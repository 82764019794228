<!-- =========================================================================================
    File Name: Chat.vue
    Description: Chat Application - Stay connected
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <dynamic-title :title="notificationTitle + 'duotalk'"></dynamic-title>
    <div class="absolute z-9999999 flex">
      <vs-button
        v-if="isChatSidebarActive"
        type="flat"
        color="dark"
        icon-pack="feather"
        icon="icon-arrow-left"
        radius
        size="large"
        @click.stop="$router.push({ name: 'dashboard' })"
      />
    </div>
    <div id="chat-app" ref="chatFrame" class="w-screen h-full overflow-hidden">
      <!-- Add conversation popup -->
      <vs-popup
        id="popup-inbox-conversation"
        :title="$t('AddConversationTitle')"
        :active.sync="addConversationPopup"
      >
        <div class="px-4 py-4" v-if="!addConversationLoading">
          <div class="flex flex-wrap mb-6 w-full" v-if="chatOptions.length > 1">
            <v-select
              class="no-icon-border w-full"
              v-model="addConversationChat"
              :clearable="false"
              :options="this.chatOptions"
              name="entity"
            />
            <div class="label-onboarding z-10">
              <i class="vs-icon feather icon icon-menu mr-1"></i>
              {{ $t("Chatbot") }}
            </div>
          </div>
          <div class="flex mb-3">
            <v-select
              :disabled="!addConversationChat"
              :options="conversationSearchContacts"
              :loading="addConversationSearchLoading"
              label="search"
              class="w-full"
              v-model="addConversationContact"
              @search="addConversationSearchContacts"
              :placeholder="$t('SearchContact')"
            >
              <!-- eslint-disable-next-line vue/no-unused-vars  -->
              <template #no-options>
                <span v-if="addConversationOnSearch">
                  {{ $t("NoContactFounded") }}
                </span>
                <span v-else>-</span>
              </template>
              <template v-slot:option="contact">
                <div class="chat__contact flex items-center">
                  <div class="contact__avatar mr-3">
                    <vs-avatar
                      class="border-2 border-solid border-white"
                      :src="contact.profilePic"
                      size="30px"
                    ></vs-avatar>
                  </div>
                  <div
                    class="contact__container w-full flex items-center justify-between overflow-hidden"
                  >
                    <div
                      class="contact__info flex flex-col"
                      style="max-width: 225px"
                    >
                      <span class="font-semibold truncate bold">
                        {{ contact.name }}
                      </span>
                      <span class="truncate">
                        {{ contact.phone }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:selected-option="contact">
                <div class="chat__contact flex items-center">
                  <vs-avatar
                    class="border-2 border-solid border-white"
                    :src="contact.profilePic"
                    size="30px"
                  ></vs-avatar>
                  <span
                    class="font-semibold truncate bold text-base px-1 block overflow-hidden whitespace-nowrap text-ellipsis"
                    style="max-width: 110px"
                  >
                    {{ contact.name }}
                  </span>
                  <span class="truncate text-xs pl-1">
                    {{ contact.phone }}
                  </span>
                </div>
              </template>
              <template v-slot:spinner="{ addConversationSearchLoading }">
                <div
                  v-if="addConversationSearchLoading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                ></div>
              </template>
            </v-select>
          </div>
          <div class="flex-wrap mb-3 w-full hidden">
            <v-select
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              class="w-full"
              :placeholder="$t('SearchContact')"
            />
          </div>
          <div
            class="flex flex-wrap mb-3 w-full"
            v-if="!addConversationNameDisabled"
          >
            <vs-input
              class="no-icon-border w-full"
              v-model="addConversationName"
            />
            <div class="label-onboarding z-10">
              <i class="vs-icon feather icon icon-user pr-1"></i>
              {{ $t("Name") }}
            </div>
          </div>
          <div
            class="flex flex-wrap mb-3 w-full"
            v-if="!addConversationPhoneDisabled"
          >
            <vue-tel-input
              v-model="addConversationPhone"
              @validate="validatePhone"
              @country-changed="countryChange"
              :mode="'auto'"
              class="w-full py-1 px-3"
            />
            <span v-if="!validPhone" class="text-danger text-sm">
              {{ $t("InvalidPhone") }}
            </span>
            <div class="label-onboarding">
              <i class="vs-icon feather icon icon-phone mr-1"></i>
              {{ $t("Phone") }}
            </div>
          </div>
          <div class="flex flex-wrap mb-3 w-full" v-if="addConversationChat">
            <v-select
              class="no-icon-border w-full"
              v-model="addConversationIntention"
              :clearable="false"
              :options="operatorIntentions"
              name="segment"
            />
            <div class="label-onboarding bg-white z-10">
              <i class="vs-icon feather icon icon-grid mr-1"></i
              >{{ $t("Department") }}
            </div>
          </div>
          <div
            class="flex flex-wrap mb-3 w-full"
            v-if="operatorEntities.length > 0 && addConversationChat"
          >
            <v-select
              class="no-icon-border w-full"
              v-model="addConversationEntity"
              :clearable="false"
              :options="operatorEntities"
              name="entity"
              v-if="operatorEntities.length > 0"
            />
            <div class="label-onboarding bg-white z-10">
              <i class="vs-icon feather icon icon-menu mr-1"></i>
              {{ $t("EntityKey") }}
            </div>
          </div>
          <vs-checkbox color="primary" v-model="optIn" class="newChatCheckbox">
            <div class="text-xs w-100 pl-3">
              Ao continuar, você garante que possui o consentimento, legítimo
              interesse ou contrato preexistente, bem como o OPT-IN para a
              comunicação com este contato. Ao continuar, você concorda com os
              <a href="https://www.duotalk.io/termos-de-uso/" target="_blank">{{
                $t("TermsOfService")
              }}</a>
              {{ $t("And") }}
              <a
                href="https://www.duotalk.io/politica-de-privacidade/"
                target="_blank"
                >{{ $t("PrivacyPolicy") }}</a
              >.
            </div>
          </vs-checkbox>
          <vs-button
            type="filled"
            @click="addConversation()"
            color="success"
            class="w-full mt-12"
            :disabled="addConversationDisabled"
          >
            {{ $t("StartConversation") }}
          </vs-button>
        </div>
        <div class="w-full flex justify-center p-12" v-else>
          <span class="material-icons-outlined animate-spin text-9xl">
            sync
          </span>
        </div>
      </vs-popup>

      <!-- Quick messages popup -->
      <vs-popup :title="$t('QuickMessages')" :active.sync="quickMessagesPopup">
        <vs-row v-if="!addConversationLoading">
          <vs-col vs-w="12">
            <vs-input
              icon="icon-search"
              icon-pack="feather"
              class="no-icon-border w-full"
              :placeholder="$t('Search')"
              v-model="searchQueryQuickMessages"
              @keyup="filterQuickMessages"
              type="search"
            />
          </vs-col>

          <vs-col vs-w="12">
            <vs-alert
              v-for="(message, index) in inboxQuickMessagesFiltered"
              :key="index"
              color="dark"
              :title="message.title"
              class="mt-3 cursor-pointer"
              @click="selectQuickMessage(message.message)"
            >
              {{ message.message.substring(0, 60) }}
            </vs-alert>
          </vs-col>
        </vs-row>
        <div v-else class="p-12">
          <span class="material-icons-outlined animate-spin text-9xl">
            sync
          </span>
        </div>
      </vs-popup>

      <!--       Create quick messages popup -->
      <vs-popup
        v-if="createQuickMessagesPopup"
        :title="$t('QuickMessages')"
        :active.sync="createQuickMessagesPopup"
      >
        <create-quick-message-popup
          @closeCreatePopup="closeCreatePopup()"
          @getQuickMessages="getQuickMessages()"
          :create-quick-messages-popup="createQuickMessagesPopup"
          :create-quick-messages-options="createQuickMessagesOptions"
          :quick-message-media="quickMessageMedia"
          :quick-message-media-mimetype="quickMessageMediaMimetype"
        />
      </vs-popup>

      <!-- Custom templates -->
      <vs-popup
        :title="$t('EditVariablesTemplates')"
        :active.sync="editVariablesTemplatesPopup"
      >
        <vs-row>
          <vs-col vs-w="12">
            <div class="con-vs-alert con-vs-alert-primary con-icon">
              <div class="vs-alert con-icon">
                <i
                  class="vs-icon notranslate icon-scale icon-alert feather icon-info null"
                ></i>
                Este template possui variáveis customizáveis.
                <a
                  href="https://ajuda.duotalk.io/pt/articles/52-enviar-mensagens-com-variaveis"
                  target="_blank"
                  class="underline"
                  >Como funciona?</a
                >
              </div>
            </div>
          </vs-col>

          <vs-col>
            <vs-divider />
          </vs-col>

          <vs-col>
            <h5 class="text-dark">
              Como gostaria de substituí-la para este envio?
            </h5>
          </vs-col>

          <vs-col
            class="mt-4"
            v-for="(item, index) in editVariablesTemplate"
            :key="index"
          >
            <vs-input
              :label="index.toLowerCase() | capitalize"
              class="w-full"
              maxlength="60"
              :name="index.toLowerCase() | capitalize"
              v-model="editVariablesTemplate[index]"
            />
          </vs-col>

          <vs-col>
            <vs-divider />
          </vs-col>

          <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
            <h5>Prévia Template</h5>
            <vs-button
              type="border"
              color="primary"
              icon-pack="feather"
              :icon="showTemplate ? 'icon-eye-off' : 'icon-eye'"
              icon-after
              class="px-2"
              @click="showTemplate = !showTemplate"
            >
              {{ showTemplate ? "Esconder" : "Visualizar" }}
            </vs-button>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-align="center"
            class="mt-4"
            v-if="showTemplate"
          >
            <vs-textarea
              v-model="templateData.message"
              rows="11"
              disabled
              readonly
            />
          </vs-col>

          <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" class="mt-4">
            <vs-button
              icon-pack="feather"
              icon="icon-save"
              size="medium"
              @click="sendWhatsappTemplate"
              icon-after
            >
              {{ $t("Send") }}
            </vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>

      <div>
        <vs-sidebar
          class="items-no-padding"
          parent="#chat-app"
          :click-not-close="clickNotClose"
          :hidden-background="clickNotClose"
          v-model="isChatSidebarActive"
          id="chat-list-sidebar"
        >
          <div class="w-full px-5 mt-8">
            <vs-duotalk-inbox-tabs-v2
              alignment="fixed"
              v-model="activeTab"
              id="tabs-inbox"
              ref="tabArchive"
              :isReady="isReady"
              :counterBadge="
                isSearch
                  ? [0, 0, 0, 0]
                  : [0, waitingAttendanceLength, inAttendancePendingLength, 0]
              "
            />
          </div>

          <div class="flex justify-between w-full p-5-mutaded drop-shadow">
            <vs-input
              :placeholder="$t('SearchContact')"
              icon-pack="feather"
              :icon="!searchQuery.length ? 'icon-search' : 'icon-x'"
              icon-after
              v-model="searchQuery"
              @keyup.enter="filterConversations"
              v-on:icon-click="
                searchQuery.length ? (searchQuery = '') : '';
                filterConversations({ forceSearch: true });
              "
              :disabled="conversationsLoadingProgress || !isInboxUsageAvailable"
              :class="{ 'w-full': activeTab === 3 }"
            />
            <vs-dropdown
              v-if="activeTab !== 3"
              vs-custom-content
              class="self-center cursor-pointer w-24"
              vs-trigger-click
              @click.native="fetchFiltersCounter"
            >
              <vs-button
                icon="icon-chevron-down"
                icon-pack="feather"
                icon-after
                :type="filterIsActive"
                class="flex justify-center cursor-pointer w-24"
                :disabled="!isInboxUsageAvailable"
              >
                {{ $t("Filters") }}
              </vs-button>
              <vs-dropdown-menu
                class="w-1/6"
                style="min-width: 320px; max-height: calc(100vh - 145px)"
              >
                <div class="p-4">
                  <h6 class="mb-2">{{ $t("Departments") }}:</h6>
                  <div
                    class="flex flex-wrap mt-6 overflow-x-auto"
                    style="max-height: calc(30vh)"
                  >
                    <div class="w-full flex mb-3">
                      <vs-radio
                        v-model="intentionFilter"
                        :vs-value="null"
                        class="text-base ml-2 whitespace-nowrap"
                      >
                        {{ $t("SelectAll") }}
                      </vs-radio>
                    </div>
                    <div
                      class="w-full flex mb-3"
                      v-for="(intention, index) in intentionsArrayStored"
                      v-bind:key="index"
                    >
                      <vs-radio
                        v-model="intentionFilter"
                        :vs-value="intention"
                        class="text-md opacity-85 ml-2 whitespace-nowrap"
                      >
                        {{ intention || $t("Others") }}
                        <custom-skeleton
                          customClass="w-3"
                          size="10px"
                          v-if="fetchFiltersCounterOnGoing"
                        />
                        <span v-else-if="!hideFiltersCounters">
                          ({{
                            intentionConversationsCount[
                              `${intention}_${activeTab + 1}`
                            ] || 0
                          }})</span
                        >
                      </vs-radio>
                    </div>
                  </div>
                </div>
                <vs-divider
                  v-if="currentOperatorIsManager && activeTab === 2"
                  class="d-theme-border-grey-light my-0"
                />
                <div
                  v-if="currentOperatorIsManager && activeTab === 2"
                  class="p-4"
                >
                  <h6 class="mb-2">{{ $t("Operators") }}:</h6>
                  <div
                    class="flex flex-wrap mt-6 overflow-x-auto"
                    style="max-height: calc(30vh)"
                  >
                    <div class="w-full flex mb-3">
                      <vs-radio
                        v-model="operatorFilter"
                        :vs-value="null"
                        class="text-base ml-2 whitespace-nowrap"
                      >
                        {{ $t("SelectAll") }}
                      </vs-radio>
                    </div>
                    <div
                      class="w-full flex mb-3"
                      v-for="(operator, index) in inboxOperatorsArrayStored"
                      v-bind:key="index"
                    >
                      <vs-radio
                        v-model="operatorFilter"
                        :vs-value="operator.value"
                        class="text-md ml-2 whitespace-nowrap"
                      >
                        {{ operator.label }}
                        <custom-skeleton
                          customClass="w-3"
                          size="10px"
                          v-if="fetchFiltersCounterOnGoing"
                        />
                        <span v-else-if="!hideFiltersCounters">
                          ({{
                            operatorConversationsCount[
                              `${operator.value}_${activeTab + 1}`
                            ] || 0
                          }})</span
                        >
                      </vs-radio>
                    </div>
                  </div>
                </div>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <vs-alert
            :active="!socketConnOnline"
            :title="$t('YoureOffline')"
            color="warning"
            icon-pack="feather"
            icon="icon-wifi-off"
          >
            {{ $t("NetworkIssues") }}
          </vs-alert>

          <vs-alert
            title="Sync"
            color="success"
            icon-pack="feather"
            icon="icon-refresh-ccw"
            :active="conversationsSyncAlert"
          >
            {{ $t("InboxConversationsSyncAlert") }}
          </vs-alert>

          <vs-divider class="mt-0" />

          <div class="flex mb-4 px-4 items-center justify-between">
            <div v-if="activeTab !== 3" class="flex items-center">
              <small class="text-primary">
                {{ $t("TotalConversationsNumber") }} ({{
                  currentTabConversations.length
                }}<i v-if="!isSearch && currentTabTotalConversations > 0"
                  >/{{ currentTabTotalConversations }}</i
                >)
              </small>
              <vs-tooltip text="Filtrar Pendentes">
                <vs-button
                  icon-pack="feather"
                  icon="icon-alert-circle"
                  :class="[
                    filterUnreadOnly ? 'vs-button-filled' : 'vs-button-flat',
                  ]"
                  radius
                  @click="filterUnreadOnly = !filterUnreadOnly"
                  class="ml-3"
                  :disabled="!isInboxUsageAvailable"
                />
              </vs-tooltip>
            </div>
            <small v-else class="text-primary mt-2 mr-5">
              {{ $t("LastConversations") }} ({{
                currentTabConversations.length
              }})
            </small>
            <div
              v-if="
                activeTab !== 3 &&
                !(
                  this.$store.state.user.userRole == 'operator' &&
                  blockCreateConversationOperator
                )
              "
            >
              <vs-tooltip :text="$t('NewConversation')">
                <vs-button
                  icon-pack="feather"
                  icon="icon-plus"
                  type="border"
                  radius
                  class="justify-center"
                  @click="addConversationPopup = true"
                  :disabled="!isInboxUsageAvailable"
                />
              </vs-tooltip>
            </div>
          </div>

          <vs-divider v-if="!conversationsLoadingProgress" class="mb-0" />
          <vs-progress
            indeterminate
            color="primary"
            :height="1"
            class="m-0 p-0 block rounded-none"
            v-else
          />

          <div class="absolute w-full">
            <!-- ACTIVE CHATS LIST -->
            <ul v-if="showConversationsSkeleton">
              <li
                v-for="(n, i) in conversationsLoadingSkeleton"
                :key="`skeleton-up-${i}`"
              >
                <chat-contact-skeleton />
              </li>
            </ul>
            <div
              v-else
              :class="{
                'flex items-center justify-center align-middle':
                  !currentTabConversations.length,
              }"
              ref="contactsList"
            >
              <virtual-list
                v-if="!!currentTabConversations.length"
                class="clusterize-scroll pt-3"
                ref="conversationsListContainer"
                :data-key="'id'"
                :data-sources="currentTabConversations"
                :data-component="conversationsItemComponent"
                :item-class="'cursor-pointer contact-list-item w-full'"
                :estimate-size="80"
                @scroll="handleConversationsScroll"
              >
                <div
                  slot="footer"
                  class="flex mt-4 mb-4 px-4 items-center w-full justify-center"
                >
                  <div
                    v-if="!isLoadingConversations && showLoadMoreConversations"
                  >
                    <vs-button
                      class="my-4 rounded-4xl"
                      type="border"
                      size="small"
                      @click="loadMoreConversations()"
                    >
                      {{ $t("LoadMoreConversations") }}
                    </vs-button>
                  </div>
                  <vs-divider v-else-if="!isLoadingConversations">
                    <small class="text-grey">
                      {{ $t("AllConversationsLoaded") }}
                    </small>
                  </vs-divider>
                  <span
                    class="material-icons-outlined animate-spin text-9xl"
                    v-if="isLoadingConversations"
                  >
                    sync
                  </span>
                </div>
              </virtual-list>
              <div
                class="items-center w-full justify-center clusterize-scroll flex p-3"
                v-if="!currentTabConversations.length"
              >
                <p class="text-center" v-if="activeTab === 0">
                  <span class="material-icons-outlined text-9xl text-primary">
                    info
                  </span>
                  <br />
                  {{ $t("InboxNoCampaignAvailableMsgP1") }}
                  <b class="text-primary">{{
                    $t("InboxNoCampaignAvailableMsgP2")
                  }}</b>
                  {{ $t("InboxNoCampaignAvailableMsgP3") }}
                  <b class="text-primary">{{
                    $t("InboxNoCampaignAvailableMsgP4")
                  }}</b>
                  {{ $t("InboxNoCampaignAvailableMsgP5") }}
                </p>
                <div class="flex" v-else>
                  <div class="text-center mr-2">
                    <h3 class="material-icons-outlined text-9xl text-primary">
                      info
                    </h3>
                  </div>
                  <p class="mt-1">{{ $t("NoConversationFounded") }}</p>
                </div>
              </div>
            </div>
          </div>
        </vs-sidebar>
        <!-- MID COLUMN -->
        <div
          ref="chatlog"
          id="chatlog"
          class="chat__bg app-fixed-height chat-content-area vs-con-loading__container"
          :class="{
            'sidebar-spacer--wide-mid': clickNotClose,
            'flex items-center justify-center': activeConversationId === null,
          }"
        >
          <div
            v-if="showDropzone && !teamChatOpen"
            class="w-full h-full mr-5 backdrop-filter flex items-center justify-center flex-col"
          >
            <h1 class="font-bold">Solte os arquivos aqui.</h1>
          </div>
          <template v-if="activeConversationId">
            <div class="chat__navbar">
              <chat-navbar
                :navbarKey="activeConversationId"
                :isSidebarCollapsed="!clickNotClose"
                :intentions="inboxIntentions"
                :entities="allEntities"
                :closingConversationReasons="closingConversationReasons"
                :chatOptions="chatOptions"
                @set-operator="setOperator"
                @postpone-conversation="postponeConversation"
                @open-contacts-sidebar="toggleChatSidebar(true)"
                @show-profile-sidebar="toogleProfileSidebar(true)"
                @close-conversation="closeConversation"
              />
            </div>
            <div
              class="w-full h-full p-7"
              style="background: #f4f4f4"
              v-if="filesToUpload.length > 0"
            >
              <div class="right-0">
                <feather-icon
                  class="mr-0 cursor-pointer"
                  icon="XCircleIcon"
                  svg-classes="stroke-current text-primary"
                  @click="cleanMessageInput"
                />
              </div>
              <div
                class="w-full content-center"
                style="display: table; height: calc(100vh - 284px)"
                v-if="filesToUploadPreview[fileToUploadPreviewIndex]"
              >
                <div
                  class="align-middle text-center"
                  style="display: table-cell"
                  v-if="
                    filesToUploadPreview[
                      fileToUploadPreviewIndex
                    ].type.includes('image')
                  "
                >
                  <img
                    :src="
                      filesToUploadPreview[fileToUploadPreviewIndex].content
                    "
                    style="
                      max-width: 90%;
                      max-height: calc(100vh - 284px);
                      object-fit: contain;
                    "
                    class="h-full"
                  />
                </div>
                <div
                  v-else-if="
                    filesToUploadPreview[
                      fileToUploadPreviewIndex
                    ].type.includes('pdf')
                  "
                  class="align-middle text-center content-center"
                  style="display: table-cell"
                >
                  <div class="flex justify-center items-center">
                    <pdf
                      :src="
                        filesToUploadPreview[fileToUploadPreviewIndex].content
                      "
                      @num-pages="fileToUploadPreviewPDFPageCount = $event"
                    />
                  </div>
                  <div class="mt-3">
                    {{ filesToUploadPreview[fileToUploadPreviewIndex].name }}
                    <p class="text-sm">
                      {{ $t("Pages") }}: {{ fileToUploadPreviewPDFPageCount }}
                    </p>
                  </div>
                </div>
                <div
                  v-else
                  class="align-middle text-center"
                  style="display: table-cell"
                >
                  <span
                    class="material-icons-outlined"
                    style="font-size: 16rem"
                  >
                    insert_drive_file
                  </span>
                  <div class="mt-3">
                    {{ filesToUploadPreview[fileToUploadPreviewIndex].name }}
                  </div>
                </div>
              </div>
              <vs-divider />
              <div
                class="w-full flex items-center justify-between bottom-0 inset-x-0"
              >
                <vue-perfect-scrollbar
                  class="w-full flex flex-nowrap overflow-y-auto chat-scroll-area"
                  :settings="{ swipeEasing: false }"
                >
                  <div
                    class="p-3 pl-0"
                    :key="`img-to-upload${index}`"
                    v-for="(file, index) in filesToUploadPreview"
                  >
                    <div
                      class="w-16 h-16 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                    >
                      <vs-button
                        color="dark"
                        size="small"
                        icon-pack="feather"
                        icon="icon-x"
                        radius
                        class="absolute z-50 ml-10 -mt-2"
                        @click="removeImageToUpload(index)"
                      />
                      <img
                        v-if="file && file.type.includes('image')"
                        :src="file.content"
                        class="cursor-pointer w-16 h-16 relative inline-block object-cover"
                        @click="fileToUploadPreviewIndex = index"
                      />
                      <div
                        v-else
                        class="flex items-center justify-center h-full w-full cursor-pointer"
                      >
                        <feather-icon
                          icon="FileIcon"
                          @click="fileToUploadPreviewIndex = index"
                        />
                      </div>
                    </div>
                  </div>
                </vue-perfect-scrollbar>
                <vs-button
                  type="flat"
                  color="dark"
                  radius
                  icon-pack="feather"
                  icon="icon-folder-plus"
                  size="large"
                  @click="$refs.moreFile.click()"
                  class="mr-3"
                />
                <input
                  ref="moreFile"
                  multiple
                  name="fileUpload"
                  type="file"
                  class="hidden"
                  v-on:input="handleFilesBeforeUpload"
                />
                <div class="relative">
                  <vs-button
                    radius
                    color="success"
                    icon-pack="feather"
                    icon="icon-send"
                    size="large"
                    @click="sendMsg"
                  />
                  <div
                    class="absolute bg-white rounded-full w-8 h-8 flex items-center justify-center top-0 right-0 -mt-5 -mr-2 drop-shadow"
                  >
                    {{ filesToUploadPreview.length }}
                  </div>
                </div>
              </div>
            </div>

            <div class="relative">
              <div
                :class="{
                  chat__log: activeTab !== 3,
                  chat__log_closed: activeTab === 3,
                }"
                class="overflow-y-auto"
                ref="chatLog"
                @scroll="onChatLogScroll"
              >
                <chat-log
                  v-if="activeConversationId"
                  ref="chatLogComponent"
                  :key="`chatLogKey_${chatLogKey}`"
                  :cloudChats="cloudChats"
                  :userId="activeConversationId"
                  :contact="activeContact"
                  :participants="participants"
                  :contactName="activeContact.name || $t('UnknownName')"
                  :contactClosed="activeContactIsClosed"
                  :messagesListPage="messagesListPage"
                  :scrollMessagesListToBottom="scrollMessagesListToBottom"
                  @download-message-file="downloadMessageFile"
                  @resend-msg="resendMsg"
                  @onReplyMessage="onReplyMessage"
                  @onReaction="onReaction"
                  @chat-log-scroll-top="chatLogScrollTop"
                />
              </div>
              <div
                class="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                v-if="showScrollMessagesListToBottom"
              >
                <vs-button
                  size="small"
                  color="primary"
                  type="filled"
                  class="flex"
                  icon="expand_more"
                  @click="
                    scrollMessagesListToBottom += 1;
                    showScrollMessagesListToBottom = false;
                  "
                >
                  Voltar ao Início
                </vs-button>
              </div>
            </div>

            <!-- NEW QUICK MESSAGES -->
            <div v-if="newQuickMessageVisible" class="relative w-full">
              <div
                class="mb-3 h-64 rounded bottom-0 shadow-lg absolute z-9999999 overflow-y-auto block w-full p-6 bg-white border border-gray-200 shadow hover:bg-gray-100"
              >
                <div class="flex flex-col w-full">
                  <div class="flex w-full">
                    <h4 ref="newquickmessage-title" class="mb-3">
                      {{ $t("QuickMessages") }}
                    </h4>
                    <div class="right-0 absolute mr-4">
                      <button
                        type="button"
                        class="css-1bfpdd2"
                        @click="openCreateQuickMessagePopup()"
                        style="background-color: #28c76f"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div
                    :key="`${index}_quickMessageKey`"
                    v-for="(contact, index) in inboxNewQuickMessagesFiltered"
                  >
                    <div
                      tabindex="99999"
                      :ref="contact._id"
                      @click="selectNewQuickMessage(contact)"
                      @keyup.enter="selectNewQuickMessage(contact)"
                      class="contact__container w-full flex items-center justify-between overflow-hidden cursor-pointer"
                    >
                      <div
                        class="w-full overflow-hidden cursor-pointer truncate"
                      >
                        <div class="font-semibold truncate bold">
                          {{ contact.title }}
                        </div>
                        <div
                          v-if="contact.type === 'audio'"
                          class="flex items-center justify-center w-1/6 rounded-xl"
                          style="background-color: #d2d2d2"
                        >
                          <vs-icon icon="mic" />
                          Audio
                        </div>
                        <div v-else class="truncate">
                          {{ contact.message }}
                        </div>
                      </div>
                    </div>
                    <vs-divider class="m-0 mt-1 mb-1" />
                  </div>
                  <h4 ref="newquickmessage-title" class="my-3">
                    {{ $t("Templates") }}
                  </h4>
                  <div
                    :key="`${index}_templateKey`"
                    v-for="(
                      template, index
                    ) in inboxQuickMessagesWppAPIApprovedOnChatFiltered"
                  >
                    <div
                      tabindex="99999"
                      :ref="template._id"
                      @click="selectNewQuickMessage(template)"
                      @keyup.enter="selectNewQuickMessage(template)"
                      class="w-full overflow-hidden cursor-pointer truncate"
                    >
                      <div class="template__info flex flex-col text-black">
                        <div class="font-semibold bold">
                          {{ template.title }}
                        </div>
                        <div>
                          {{ template.message }}
                        </div>
                      </div>
                    </div>
                    <vs-divider class="m-0 mt-1 mb-1" />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="!activeContact.closed"
              v-show="filesToUpload.length === 0"
              class="chat__input p-0"
              :class="noteMessage ? 'bg-E4EEF7' : 'bg-white'"
              id="chat-input"
            >
              <vs-divider
                :color="noteMessage ? 'rgba(0, 0, 0, 0.1)' : 'transparent'"
                class="m-0"
              />
              <div
                v-if="!activeContact.inAttendance"
                style="
                  width: 100%;
                  height: 217px;
                  background-color: rgba(100, 100, 100, 0.2);
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  z-index: 10;
                "
              >
                <vs-button
                  class="inline-flex mr-3"
                  color="primary"
                  type="filled"
                  size="medium"
                  @click="startContactAttendance()"
                >
                  {{ $t("StartAttendance") }}</vs-button
                >
              </div>
              <div class="p-3" style="height: 217px">
                <div class="flex items-center space-x-4">
                  <div
                    class="rounded-4xl bg-grey-light p-1 flex items-center gap-x-4 float-left relative"
                  >
                    <div
                      :class="!chooseChannel ? 'hidden' : 'block'"
                      class="bg-white rounded-lg p-3 absolute shadow-md bottom-16"
                      ref="changeChannelDiv"
                    >
                      <button
                        class="mb-2 flex items-center p-3 border-none rounded-lg bg-FAFAFA hover:bg-grey-light transition-all w-full cursor-not-allowed text-base"
                        disabled
                        v-if="inboxChannelDisabled"
                      >
                        <WhatsappIcon
                          v-if="/whatsapp/i.test(channel)"
                          :width="17"
                          :height="17"
                          color="green"
                          class="mr-3 opacity-50"
                        />
                        <InstagramIcon
                          v-else-if="/instagram/i.test(channel)"
                          :width="17"
                          :height="17"
                          class="mr-3 opacity-50"
                        />
                        <FacebookIcon
                          v-else-if="/facebook/i.test(channel)"
                          :width="16"
                          :height="16"
                          class="mr-3 opacity-50"
                        />
                        <DuotalkIcon
                          class="mr-3 opacity-50"
                          v-else-if="/^(webchat|website)$/i.test(channel)"
                          :width="17"
                          :height="17"
                        />
                        {{ channel }}
                      </button>
                      <button
                        v-for="(item, index) in inboxChannels"
                        :key="index"
                        class="flex items-center p-3 border-none rounded-lg bg-FAFAFA hover:bg-grey-light transition-all w-full cursor-pointer text-base"
                        :class="index > 0 ? 'mt-2' : ''"
                        @click="setChannel(item)"
                      >
                        <WhatsappIcon
                          v-if="
                            /whatsapp/i.test(item) &&
                            (currentChannelsAvailable.wppApi360DialogStatus ||
                              currentChannelsAvailable.wppApiStatus)
                          "
                          :width="17"
                          :height="17"
                          color="green"
                          class="mr-3"
                        />
                        <InstagramIcon
                          v-else-if="
                            /instagram/i.test(item) &&
                            currentChannelsAvailable.instagramStatus
                          "
                          :width="17"
                          :height="17"
                          class="mr-3"
                        />
                        <FacebookIcon
                          v-else-if="
                            /facebook/i.test(item) &&
                            currentChannelsAvailable.facebookStatus
                          "
                          :width="16"
                          :height="16"
                          class="mr-3"
                        />
                        <DuotalkIcon
                          v-else-if="
                            /^(webchat|website)$/i.test(item) &&
                            (currentChannelsAvailable.webChatStatus ||
                              currentChannelsAvailable.webChatAIStatus)
                          "
                          :width="17"
                          :height="17"
                          class="mr-3"
                        />
                        {{ item }}
                      </button>
                    </div>

                    <div
                      :class="noteMessage ? 'bg-transparent' : 'bg-white'"
                      class="rounded-xxl border-none p-1 flex items-center"
                    >
                      <button
                        @click="
                          () => {
                            manyChannelsAvailable ? bindChangeChannelDiv() : '';
                          }
                        "
                        :class="
                          manyChannelsAvailable
                            ? 'cursor-pointer flex transition-all items-center hover:bg-grey-light rounded-xxl p-2 mr-1'
                            : 'py-2 ml-2 mr-3'
                        "
                        class="bg-transparent border-none flex items-center"
                      >
                        <Feather-icon
                          v-if="manyChannelsAvailable"
                          icon="ChevronUpIcon"
                          svg-classes="w-5 h-5"
                        />
                        <WhatsappIcon
                          v-if="
                            /whatsapp/i.test(channel) &&
                            (currentChannelsAvailable.wppApi360DialogStatus ||
                              currentChannelsAvailable.wppApiStatus)
                          "
                          :width="17"
                          :height="17"
                          color="green"
                        />
                        <FacebookIcon
                          v-else-if="
                            /facebook/i.test(channel) &&
                            currentChannelsAvailable.facebookStatus
                          "
                          :width="17"
                          :height="17"
                        />
                        <InstagramIcon
                          v-else-if="
                            /instagram/i.test(channel) &&
                            currentChannelsAvailable.instagramStatus
                          "
                          :width="17"
                          :height="17"
                        />
                        <DuotalkIcon
                          v-else-if="
                            /^(webchat|website)$/i.test(channel) &&
                            (currentChannelsAvailable.webChatStatus ||
                              currentChannelsAvailable.webChatAIStatus)
                          "
                          :width="17"
                          :height="17"
                        />
                      </button>
                      <button
                        @click="
                          noteMessage = false;
                          typedMessage = '';
                        "
                        class="bg-transparent border-none cursor-pointer pr-1"
                        :class="noteMessage ? 'font-normal' : 'font-semibold'"
                      >
                        <span class="pr-1">{{ $t("Respond") }}</span>
                      </button>
                    </div>

                    <button
                      @click="
                        bindChangeChannelDiv(false);
                        noteMessage = true;
                      "
                      :class="
                        noteMessage
                          ? 'bg-white font-semibold'
                          : 'bg-transparent'
                      "
                      class="cursor-pointer rounded-xxl border-none py-3 px-4 flex items-center"
                    >
                      <feather-icon
                        icon="ClipboardIcon"
                        svg-classes="w-5 h-5 mr-2"
                      />
                      {{ $t("Notes") }}
                    </button>
                  </div>
                  <vs-input
                    v-if="
                      !noteMessage &&
                      activeContact.wppApiOn24hWindow === false &&
                      (channel === 'WhatsApp API' || channel === 'WhatsApp 360')
                    "
                    icon="icon-search"
                    icon-pack="feather"
                    size="normal"
                    class="no-icon-border w-64 ml-5"
                    placeholder="Pesquisar Templates"
                    v-model="searchTemplate"
                  />
                  <vs-chip
                    transparent
                    closable
                    class="flex-none"
                    close-icon="close"
                    v-if="messageFileUrl"
                    @click="removeMessageFileUrl"
                  >
                    <vs-avatar
                      v-if="!messageFileMimetype.includes('audio')"
                      :icon="
                        messageFileMimetype.includes('video')
                          ? 'play_circle'
                          : 'attach_file'
                      "
                      :src="
                        messageFileMimetype.includes('image')
                          ? messageFileUrl
                          : undefined
                      "
                      @click="
                        openModalInboxQuickMessages(
                          messageFileMimetype,
                          messageFileUrl
                        )
                      "
                    />
                    <a
                      v-if="
                        messageFileMimetype.includes('image') ||
                        messageFileMimetype.includes('video')
                      "
                      @click="
                        openModalInboxQuickMessages(
                          messageFileMimetype,
                          messageFileUrl
                        )
                      "
                      href="#_"
                    >
                      {{ inboxQuickMessagesFileName }} no Template
                    </a>
                    <a
                      v-else-if="messageFileMimetype.includes('audio')"
                      :href="messageFileUrl"
                      target="_blank"
                    >
                      <audio :src="messageFileUrl" controls />
                    </a>
                    <a v-else :href="messageFileUrl" target="_blank">
                      {{ inboxQuickMessagesFileName }} no Template
                    </a>
                  </vs-chip>
                </div>
                <vs-list
                  v-if="
                    activeContact.wppApiOn24hWindow === false &&
                    (channel === 'WhatsApp API' ||
                      channel === 'WhatsApp 360') &&
                    !noteMessage
                  "
                  class="overflow-y-auto"
                  style="height: calc(100% - 40px)"
                >
                  <vs-list-item
                    v-for="(
                      item, i
                    ) in inboxQuickMessagesWppAPIApprovedOnChatFiltered"
                    :title="item.title"
                    :subtitle="item.message"
                    :key="i"
                  >
                    <vs-button
                      color="primary"
                      size="small"
                      class="ml-3"
                      :disabled="!socketConnOnline"
                      @click="handleWhatsappTemplate(item)"
                    >
                      {{ $t("Send") }}
                    </vs-button>
                  </vs-list-item>
                </vs-list>

                <form v-else>
                  <div
                    class="flex flex-nowrap lg:h-12 xl:h-auto 2xl:h-auto px-1 pt-2 pb-1"
                  >
                    <div v-if="audioSource" class="flex items-center my-5">
                      <audio :src="audioSource" controls />
                      <vs-button
                        icon-pack="feather"
                        icon="icon-trash"
                        radius
                        @click="cleanMessageInput()"
                        class="ml-2"
                      />
                    </div>
                    <textarea
                      v-else
                      tabindex="1"
                      v-model="typedMessage"
                      @keyup="inspectBar"
                      :placeholder="
                        noteMessage
                          ? 'Escreva aqui sua nota interna (fique tranquilo, essa nota não será enviado ao cliente)'
                          : 'Escreva uma resposta...'
                      "
                      id="input-textarea-message"
                      ref="inputTextareaMessage"
                      class="border-0 text-base text-black outline-0 leading-5 w-full resize-none chat-textArea bg-transparent"
                      @paste="
                        pasteOnTextBox($event, channelAcceptMedia(channel))
                      "
                      rows="6"
                    />
                  </div>
                  <div
                    :class="noteMessage ? 'justify-end' : 'justify-between'"
                    class="flex p-2"
                  >
                    <div v-if="!noteMessage" class="flex items-center">
                      <template
                        v-if="channelAcceptMedia(channel) && !audioSource"
                      >
                        <vs-button
                          radius
                          color="rgba(128, 128, 128, .15)"
                          text-color="black"
                          icon-pack="feather"
                          icon="icon-paperclip"
                          class="hover:bg-primary hover:text-white"
                          v-if="
                            channelMediaData(
                              channel
                            ).mediaAcceptedTypes.includes('file') ||
                            channelMediaData(
                              channel
                            ).mediaAcceptedTypes.includes('video') ||
                            channelMediaData(
                              channel
                            ).mediaAcceptedTypes.includes('image')
                          "
                          @click="channelAddFileOnClick()"
                        />
                        <input
                          multiple
                          id="file-upload"
                          type="file"
                          class="hidden"
                          v-on:input="handleFilesBeforeUpload"
                          :accept="
                            channelMediaData(
                              channel
                            ).mediaAcceptedExtensions.join(',')
                          "
                          ref="addFile"
                        />
                        <vs-button
                          v-if="
                            channelMediaData(
                              channel
                            ).mediaAcceptedTypes.includes('audio')
                          "
                          :radius="!recorder ? true : false"
                          color="rgba(128, 128, 128, .15)"
                          text-color="black"
                          icon-pack="feather"
                          :icon="
                            !recorder || recorder.state !== 'recording'
                              ? 'icon-mic'
                              : ''
                          "
                          @click="handleRecording()"
                          class="ml-2 hover:bg-primary hover:text-white"
                          :class="
                            !recorder || recorder.state !== 'recording'
                              ? ''
                              : 'animate-pulse'
                          "
                        >
                          <span
                            class="flex text-9xl items-center"
                            v-if="recorder"
                          >
                            <vs-icon class="mr-2" icon="stop" color="red" />
                            {{
                              `${mediaAudioRecordingMin}: ${mediaAudioRecordingSec}`
                            }}
                          </span>
                        </vs-button>
                      </template>

                      <twemoji-picker
                        :emojiData="emojiDataAll"
                        :emojiGroups="emojiGroups"
                        :skinsSelection="true"
                        :searchEmojisFeat="true"
                        :pickerWidth="emojiPickerSize"
                        @emojiUnicodeAdded="insertEmoji"
                        :searchEmojiPlaceholder="$t('Search')"
                        searchEmojiNotFound="Nenhum Emoji encontrado"
                        isLoadingLabel="Carregando..."
                        :key="emojiKeyForceRerender"
                        twemojiPath="https://cdn.duotalk.com.br/img/uploads/twemoji/"
                        class="ml-2"
                      >
                        <template v-slot:twemoji-picker-button>
                          <vs-button
                            radius
                            color="rgba(128, 128, 128, .15)"
                            class="hover:bg-primary"
                            @click="channelAddEmoteOnClick()"
                          >
                            <img
                              src="https://app.duotalk.io/img/smiling.e1cb388a.png"
                              width="18px"
                              alt="smile icon"
                            />
                          </vs-button>
                        </template>
                      </twemoji-picker>

                      <vs-dropdown
                        vs-custom-content
                        class="ml-2 cursor-pointer group"
                        vs-trigger-click
                        :key="dropDownKey"
                      >
                        <vs-button
                          radius
                          color="rgba(128, 128, 128, .15)"
                          text-color="black"
                          icon-pack="feather"
                          icon="icon-file-text"
                          class="group-hover:text-white group-hover:bg-primary"
                        />
                        <vs-dropdown-menu>
                          <div class="h-80 w-96 overflow-y-auto p-3">
                            <div
                              class="flex w-full items-center justify-between"
                            >
                              <h5>{{ $t("QuickMessages") }}</h5>

                              <vs-button
                                color="success"
                                icon-pack="feather"
                                icon="icon-plus"
                                radius
                                size="small"
                                @click="createQuickMessagesPopup = true"
                              />
                            </div>
                            <vs-input
                              v-model="searchQueryQuickMessages"
                              :placeholder="$t('Search')"
                              class="w-full mt-3"
                            />
                            <vs-alert
                              v-for="(
                                item, index
                              ) in filterTemplateQuickMessages"
                              :key="index"
                              :title="item.title"
                              color="dark"
                              class="mt-3 cursor-pointer"
                              @click="selectedQuickMessage = item"
                            >
                              <div class="truncate">{{ item.message }}</div>
                              <div
                                class="flex items-center"
                                v-if="item.type === 'audio'"
                              >
                                <div
                                  class="flex items-center justify-center w-1/3 rounded-xl"
                                  style="background-color: #d2d2d2"
                                >
                                  <vs-icon icon="mic" />
                                  Audio
                                </div>
                              </div>
                            </vs-alert>
                          </div>
                        </vs-dropdown-menu>
                      </vs-dropdown>

                      <!-- CHATGPT SUGGESTION -->
                      <vs-button
                        v-if="accHasAIFeature"
                        @click.prevent="generateChatGptSuggestion()"
                        class="ml-4 px-2 suggestionBtn"
                        icon-pack="feather"
                        :icon="
                          generateChatGptSuggestionOngoing
                            ? ''
                            : !chatGptSuggestionGenerated
                            ? 'icon-edit-2'
                            : 'icon-refresh-ccw'
                        "
                        color="warning"
                        type="border"
                        text-color="black"
                        :disabled="generateChatGptSuggestionOngoing"
                      >
                        <span
                          class="material-icons-outlined animate-spin text-base"
                          v-if="generateChatGptSuggestionOngoing"
                        >
                          sync
                        </span>
                        {{
                          chatGptSuggestionGenerated
                            ? $t("Redo")
                            : $t("SuggestAnswer")
                        }}
                      </vs-button>

                      <!-- Resumo de Conversa e Análise de sentimento-->
                      <vs-dropdown
                        v-if="accHasAIFeature"
                        vs-custom-content
                        vs-trigger-click
                        class="ml-4 cursor-pointer group dropdownActionsBtn"
                      >
                        <vs-button
                          type="border"
                          color="warning"
                          class="px-2 group-hover:dropdownActionsBtn"
                        >
                          <ThunderIcon />
                        </vs-button>
                        <vs-dropdown-menu class="dropdownActionsListBtn">
                          <vs-dropdown-item>
                            <vs-button
                              color="warning"
                              type="flat"
                              icon-pack="feather"
                              icon="icon-file-text"
                              text-color="black"
                              class="px-2"
                              @click="resumeConversation"
                            >
                              Resumir Conversa
                            </vs-button>
                          </vs-dropdown-item>
                          <vs-dropdown-item>
                            <vs-button
                              color="warning"
                              type="flat"
                              icon-pack="feather"
                              icon="icon-heart"
                              text-color="black"
                              class="px-2"
                              @click="sentimentAnalysis"
                            >
                              Análise de Sentimento
                            </vs-button>
                          </vs-dropdown-item>
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </div>
                    <vs-button
                      @click="sendMsg"
                      :color="noteMessage ? 'primary' : 'success'"
                      icon-pack="feather"
                      icon="icon-send"
                      :disabled="!socketConnOnline"
                    >
                      {{ $t("Send") }}
                    </vs-button>
                  </div>
                </form>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex flex-col items-center">
              <feather-icon
                icon="MessageSquareIcon"
                class="mb-4 bg-white p-8 shadow-md rounded-full"
                svgClasses="w-16 h-16"
                @click.stop="toggleChatSidebar(true)"
              ></feather-icon>
              <h4
                class="py-2 px-4 bg-white shadow-md rounded-full cursor-pointer"
                @click.stop="toggleChatSidebar(true)"
                v-if="!this.clickNotClose"
              >
                Ver contatos e iniciar atendimento
              </h4>
            </div>
          </template>
        </div>

        <vs-sidebar
          parent="#chat-app"
          :hidden-background="true"
          v-model="activeProfileSidebar"
          :position-right="true"
          :click-not-close="true"
          id="chat-profile-info-sidebar"
        >
          <contact-profile
            v-if="activeConversationId !== null"
            :conversationID="activeContact.contactSessionID || null"
            :contactName="activeContact.name || $t('UnknownName')"
            :contactEmail="activeContact.email"
            :contactPhone="activeContact.phone"
            :contactPhase="activeContact.phase"
            :contactProfilePic="activeContact.profilePic"
            :contactOptIn="activeContact.optIn"
            :closed="activeContact.closed"
            :activeContact="activeConversationId"
            :phaseList="phaseList"
            :contactID="activeContact.contactID"
            :fromInbox="true"
            @close-profile-sidebar="toogleProfileSidebar(false)"
            @init-add-conversation-popup="initAddConversationPopup"
            @update-contact="updateContactProp"
            class="user-profile-container"
            :showLoading="false"
            @close-conversation="closeConversation"
            :status="activeContact.contactStatus"
          />
        </vs-sidebar>
      </div>
    </div>

    <vs-popup
      title="Editar variáveis"
      :active.sync="modalReplaceTemplateMessage"
    >
      <vs-alert
        :active="modalReplaceTemplateMessage"
        color="primary"
        icon-pack="feather"
        icon="icon-info"
      >
        Este template possui variáveis customizáveis.
        <a href="#" target="_blank" class="underline">Como funciona?</a>
      </vs-alert>
      <vs-row>
        <vs-col>
          <vs-divider />
        </vs-col>
        <vs-col>
          <h4 class="text-dark">
            Como gostaria de substituí-las para este envio?
          </h4>
        </vs-col>
        <vs-col class="mt-5" v-if="templateData.customVariables.length">
          <vs-input
            v-for="(item, index) in previewTemplateMessage"
            v-model="item.value"
            :key="index"
            :label="item.label"
            class="mb-5 w-full inputTemplate"
            val-icon-pack="feather"
            val-icon-danger="icon-x-circle"
          />
          <vs-button
            icon-pack="feather"
            icon="icon-eye"
            icon-after
            class="float-right"
            @click="
              modalPreviewTemplateMessage = true;
              templateMessagePreview();
            "
            :disabled="!canPreviewTemplateMessage"
          >
            {{ $t("View") }}
          </vs-button>
        </vs-col>
      </vs-row>

      <vs-popup
        title="Prévia da mensagem"
        :active.sync="modalPreviewTemplateMessage"
      >
        <vs-row>
          <vs-col class="my-5">
            <p v-html="textReplacedFromTemplateMessage"></p>
          </vs-col>
          <vs-col class="mt-5" vs-type="flex" vs-justify="end">
            <vs-button
              type="border"
              icon-pack="feather"
              icon="icon-arrow-left"
              @click="closeModalPreviewTemplateMessage()"
              >{{ $t("Back") }}</vs-button
            >
            <vs-button
              color="success"
              icon-pack="feather"
              icon="icon-send"
              icon-after
              class="ml-3"
              @click="sendWhatsappTemplate()"
              >{{ $t("Send") }}</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-popup>
    </vs-popup>

    <inbox-quick-message-zoom-modal
      :src="inboxQuickMessageZoomModalSrc"
      :modalOpen="inboxQuickMessageZoomModalOpen"
      :fileMimetype="inboxQuickMessageZoomModalMime"
      @modalclose="inboxQuickMessageZoomModalOpen = false"
    />
  </div>
</template>

<script>
//import { axios as nativeAxios } from "axios";
import "@/assets/scss/vuesax/apps/chat.scss";
import newConversationNotification from "@/assets/sounds/chat/newConversationNotification.mp3";
import newMessageNotification from "@/assets/sounds/chat/newMessageNotification.mp3";
import sendMessageNotification from "@/assets/sounds/chat/sendMessageNotification.mp3";
import CustomSkeleton from "@/components/CustomSkeleton.vue";
import DynamicTitle from "@/components/DynamicTitle.vue";
import InboxQuickMessageZoomModal from "@/components/InboxQuickMessageZoomModal.vue";
import ContactProfile from "@/components/duotalk-contact-profile/ContactProfile.vue";
import VsDuotalkInboxTabsV2 from "@/components/duotalk-inbox-tabs/VsDuotalkInboxTabsV2.vue";
import DuotalkIcon from "@/components/svg/DuotalkIcon.vue";
import FacebookIcon from "@/components/svg/FacebookIcon.vue";
import InstagramIcon from "@/components/svg/InstagramIcon.vue";
import WhatsappIcon from "@/components/svg/WhatsappIcon.vue";
import normalizeString from "@/helpers/normalizeString";
import { TwemojiPicker } from "@kevinfaguiar/vue-twemoji-picker";
import EmojiGroups from "@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json";
import EmojiAllData from "@kevinfaguiar/vue-twemoji-picker/emoji-data/pt/emoji-all-groups.json";
import OpusMediaRecorder from "OpusMediaRecorder";
import { v4 as uuidV4 } from "uuid";
import pdf from "vue-pdf";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import VirtualList from "vue-virtual-scroll-list";
import { mapState } from "vuex";
import axios from "../../../axios";
import FeatherIcon from "../../../components/FeatherIcon.vue";
import createQuickMessagePopup from "../../../components/duotalk-inbox/createQuickMessagePopup.vue";
import ThunderIcon from "../../../components/svg/ThunderIcon.vue";
import ChatContact from "./ChatContact.vue";
import ChatContactSkeleton from "./ChatContactSkeleton.vue";
import ChatLog from "./ChatLog.vue";
import ChatNavbar from "./ChatNavbar.vue";
import socket from "./socketAttendance";

export default {
  name: "duotalk-inbox",
  components: {
    WhatsappIcon,
    InstagramIcon,
    DuotalkIcon,
    FacebookIcon,
    pdf,
    "virtual-list": VirtualList,
    ThunderIcon,
    VsDuotalkInboxTabsV2,
    "twemoji-picker": TwemojiPicker,
    ChatLog,
    ChatContactSkeleton,
    ChatNavbar,
    ContactProfile,
    DynamicTitle,
    VuePerfectScrollbar,
    vSelect,
    FeatherIcon,
    InboxQuickMessageZoomModal,
    CustomSkeleton,
    createQuickMessagePopup,
  },
  data() {
    return {
      chooseChannel: false,
      noteMessage: false,
      inboxQuickMessageZoomModalMime: "",
      inboxQuickMessageZoomModalSrc: "",
      inboxQuickMessageZoomModalOpen: false,
      canPreviewTemplateMessage: false,
      textReplacedFromTemplateMessage: "",
      modalPreviewTemplateMessage: false,
      previewTemplateMessage: [],
      modalReplaceTemplateMessage: false,
      vitualListOverflow: false,
      vitualListFinished: false,
      conversationsItemComponent: ChatContact,
      chatLogKey: 1,
      quickMessageMedia: null,
      quickMessageMediaMimetype: "",
      messageFileUrl: null,
      messageFileMimetype: "",
      cloudChats: [],
      searchTemplate: "",
      inboxQuickMessagesWppAPIApprovedOnChatFiltered: [],
      active: true,
      activeContactIsClosed: false,
      alwaysactive: true,
      addConversationChat: null,
      addConversationIntention: "",
      addConversationEntity: "",
      addConversationLoading: false,
      addConversationName: "",
      addConversationPhoneCountryCode: "",
      addConversationPhone: "",
      addConversationNameDisabled: false,
      addConversationPhoneDisabled: false,
      addConversationContact: "",
      addConversationPopup: false,
      addConversationOnSearch: false,
      addConversationSearchLoading: false,
      conversationsSyncAlert: true,
      conversationsLoadingProgress: false,
      audioPlaying: false,
      conversationsNumbers: {
        waitingAttendance: 0,
        inAttendance: 0,
        inAttendancePending: 0,
        total: 0,
      },
      oprAllIntentions: false,
      clickNotClose: true,
      conversationSearchContacts: [],
      conversation: ["Abertas", "Fechadas"],
      contactsPagination: 0,
      contactListItemStart: 0,
      contactListItemEnd: 10,
      channelsConfig: [],
      chatOptions: [],
      changeDropdown: false,
      clLib: null,
      contactListHeightConfig: {},
      contactListItemHeight: 86,
      currentTabConversationsLength: 0,
      dropDownKey: -1,
      emojiKeyForceRerender: 0,
      filterIDs: [],
      filter: 0,
      filterUnreadOnly: false,
      selectOpen: false,
      filesToUpload: [],
      filesToUploadPreview: [],
      fileToUploadPreviewIndex: 0,
      fileToUploadPreviewPDFPageCount: 0,
      ia: {},
      isChatSidebarActive: true,
      isHidden: false,
      isFiltered: false,
      inputTypeBoxWithListeners: false,
      isChatPinned: false,
      isFirstLoad: true,
      isReconnection: false,
      inboxChannelsAvailable: [],
      inboxNewQuickMessagesFiltered: [],
      inboxIntentions: [],
      inboxLoaded: false,
      inboxQuickMessagesWppAPIApproved: [],
      inboxOperatorsList: [],
      inboxQuickMessagesFiltered: [],
      lastConversationID: undefined,
      inboxConversationsIDs: [],
      lastTimestamp: 0,
      loadingClosed: false,
      localImageFile: null,
      isLoadingConversations: false,
      showLoadMoreConversations: true,
      localDBTable: "inbox-unsended-messages",
      messagesListPage: 1,
      newQuickMessageVisible: false,
      notificationTitle: "",
      phaseList: [],
      placement: "top",
      proactiveChannels: [],
      replyMethod: { label: "Email", value: 0 },
      replyOptions: [""],
      showEndMessage: false,
      sendMessagesQueue: [],
      searchContact: "",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7,
      },
      showWppDisconnectedActive: false,
      showScrollMessagesListToBottom: false,
      scrollMessagesListToBottom: 0,
      selectAllOperators: true,
      socketConnOnline: true,
      socketConnOnlineCheckRoutine: () => {},
      socketContactListTimestamp: Date.now(),
      socketConversationTimestamp: Date.now(),
      socketLastPing: Date.now(),
      socketReconnect: false,
      startLivechatTimer: null,
      startTemplateConversationWPPApiAwait: false,
      searchQueryQuickMessages: "",
      searchQueryChanged: false,
      selectedQuickMessage: "",
      showDropzone: false,
      timerSearchQuery: null,
      timerSearchQueryContacts: null,
      totalConversations: 0,
      tabUpdate: 1,
      textareaListener: false,
      editVariablesTemplate: {},
      editVariablesTemplatesPopup: false,
      quickMessagePopup: {
        selectedQckType: null,
      },
      quickMessagesPopup: false,
      createQuickMessagesPopup: false,
      createQuickMessages: {
        title: "",
        intentions: [],
        entities: [],
        message: "",
      },
      createQuickMessagesOptions: {
        intentions: [],
        entities: [],
      },
      validPhone: true,
      wppTargetNotification: false,
      windowWidth: window.innerWidth,
      windowTitleInterval: null,
      isReady: false,
      calculateContactListHeightTimeout: null,
      conversationsLoadingSkeleton: [
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
      ],
      showConversationsSkeleton: true,
      conversationsLoadingSkeletonDown: [],
      conversations: [[], [], [], []],
      inboxSettingsData: {},
      closingConversationReasons: [],
      salesSuccessReasons: [],
      salesFailureReasons: [],
      inboxSignOperator: false,
      blockCreateConversationOperator: true,
      campaignConversations: [],
      waitingAttendanceConversations: [],
      inAttendanceConversations: [],

      waitingAttendanceLength: 0,
      inAttendanceLength: 0,
      inAttendancePendingLength: 0,
      campaignLength: 0,
      closedLength: 0,

      intentionConversationsCount: {},
      operatorConversationsCount: {},

      templateData: {
        name: "",
        message: "",
        components: [],
        customVariables: [],
      },
      showTemplate: false,
      mediaAudioInterval: null,
      mediaAudioRecordingMin: "00",
      mediaAudioRecordingSec: "00",
      tabsCounterStage: {},
      intentionFilter: null,
      operatorFilter: null,
      requestRetryDriver: {},
      chatGptSuggestionGenerated: false,
      useIndexedDB: false,
      inboxCampaignSyncDates: "",
      inboxWaitingAttendanceSyncDates: "",
      inboxInAttendanceSyncDates: "",

      inboxSearchPagination: 0,
      inboxCampaignPagination: 0,
      inboxWaitingAttendancePagination: 0,
      inboxInAttendancePagination: 0,
      inboxInClosedPagination: 0,
      inboxSearchLastUpdate: "",
      inboxCampaignLastUpdate: "",
      inboxWaitingAttendanceLastUpdate: "",
      inboxInAttendanceLastUpdate: "",
      inboxInClosedLastUpdate: "",
      conversationsLoadedStatus: {},
      deepLinkCalled: false,
      totalConversationsPerLoad: 20,
      generateChatGptSuggestionOngoing: false,
      conversationsCounter: {},
      fetchDeltaConversationsRoutine: () => {},
      optIn: false,
      tabId: null,
      otherTabOpen: false,
      fetchFiltersCounterOnGoing: false,
      hideFiltersCounters: false,
      messageQueue: new Set(),
      originalCustomTemplateMessage: "",
    };
  },
  computed: {
    inboxChannelDisabled() {
      return (
        this.inboxChannels.length === 1 &&
        this.inboxChannels[0] !== this.channel
      );
    },
    manyChannelsAvailable() {
      return (
        this.inboxChannels.length > 1 ||
        (this.inboxChannels.length === 1 &&
          this.inboxChannels[0] !== this.channel)
      );
    },
    isSearch() {
      return (
        !!this.searchQuery ||
        !!this.operatorFilter ||
        !!this.intentionFilter ||
        !!this.filterUnreadOnly
      );
    },
    isInboxUsageAvailable() {
      return this.socketConnOnline && this.isReady;
    },
    currentTabTotalConversations() {
      switch (this.activeTab) {
        case 0:
          return this.campaignLength;
        case 1:
          return this.waitingAttendanceLength;
        case 2:
          return this.inAttendanceLength;
        default:
          return 0;
      }
    },
    inboxWarningFlags: {
      get: function () {
        return this.$store.state.chat.inboxWarningFlags;
      },
      set: async function (data) {
        this.$store.dispatch("chat/setInboxWarningFlags", data);
      },
    },
    currentChannelsAvailable: {
      get: function () {
        return (
          this.inboxChannelsAvailable.find(
            (el) => el.chat === this.activeContact.chatID
          ) || {}
        );
      },
    },
    blockWaitingServiceTab: {
      get: function () {
        return this.$store.state.chat.blockWaitingServiceTab;
      },
      set: async function (data) {
        this.$store.dispatch("chat/setBlockWaitingServiceTab", data);
      },
    },
    activeContact: {
      get: function () {
        return this.$store.state.chat.activeContact;
      },
      set: async function (data) {
        await this.$store.dispatch("chat/setActiveConversation", data);
      },
    },
    firstID: {
      get: function () {
        return localStorage.getItem("inboxFirstID")
          ? localStorage.getItem("inboxFirstID")
          : null;
      },
      set: async function (data) {
        return localStorage.setItem("inboxFirstID", data);
      },
    },
    lastID: {
      get: function () {
        return localStorage.getItem("inboxLastID")
          ? localStorage.getItem("inboxLastID")
          : null;
      },
      set: async function (data) {
        return localStorage.setItem("inboxLastID", data);
      },
    },
    currentTabConversations: {
      get: function () {
        return this.isFiltered
          ? this.$store.state.chat.currentTabConversations.filter((el) =>
              this.filterIDs.includes(el.id)
            )
          : this.$store.state.chat.currentTabConversations;
      },
      set: async function (data) {
        this.$store.dispatch("chat/currentTabConversations", data);
      },
    },
    activeUserName() {
      return this.$store.state.user.name.trim();
    },
    quickMessageMediaUrl() {
      return typeof this.quickMessageMedia === "string"
        ? this.quickMessageMedia
        : URL.createObjectURL(this.quickMessageMedia);
    },
    replyMessage() {
      return this.$store.state.chat.chatReplyMessage;
    },
    inboxQuickMessagesWppAPIApprovedOnChat() {
      const thisIns = this;
      let messages = [];

      this.inboxQuickMessagesWppAPIApproved.forEach((el) => {
        if (el.components) {
          if (el.status.toLowerCase() === "approved") {
            el.components.map((el2) => {
              if (el2.type.toLowerCase().includes("body")) {
                messages.push({ ...el, message: el2.text });
              }
            });
          }
        } else if (el.whatsappApiTemplates) {
          el.whatsappApiTemplates.forEach((el2) => {
            if (el2.chat === thisIns.activeContact.chatID)
              messages.push({ ...el, template: el2 });
          });
        }
      });
      return messages;
    },
    currentAccId() {
      return this.$store.state.acc.current_acc.id;
    },
    accHasAIFeature() {
      return (
        this.$store.state.acc.current_acc.aiEnable ||
        this.currentAccId === "60ad40a8cf24431d122b2433" ||
        this.currentAccId === "608c60d25e9671ec25384820" ||
        this.currentAccId === "5f5a4806e7a7bc318ae589cc" ||
        this.currentAccId === "64e7286b799c8d04217445ee" ||
        this.currentAccId === "626a9da67edc070332477a55" ||
        this.currentAccId === "626a9d947edc07e34c4776a1" ||
        this.currentAccId === "64f774f21bb62e5a0f0ae7be" ||
        this.currentAccId === "634f2828d73776a6886df536" ||
        this.currentAccId === "638ddbce63ef447b55b5f6b1" ||
        this.currentAccId === "63286decb79ebf3b3384367e" ||
        this.currentAccId === "6298bbf57d445a5dec90e060" ||
        this.currentAccId === "62c5a4e1f45755f275bd8d1d" ||
        this.currentAccId === "62c5a4e1f45755f275bd8d1d" ||
        this.currentAccId === "623b367aadbb4d4aa6802372" ||
        this.currentAccId === "63a36fd60a079cd7b75de1ed" ||
        this.currentAccId === "63a36fe60a079c2e6d5de573" ||
        this.currentAccId === "647de082c17f5f6bc518bcca" ||
        this.currentAccId === "647de0c5f88ea8e8edbbd3dd" ||
        this.currentAccId === "647de0ddfdf35443e301ebcf" ||
        this.currentAccId === "647de0f4fdf354294f01ef36" ||
        this.currentAccId === "647de106f88ea8652cbbde82" ||
        this.currentAccId === "647de11ed44b0d4ad326c9d2" ||
        this.currentAccId === "647de131821e83a789e02735" ||
        this.currentAccId === "647de37afdf354c84502809b" ||
        this.currentAccId === "647de399d44b0d36682757c2" ||
        this.currentAccId === "647de3af7c43cdc9d2c25192" ||
        this.currentAccId === "647e12ed2e3fd754b5375ef1" ||
        this.currentAccId === "648c67528f79849e304b2205" ||
        this.currentAccId === "64a5870d3ef4914948a88910" ||
        this.currentAccId === "64a5871e997f109a046f4c4c" ||
        this.currentAccId === "6310a6c603f50d057e202d02" ||
        this.currentAccId === "6310a75f03f50d65af207d68" ||
        this.currentAccId === "63a36faa51d3cd17c1e400a3" ||
        this.currentAccId === "63ab38651b5aadfa16ab87b6" ||
        this.currentAccId === "63efc042b2d31059ad35d66e"
      );
    },
    activeUserInfo() {
      return this.$store.state.user;
    },
    chats() {
      return this.$store.getters["chat/chats"];
    },
    ...mapState("chat", [
      "teamChatOpen",
      "searchQuery",
      "currentOperator",
      "inboxOperatorsArrayStored",
      "inboxChannels",
      "currentAttendance",
      "attendance",
      "activeProfileSidebar",
      "activeConversationId",
      "userProfileId",
      "participants",
      "blob",
      "audioSource",
      "recorder",
      "activeTabName",
      "intentionsArrayStored",
      "entitiesArray",
      "allIntentions",
      "allEntities",
      "notificationCounter",
      "inboxQuickMessages",
    ]),
    channel: {
      get() {
        return this.$store.state.chat.channel;
      },
      set(val) {
        this.$store.dispatch("chat/setChannel", val);
      },
    },
    activeTab: {
      get: function () {
        return this.$store.state.chat.activeTab;
      },
      set: async function (tab) {
        if (tab === this.activeTab) return;

        this.operatorFilter = null;
        this.intentionFilter = null;
        this.showLoadMoreConversations = true;
        this.filterIDs = [];
        this.inboxSearchPagination = 0;
        this.isFiltered = false;

        if (this.$refs.contactsList && this.$refs.contactsList.$el)
          this.$refs.contactsList.$el.scrollTop = 0;
        if (this.currentOperator.acl === "operator" && tab === 0) tab = 1;
        this.isFirstLoad = true;

        if (localStorage.getItem("startConversation") !== null && tab !== 2) {
          localStorage.removeItem("startConversation");
        }

        await this.$store.dispatch("chat/setActiveTab", tab);
      },
    },
    typedMessage: {
      get: function () {
        const typedMessage = this.$store.state.chat.typedMessage
          ? this.$store.state.chat.typedMessage.find(
              (el) => el.id === this.activeContact.id
            )
          : false;
        return typedMessage ? typedMessage.message : "";
      },
      set: function (typedMessage) {
        if (!this.textareaListener) {
          this.textareaListener = true;
          const textarea = document.querySelector("#input-textarea-message");
          if (textarea) {
            textarea.addEventListener(
              "keypress",
              function (e) {
                let keyCode = e.charCode;
                if (keyCode === 13 && !e.shiftKey) e.preventDefault();
              },
              false
            );
          }
        }
        this.$store.dispatch("chat/setTypedMessage", {
          id: this.activeContact.id,
          message: typedMessage,
        });
      },
    },
    messageList: {
      get: function () {
        return this.$store.state.chat.messageList;
      },
      set: async function (messageList) {
        if (messageList.length > 0) {
          const unsendedMessage = await this.$db
            .collection(this.localDBTable)
            .doc(this.activeContact.id)
            .get();
          if (
            unsendedMessage &&
            unsendedMessage.messages &&
            Array.isArray(unsendedMessage.messages)
          ) {
            for (let i = 0; i < unsendedMessage.messages.length; i += 1)
              if (
                messageList.find(
                  (el) => el.uid === unsendedMessage.messages[i].uid
                )
              )
                this.removeUnsendedMessage(
                  this.activeContact.id,
                  unsendedMessage.messages[i].uid
                );
            messageList = messageList.concat(unsendedMessage.messages);
            messageList.sort((a, b) => {
              if (typeof a.timestamp === "string")
                a.timestamp = new Date(a.timestamp).getTime();
              if (typeof b.timestamp === "string")
                b.timestamp = new Date(b.timestamp).getTime();
              return a.timestamp - b.timestamp;
            });
          }
        }
        this.$store.dispatch("chat/setMessageList", messageList);
      },
    },
    filterIsActive() {
      return this.intentionFilter || this.operatorFilter ? "filled" : "border";
    },
    searchQuery: {
      get: function () {
        return this.$store.state.chat.searchQuery;
      },
      set: function (data) {
        this.$store.dispatch("chat/setSearchQuery", data);
      },
    },
    addConversationDisabled() {
      return !(
        this.currentAccId &&
        this.addConversationIntention &&
        this.currentOperator._id &&
        this.validPhone &&
        (this.addConversationChat ? this.addConversationChat.value : null) &&
        this.optIn
      );
    },
    currentOperatorIsManager() {
      if (this.currentOperator.acl === "admin") return true;
      else return this.currentOperator.acl === "manager";
    },
    emojiDataAll() {
      if (EmojiAllData && EmojiAllData[1] && EmojiAllData[1].emojiList)
        EmojiAllData[1].emojiList.splice("16", 1);
      return EmojiAllData;
    },
    emojiGroups() {
      return EmojiGroups;
    },
    emojiPickerSize() {
      let size = window.innerWidth * 0.2;
      if (window.innerWidth < 1300) size = 300;
      return size;
    },
    operatorIntentions() {
      let intentions = [];
      const flowIntentions = [];
      const flowIntentionsArr = this.chatOptions.map(
        (el) => el.data.intentions
      );

      flowIntentionsArr.forEach((elArr) => {
        elArr.forEach((el) => {
          flowIntentions.push(el.replace("#", ""));
        });
      });

      if (
        this.addConversationChat &&
        this.addConversationChat.data &&
        this.addConversationChat.data.intentions
      ) {
        this.intentionsArrayStored.forEach((el) => {
          if (
            (this.addConversationChat.data.intentions.indexOf(`#${el}`) > -1 ||
              flowIntentions.indexOf(el) === -1) &&
            intentions.indexOf(el) === -1
          )
            intentions.push(el);
        });

        if (this.addConversationChat.data.isAssistant) {
          intentions = [
            ...this.addConversationChat.data.intentions,
            ...intentions,
          ];
        }
      }

      if (
        (this.intentionsArrayStored.indexOf("") > -1 ||
          this.intentionsArrayStored.indexOf("outros") > -1) &&
        intentions.indexOf("outros") === -1
      )
        intentions.push("outros");
      return intentions;
    },
    operatorEntities() {
      let entities = [];
      const flowEntities = [];
      const flowEntitiesArr = this.chatOptions.map((el) => el.data.keyEntities);
      flowEntitiesArr.forEach((elArr) => {
        elArr.forEach((el) => {
          flowEntities.push(el);
        });
      });
      if (
        this.addConversationChat &&
        this.addConversationChat.data &&
        this.addConversationChat.data.keyEntities
      )
        this.entitiesArray.forEach((el) => {
          if (
            (this.addConversationChat.data.keyEntities.indexOf(el) > -1 ||
              flowEntities.indexOf(el) === -1) &&
            entities.indexOf(el) === -1
          )
            entities.push(el);
        });
      return entities.sort();
    },
    filterTemplateQuickMessages() {
      if (this.searchQueryQuickMessages !== "") {
        return this.inboxQuickMessages.filter(
          (i) =>
            normalizeString(i.message).includes(
              normalizeString(this.searchQueryQuickMessages)
            ) ||
            normalizeString(i.title).includes(
              normalizeString(this.searchQueryQuickMessages)
            )
        );
      } else {
        return this.inboxQuickMessages;
      }
    },
    inboxQuickMessagesFileName() {
      if (this.messageFileMimetype.includes("image")) return "Imagem";
      if (this.messageFileMimetype.includes("video")) return "Video";
      return "Arquivo";
    },
  },
  watch: {
    channel() {
      this.getQuickMessagesWppApi();
    },
    searchTemplate(val) {
      this.handleSearchTemplates(val);
    },
    waitingAttendanceLength() {
      this.tabUpdate += 1;
    },
    inAttendanceLength() {
      this.tabUpdate += 1;
    },
    isLoadingConversations(val) {
      if (val)
        setTimeout(() => {
          this.isLoadingConversations = false;
        }, 120000);
    },
    addConversationPopup: function (val) {
      this.validPhone = true;
      if (!val) {
        this.addConversationLoading = false;
        this.addConversationIntention = "";
        this.addConversationEntity = "";
        this.addConversationContact = "";
        this.addConversationName = "";
        this.addConversationNameDisabled = false;
        this.addConversationPhone = "";
        this.addConversationPhoneDisabled = false;
        this.addConversationPhoneCountryCode = "";
      }
    },
    addConversationContact: function (val) {
      this.addConversationName = val && val.name ? val.name : "";
      this.addConversationNameDisabled = !!(val && val.name);
      this.addConversationPhone = val && val.phone ? val.phone : "";
      this.addConversationPhoneDisabled = !!(val && val.name);
      const chatIndex = this.chatOptions.findIndex(
        (el) => el.value === val.chat
      );
      if (chatIndex > -1)
        this.addConversationChat = {
          label: this.chatOptions[chatIndex].label,
          value: this.chatOptions[chatIndex].value,
          data: this.chatOptions[chatIndex].data,
        };
      else if (this.chatOptions.length > 1)
        this.addConversationChat = { label: "", value: "" };
    },
    activeTab: async function (val, oldVal) {
      if (val === oldVal || !this.isReady) return;
      if (val === 0 || oldVal === 0) this.searchQuery = "";
      this.showConversationsSkeleton = true;

      if (oldVal === 3) this.$vs.loading.close();
      this.resetActiveChatUser();
      if (val === 3) {
        this.showLoadMoreConversations = true;
        this.initClosedTab();
      }
      this.inboxSearchLastUpdate = "";
      this.inboxSearchPagination = 0;

      if (!this.isFiltered && !this.isSearch)
        await this.fetchConversations({
          isTabChange: true,
          loadSize: 100,
          page: 0,
          isDeltaLoad: true,
          closedConversations: val === 3,
        });
      else await this.searchAndFilter();

      this.showLoadMoreConversations = true;
      if (this.$route.query.conversation) {
        this.pushRouteQueryParams(
          {
            acc: this.$store.state.acc.current_acc.id,
            activeTab: val,
          },
          true
        );
      }
    },
    activeProfileSidebar: function (val) {
      if (val) this.$refs.chatlog.style = "padding-right: 349px;";
      else this.$refs.chatlog.style = "";
    },
    activeConversationId: function (val) {
      this.chatLogKey++;
      if (val) {
        const contact = this.conversations[this.activeTab].find(
          (el) => el.id === val
        );
        if (contact)
          this.$store.dispatch("chat/addOnCurrentAttendance", contact);
      }
    },
    filterUnreadOnly() {
      this.filterConversations({ forceSearch: true });
    },
    intentionFilter() {
      if (this.activeTab === 3) return;
      this.$vs.loading();
      this.filterIDs = [];
      this.inboxSearchPagination = 0;
      setTimeout(() => {
        this.searchAndFilter();
      }, 500);
    },
    operatorFilter() {
      if (this.activeTab === 3) return;
      this.$vs.loading();
      this.filterIDs = [];
      this.inboxSearchPagination = 0;
      setTimeout(() => {
        this.searchAndFilter();
      }, 500);
    },
    selectAllOperators(checked) {
      if (checked)
        this.$store.dispatch(
          "chat/setInboxOperatorsArray",
          this.inboxOperatorsArrayStored.map((operator) => operator.value)
        );
      else this.$store.dispatch("chat/setInboxOperatorsArray", []);
    },
    notificationCounter(val) {
      this.changeWindowTitle(val);
    },
    activeContact(newVal, oldVal) {
      if (newVal && oldVal && newVal.id && oldVal.id && newVal.id === oldVal.id)
        return;
      if (
        !oldVal.closed &&
        oldVal.sessionID &&
        (!newVal || newVal.sessionID !== oldVal.sessionID)
      )
        socket.emit("leave conversation", {
          sessionID: oldVal.sessionID,
        });
      this.filesToUpload = [];
      this.filesToUploadPreview = [];
      this.fileToUploadPreviewIndex = 0;
      this.activeContactIsClosed = !!newVal.closed;
    },
    filesToUpload(arr) {
      const chatbtn = document.getElementById("teamChatFrame");
      if (arr.length === 0 && chatbtn) chatbtn.style.display = "block";
      else if (chatbtn) chatbtn.style.display = "none";
    },
    selectedQuickMessage: function (val) {
      if (val) {
        this.selectQuickMessage(val);
        this.dropDownKey = Math.random();
        this.selectedQuickMessage = "";
        this.searchQueryQuickMessages = "";
      }
    },
    typedMessage() {
      if (this.typedMessage.length === 0) {
        this.chatGptSuggestionGenerated = false;
      }
    },
    searchQuickMessage() {
      this.filterQuickMessages();
    },
    searchQuery() {
      this.searchQueryChanged = true;
    },
    modalReplaceTemplateMessage() {
      if (!this.modalReplaceTemplateMessage) this.previewTemplateMessage = [];
    },
    previewTemplateMessage: {
      deep: true,
      handler() {
        if (
          this.previewTemplateMessage.length &&
          this.templateData.customVariables.length
        ) {
          this.canPreviewTemplateMessage = this.previewTemplateMessage.every(
            (preview) => {
              return preview.value.length > 0;
            }
          );
        } else {
          this.canPreviewTemplateMessage = false;
        }
      },
    },
    modalPreviewTemplateMessage(val) {
      if (!val) this.templateData.message = this.originalCustomTemplateMessage;
    },
  },
  async created() {
    this.tabId = Date.now().toString(36) + Math.random().toString(36).slice(2);
    localStorage.setItem("tabId", this.tabId);
    localStorage.setItem("otherTabId", this.tabId);
    this.checkOtherTabs();

    window.addEventListener("storage", this.onStorageChange);
    window.addEventListener("beforeunload", this.onBeforeUnload);

    this.$on("changeActiveConversation", (contact) => {
      this.updateActiveConversation(contact, contact.id);
    });
    this.$store.dispatch("chat/setNotificationCounter", 0);
    this.getQuickMessages();
    axios
      .get("/p/chat/inbox/phases", {
        params: { acc: this.$store.state.acc.current_acc.id },
      })
      .then((response) => {
        this.phaseList = response.data.data;
      })
      .catch(() => {});

    /* end socket */
    this.getChats();
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebarWidth();
  },
  async mounted() {
    //STARTUP INBOX ATTENDANCE
    await this.initializeInbox();
    if (!this.isReady) {
      this.showConversationsSkeleton = true;
    }

    document.body.style = "overflow: hidden;";
    //Center archive tab icon
    setTimeout(() => {
      if (this.$refs.tabArchive)
        this.$refs.tabArchive.$refs.ul
          .getElementsByTagName("li")[2]
          .getElementsByTagName("button")[0].style =
          "display: flex; align-items: center; justify-content: center";
    }, 100);

    this.socketConnOnlineCheckRoutine = async () => {
      if (!navigator.onLine && this.socketConnOnline) {
        try {
          const conStatusHttp = await axios.get("/ping", { timeout: 1500 });
          if (conStatusHttp.status !== 200) this.socketConnOnline = false;
          else this.socketConnOnline = navigator.onLine;
        } catch (err) {
          this.socketConnOnline = false;
        }
        socket.disconnect();
      } else if (navigator.onLine && !this.socketConnOnline) {
        socket.connect();
        this.socketConnOnline = true;
      }
    };

    this.fetchDeltaConversationsRoutine = setInterval(async () => {
      await this.fetchConversations({
        loadSize: 100,
        page: 0,
        isDeltaLoad: true,
      });
    }, 60000); // Fetch deltas conversations every 60 seconds

    window.addEventListener("online", this.socketConnOnlineCheckRoutine);
    window.addEventListener("offline", this.socketConnOnlineCheckRoutine);

    if (!socket.connected) {
      this.isLoadingConversations = true;
    }
    if (this.$refs.chatlog !== undefined) {
      if (this.$store.state.chat.activeProfileSidebar)
        this.$refs.chatlog.style = "padding-right: 349px;";
      else this.$refs.chatlog.style = "";
    }

    this.toggleChatSidebar(true);
    let lastTarget = null;
    window.addEventListener("dragover", (event) => {
      event.preventDefault();
    });

    window.addEventListener("dragenter", (event) => {
      if (this.channelAcceptMedia(this.activeContact.channel)) {
        lastTarget = event.target;

        this.showDropzone = true;
      }
    });

    window.addEventListener("dragleave", (event) => {
      if (this.channelAcceptMedia(this.activeContact.channel)) {
        event.preventDefault();
        if (event.target === lastTarget || event.target === document) {
          this.showDropzone = false;
        }
      }
    });

    window.addEventListener("drop", this.onDrop);
  },
  beforeDestroy: function () {
    if (localStorage.getItem("startConversation"))
      localStorage.removeItem("startConversation");

    window.removeEventListener("resize", this.handleWindowResize);
    this.$store.dispatch("chat/reset");
    this.closeAttendance(true);
    clearInterval(this.socketConnOnlineCheckRoutine);
    clearInterval(window.inboxSocketPing);
    clearInterval(this.fetchDeltaConversationsRoutine);
    window.inboxSocketPing = null;

    window.removeEventListener("storage", this.onStorageChange);
    window.removeEventListener("beforeunload", this.onBeforeUnload);
    if (localStorage.getItem("otherTabId") === this.tabId) {
      localStorage.removeItem("otherTabId");
    }
    document.removeEventListener(
      "click",
      this.handleClickOutsideChangeChannelDiv
    );
  },
  methods: {
    operatorCanSeeConversation(conversation) {
      if (!conversation._id) return false;

      const { operator, intentions, entities } = conversation;
      const { _id, acl } = this.currentOperator;

      if (operator && operator !== _id && acl === "operator") return false;

      if (intentions) {
        const noAccessIntentions =
          !this.oprAllIntentions && intentions.length > 0;
        const noMatchingIntentions =
          noAccessIntentions &&
          !intentions.some((intent) =>
            this.intentionsArrayStored.includes(intent)
          );

        if (
          (intentions.length === 0 &&
            !this.oprAllIntentions &&
            !this.intentionsArrayStored.includes("")) ||
          noMatchingIntentions
        )
          return false;
      }

      if (
        entities &&
        this.currentOperator.entities.length > 0 &&
        entities.length > 0 &&
        !entities.some((entity) =>
          this.currentOperator.entities.includes(entity)
        )
      )
        return false;

      if (
        this.currentOperator.entities.length &&
        (!entities || !entities.length)
      )
        return false;

      if (!this.entitiesArray.length && entities && entities.length)
        return false;
      return true;
    },
    closeCreatePopup() {
      this.createQuickMessagesPopup = false;
    },
    changeQckType(value) {
      this.quickMessagePopup.selectedQckType = value;
    },
    bindChangeChannelDiv(forceState) {
      this.chooseChannel =
        forceState !== undefined ? forceState : !this.chooseChannel;
      if (this.chooseChannel)
        setTimeout(() => {
          document.addEventListener(
            "click",
            this.handleClickOutsideChangeChannelDiv
          );
        }, 100);
      else
        document.removeEventListener(
          "click",
          this.handleClickOutsideChangeChannelDiv
        );

      this.typedMessage = "";
    },
    handleClickOutsideChangeChannelDiv(event) {
      if (
        this.$refs.changeChannelDiv &&
        !this.$refs.changeChannelDiv.contains(event.target)
      ) {
        this.chooseChannel = false;
        this.noteMessage = false;
        document.removeEventListener(
          "click",
          this.handleClickOutsideChangeChannelDiv
        );
      }
    },
    setChannel(channel) {
      this.chooseChannel = false;
      this.channel = channel;
      this.noteMessage = false;
      this.typedMessage = "";
      document.removeEventListener(
        "click",
        this.handleClickOutsideChangeChannelDiv
      );
    },
    openModalInboxQuickMessages(messageFileMimetype, messageFileUrl) {
      this.inboxQuickMessageZoomModalMime = messageFileMimetype;
      this.inboxQuickMessageZoomModalSrc = messageFileUrl;
      this.inboxQuickMessageZoomModalOpen = true;
    },
    openModalReplaceTemplateMessage() {
      this.modalReplaceTemplateMessage = true;
    },
    templateMessagePreview() {
      this.originalCustomTemplateMessage = this.templateData.message;
      let rawMessage = this.templateData.message;

      if (rawMessage.includes("{NOME_CONTATO}"))
        rawMessage = rawMessage.replaceAll(
          "{NOME_CONTATO}",
          "<b>" + this.activeContact.name + "</b>"
        );

      if (rawMessage.includes("{NOME_ATENDENTE}")) {
        const operatorName = this.inboxOperatorsArrayStored.find(
          (i) => i.value === this.currentOperator._id
        ).label;
        rawMessage = rawMessage.replaceAll(
          "{NOME_ATENDENTE}",
          "<b>" + operatorName + "</b>"
        );
      }
      if (rawMessage.includes("{ID_CONVERSA}")) {
        rawMessage = rawMessage.replaceAll(
          "{ID_CONVERSA}",
          "<b>" + this.activeContact.id + "</b>"
        );
      }
      this.previewTemplateMessage.forEach((previewTemplateMessage) => {
        rawMessage = rawMessage.replace(
          previewTemplateMessage.label,
          "<b>" + previewTemplateMessage.value + "</b>"
        );
        this.templateData.message = this.templateData.message.replace(
          previewTemplateMessage.label,
          previewTemplateMessage.value
        );
      });

      this.textReplacedFromTemplateMessage = rawMessage;
    },
    closeModalPreviewTemplateMessage() {
      this.modalPreviewTemplateMessage = false;
      this.templateData.message = this.originalCustomTemplateMessage;
    },
    toggleReloadPageModal() {
      //t
    },
    async conversationsGet() {
      return this.conversations[this.activeTab];
    },
    async conversationGet(id) {
      let conversation;
      if (this.conversationsLoadedStatus[id])
        for (let i = 0; i < this.conversations.length; i += 1) {
          if (!conversation)
            conversation = this.conversations[i].find((el) => el.id === id);
          if (conversation) continue;
        }
      return conversation;
    },
    async conversationsBulk(conversations) {
      if (
        conversations &&
        Array.isArray(conversations) &&
        conversations.length
      ) {
        if (!this.conversations[this.activeTab].length) {
          this.conversations[this.activeTab] = conversations;
          const conversationIDs = conversations.map((el) => el.id);
          conversationIDs.forEach((id) => {
            this.conversationsLoadedStatus[id] = true;
          });
        } else {
          for (let i = 0; i < conversations.length; i += 1) {
            await this.conversationCreateOrUpdate(conversations[i], {
              skipCurrentTabUpdate: true,
            });
          }
          this.updateCurrentTabConversations();
        }
      }
    },
    async conversationCreateOrUpdate(conversation, options = {}) {
      if (!options.isFilter) {
        if (this.conversationsLoadedStatus[conversation.id]) {
          const DBconversation = await this.conversationGet(conversation.id);

          if (DBconversation) {
            if (
              this.currentOperator.acl === "operator" &&
              conversation.operator &&
              conversation.operator !== this.currentOperator._id
            ) {
              if (conversation.id === this.activeConversationId)
                this.resetActiveChatUser();
              await this.conversationDelete(conversation.id);
              return conversation;
            }

            if (
              this.activeTab !== 3 &&
              ((conversation.id &&
                conversation.closed !== undefined &&
                conversation.closed === true) ||
                (conversation.status !== undefined &&
                  conversation.status !== 1))
            ) {
              await this.conversationDelete(conversation.id);
              return conversation;
            }

            if (conversation.inAttendance === true) {
              if (
                DBconversation.inAttendance === false &&
                conversation.inAttendance === true
              ) {
                this.waitingAttendanceLength -= 1;
                this.inAttendanceLength += 1;
                if (
                  this.activeConversationId &&
                  DBconversation.id === this.activeConversationId
                ) {
                  await this.updateActiveConversation(
                    DBconversation,
                    DBconversation.id
                  );
                }
              }
            }
          }
        }
      }

      if (options.newConversation) {
        if (conversation.inAttendance === false)
          this.waitingAttendanceLength += 1;
        else if (conversation.inAttendance === true)
          this.inAttendanceLength += 1;
      }

      if (!this.useIndexedDB) {
        let activeTab = this.getConversationTab(conversation);
        let DBconversation = conversation;
        if (this.conversationsLoadedStatus[conversation.id]) {
          DBconversation = await this.conversationGet(conversation.id);
          activeTab = this.getConversationTab(DBconversation);
          for (let i = 0; i < this.conversations.length; i += 1) {
            const itemIndex = this.conversations[i].findIndex(
              (el) => el.id === conversation.id
            );
            if (itemIndex > -1) {
              DBconversation = {
                ...this.conversations[i][itemIndex],
                ...conversation,
              };
              if (
                this.activeConversationId &&
                DBconversation.id === this.activeConversationId
              )
                this.activeContact = DBconversation;
              this.conversations[i][itemIndex] = DBconversation;
              activeTab = this.getConversationTab(
                this.conversations[i][itemIndex]
              );

              if (i !== activeTab) {
                DBconversation = JSON.parse(
                  JSON.stringify(this.conversations[i][itemIndex])
                );
                this.conversations[i].splice(itemIndex, 1);
                this.conversations[activeTab].push(DBconversation);
              }
              continue;
            }
          }
        } else if (conversation.name) {
          this.conversationsLoadedStatus[conversation.id] = true;
          this.conversations[activeTab].push(conversation);
        }
        if (!options.skipCurrentTabUpdate) this.updateCurrentTabConversations();
        return DBconversation;
      }
      this.updateCurrentTabConversations();

      if (!options.isFilter) {
        const DBconversation = await this.conversationGet(conversation.id);
        if (DBconversation) {
          if (
            this.activeContact &&
            conversation.id === this.activeContact.id &&
            conversation.wppApiOn24hWindow !== undefined
          ) {
            await this.updateActiveConversation(
              { ...DBconversation, ...conversation },
              DBconversation.id
            );
          }
          return { ...DBconversation, ...conversation };
        }
      }

      return {};
    },
    async conversationDelete(id) {
      if (this.conversationsLoadedStatus[id])
        for (let i = 0; i < this.conversations.length; i += 1) {
          const conversationIndex = this.conversations[i].findIndex(
            (el) => el.id === id
          );
          if (conversationIndex > -1) {
            this.conversations[i].splice(conversationIndex, 1);

            if (i === 0 && this.campaignLength > 0) this.campaignLength -= 1;
            if (i === 1 && this.waitingAttendanceLength > 0)
              this.waitingAttendanceLength -= 1;
            if (i === 2 && this.inAttendanceLength > 0)
              this.inAttendanceLength -= 1;
          }
          this.updateCurrentTabConversations();
          continue;
        }
    },
    async fetchFiltersCounter() {
      try {
        if (this.searchQuery || this.filterUnreadOnly) {
          this.hideFiltersCounters = true;
          this.fetchFiltersCounterOnGoing = false;
          this.intentionConversationsCount = {};
          this.operatorConversationsCount = {};
          return;
        }
        this.hideFiltersCounters = false;
        this.fetchFiltersCounterOnGoing = true;
        const response = await this.$http.get(
          `/p/chat/inbox/load-delta-data/${this.$store.state.acc.current_acc.id}`,
          {
            params: {
              loadType: "filterCounters",
              acc: this.$store.state.acc.current_acc.id,
              intention: this.intentionFilter,
              operator: this.operatorFilter,
            },
          }
        );
        this.fetchFiltersCounterOnGoing = false;
        if (!response || !response.data || !response.data.data) return;
        if (!this.intentionFilter || !!this.operatorFilter)
          this.intentionConversationsCount = {};
        if (!this.operatorFilter || !!this.intentionFilter)
          this.operatorConversationsCount = {};
        const data = response.data.data;
        if (data.conversationsCounter.conversationByIntention)
          Object.keys(
            data.conversationsCounter.conversationByIntention
          ).forEach((key) => {
            this.intentionConversationsCount[key] =
              data.conversationsCounter.conversationByIntention[key];
          });
        if (data.conversationsCounter.conversationByAttendant)
          Object.keys(
            data.conversationsCounter.conversationByAttendant
          ).forEach((key) => {
            this.operatorConversationsCount[key] =
              data.conversationsCounter.conversationByAttendant[key];
          });
      } catch (e) {
        this.fetchFiltersCounterOnGoing = false;
      }
    },
    getConversationTab(conversation) {
      if (!conversation) return this.activeTab;
      if (conversation.closed === true) return 3;
      else if (
        !conversation.inAttendance &&
        conversation.origin !== "Inbound" &&
        conversation.origin !== "Outbound" &&
        !conversation.pendingResponse
      )
        return 0;
      else if (
        !conversation.inAttendance &&
        (conversation.origin === "Inbound" ||
          conversation.origin === "Outbound" ||
          conversation.pendingResponse)
      )
        return 1;
      else if (conversation.inAttendance) return 2;
      return this.activeTab;
    },
    async updateCurrentTabConversations(options = {}) {
      const self = this;
      //if (this.activeTab === 3) return;

      const conversations =
        options.isFilter && !options.setDBConversations
          ? this.conversations[this.activeTab]
          : await this.conversationsGet();

      let currentTabConversations = [];
      if (conversations.length > 0)
        currentTabConversations = conversations.filter(function (item) {
          return (
            (item.status === 1 && item.stage === 1 && self.activeTab === 0) ||
            (item.status === 1 && item.stage === 2 && self.activeTab === 1) ||
            (item.status === 1 && item.stage === 3 && self.activeTab === 2) ||
            (item.status === 4 && self.activeTab === 3)
          );
        });
      this.currentTabConversations =
        this.activeTab !== 3
          ? currentTabConversations.sort(function (a, b) {
              return b.timestamp - a.timestamp;
            })
          : currentTabConversations;
    },
    async serveLocalConversationsDocs() {
      await this.updateCurrentTabConversations({ setDBConversations: true });
      const lastAcc = localStorage.getItem("inboxLastAccID");
      if (lastAcc && lastAcc !== this.currentAccId) {
        this.conversations = [[], [], [], []];
      }
    },
    chatLogScrollTop(scrollTop) {
      this.showScrollMessagesListToBottom = true;
      this.$refs.chatLog.scrollTop = scrollTop;
    },
    onChatLogScroll({ target: { scrollTop } }) {
      if (scrollTop <= 0) this.messagesListPage += 1;
    },
    handleRecording() {
      window.analytics.track(
        "Enviar audio",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      !this.$store.state.chat.recorder ||
      this.$store.state.chat.recorder.state !== "recording"
        ? this.startRecording()
        : this.stopRecording();
    },
    removeMessageFileUrl() {
      this.messageFileUrl = null;
      this.messageFileMimetype = "";
    },
    quickMessagePreviewFile(e) {
      if (!e.target.files.length) {
        this.quickMessageMedia = null;
        return;
      }

      this.quickMessageMedia = e.target.files[0];

      this.quickMessageMediaMimetype = this.quickMessageMedia.type || "";
    },
    postponeConversation({ postponedTo }) {
      this.$vs.loading();
      socket.emit(
        "postpone conversation",
        {
          postponedTo,
          contactSessionID: this.activeContact.contactSessionID,
          operatorID: this.currentOperator._id,
        },
        () => {
          this.$vs.loading.close();
        }
      );
    },
    handleSearchTemplates(search) {
      if (search.length > 0) {
        const searchClean = normalizeString(search);
        this.inboxQuickMessagesWppAPIApprovedOnChatFiltered =
          this.inboxQuickMessagesWppAPIApprovedOnChat.filter((e) => {
            if (e.title || e.name) {
              const title = e.title || e.name;
              if (title && normalizeString(title).includes(searchClean))
                return true;
            }
            if (e.message)
              return normalizeString(e.message).includes(searchClean);
            return false;
          });
      } else {
        this.inboxQuickMessagesWppAPIApprovedOnChatFiltered =
          this.inboxQuickMessagesWppAPIApprovedOnChat;
      }
    },
    onReplyMessage() {
      this.$refs.inputTextareaMessage.focus();
    },
    onReaction(msgId, emoji) {
      if (!msgId) return;

      const message = this.messageList.find((e) => e.id === msgId);

      this.$store.dispatch("chat/updateMessageOnMessageList", {
        _id: message._id || -1,
        messageID: message.id,
        payload: {
          data: message.data,
          reactions: {
            ...message.reactions,
            operator: emoji,
          },
        },
      });

      socket.emit("message reaction", {
        messageID: msgId,
        reaction: emoji,
        chatID: this.activeContact.chatID,
        conversationID: this.activeContact.contactSessionID,
        sessionID: this.activeContact.sessionID,
      });
    },
    selectNewQuickMessage(val) {
      if (val) {
        this.selectQuickMessage(val);
        this.newQuickMessageVisible = false;
        this.$refs.inputTextareaMessage.focus();
      }
    },
    inspectBar(e) {
      let key = e.key.toLowerCase();

      // check if message field is empty and stop the function
      if (
        (!this.typedMessage || this.typedMessage.trim() === "") &&
        key === "enter" &&
        !e.shiftKey
      )
        return;

      if (this.typedMessage.trim() <= 0) {
        this.messageFileUrl = null;
        this.messageFileMimetype = "";
      }

      let inputValue = this.typedMessage.trim();
      let search = inputValue.replaceAll("/", "").toLowerCase();

      if (inputValue[0] !== "/") {
        this.newQuickMessageVisible = false;

        if (key === "enter") this.sendMsg(e);

        return;
      }

      if (inputValue[0] !== "/" && key !== "/") return;
      this.inboxNewQuickMessagesFiltered = this.inboxQuickMessages;
      this.inboxQuickMessagesWppAPIApprovedOnChatFiltered =
        this.inboxQuickMessagesWppAPIApprovedOnChat;
      setTimeout(() => {
        this.newQuickMessageVisible = true;
      }, 30);

      if (search.length > 0) {
        this.inboxNewQuickMessagesFiltered =
          this.inboxNewQuickMessagesFiltered.filter((e) => {
            if (e.title.toLowerCase().includes(search)) {
              return true;
            } else return e.message.toLowerCase().includes(search);
          });
        this.inboxQuickMessagesWppAPIApprovedOnChatFiltered =
          this.inboxQuickMessagesWppAPIApprovedOnChatFiltered.filter((e) => {
            if (e.title.toLowerCase().includes(search)) {
              return true;
            } else return e.message.toLowerCase().includes(search);
          });
      }
    },
    addConversationSearchContacts(searchQuery = null) {
      this.addConversationOnSearch = searchQuery.length > 0;
      this.conversationSearchContacts = [];
      if (searchQuery.length > 2) {
        this.addConversationSearchLoading = true;
        if (this.timerSearchQueryContacts) {
          clearTimeout(this.timerSearchQueryContacts);
          this.timerSearchQueryContacts = null;
        }
        this.timerSearchQueryContacts = setTimeout(() => {
          this.addConversationSearchLoading = true;

          const self = this;

          this.$http
            .get("/p/chat/contacts", {
              params: {
                acc: this.$store.state.acc.current_acc.id,
                searchQuery,
                chat: this.addConversationChat.value,
              },
            })
            .then((response) => {
              self.addConversationSearchLoading = false;
              self.conversationSearchContacts = response.data.data.map(
                (contact) => {
                  return {
                    ...contact,
                    search: `${contact.name}${contact.name
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")}${contact.phone}${
                      contact.email ? contact.email : ""
                    }`,
                  };
                }
              );
              let startConversation = localStorage.getItem("startConversation");
              if (startConversation) {
                startConversation = JSON.parse(startConversation);
                this.addConversationContact =
                  this.conversationSearchContacts.find(
                    (e) =>
                      e.phone ===
                      startConversation.phone.replace(/[^A-Z0-9]+/gi, "")
                  ) || "";
              }
              localStorage.removeItem("startConversation");
            })
            .catch(() => {
              self.addConversationSearchLoading = false;
            });
        }, 800);
      } else self.addConversationSearchLoading = false;
    },
    async addConversation() {
      this.searchQuery = "";
      await this.filterConversations();
      this.addConversationLoading = true;

      let phone = String(
        (this.addConversationPhone && this.addConversationPhone.length > 0
          ? this.addConversationPhone
              .trim()
              .replace(/\t/g, "")
              .replace(/\r/g, "")
              .replace(/\n/g, "")
          : this.addConversationPhone
        ).replace(/\D/g, "")
      );

      const name =
        this.addConversationName && this.addConversationName.trim()
          ? this.addConversationName
              .replace(/[\t\r\n]/g, "")
              .split(" ")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" ")
          : this.addConversationName;

      if (this.addConversationContact === "")
        phone = this.addConversationPhoneCountryCode + phone;

      const acc = this.currentAccId;
      const intention = this.addConversationIntention;
      const entity = this.addConversationEntity;
      const operatorId = this.currentOperator._id;
      const validPhone = this.validPhone;
      const chat = this.addConversationChat
        ? this.addConversationChat.value
        : null;
      const optIn = this.optIn;

      if (validPhone && intention && name && phone && optIn) {
        await this.$http
          .post("/p/chat/conversation", {
            name,
            phone,
            acc,
            intention,
            entity,
            operatorId,
            chat,
            optIn,
          })
          .then(async (response) => {
            this.resetActiveChatUser();
            this.toggleChatSidebar(false);
            if (response.data.data) {
              const contact = await this.createNewConversation(
                response.data.data
              );
              this.addConversationContact = "";

              if (this.activeTab !== 2) this.activeTab = 2;

              await this.updateCurrentTabConversations();
              if (contact) {
                await this.updateActiveConversation(contact, contact.id);
                this.addConversationPopup = false;
                this.$vs.notify({
                  title: this.$t("Success"),
                  text: this.$t("AddConversationSuccess"),
                  iconPack: "feather",
                  icon: "icon-check",
                  color: "success",
                  position: "top-right",
                  time: 4000,
                });
              }
            }
          })
          .catch(async (res) => {
            // Block OPT OUT caso seja false.
            this.addConversationLoading = false;
            const { message, data } = JSON.parse(res.request.responseText);
            if (message === "Already exists") {
              const foundedConversation = await this.conversationGet(data.id);

              if (foundedConversation) {
                await this.updateActiveConversation(
                  foundedConversation,
                  foundedConversation.id
                );
                this.addConversationPopup = false;
                return;
              } else {
                await this.fetchConversations({ byID: data.id });
              }

              const intentionText =
                this.$t("AtDepartment") + " " + data.intention ||
                this.$t("Others");

              const operatorText = data.operator
                ? `${this.$t("AndAttendant")} ${data.operator}`
                : "";

              this.$vs.notify({
                title: this.$t("UnexpectedError"),
                text:
                  this.$t("AddConversationAlreadyExists") +
                  ` ${intentionText} ${operatorText}`,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning",
                position: "top-right",
                time: 4000,
              });
            } else if (message === "Incorrect data") {
              this.$vs.notify({
                title: this.$t("Error"),
                text: this.$t("VerifyDataAndTryAgain"),
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
                position: "top-right",
                time: 4000,
              });
            } else if (message === "Blocked contact") {
              this.$vs.notify({
                title: this.$t("Error"),
                text: "Contato bloqueado.",
                iconPack: "feather",
                icon: "icon-user-x",
                color: "danger",
                position: "top-right",
                time: 4000,
              });
            } else if (
              message === "O contato optou por não receber comunicações."
            ) {
              this.$vs.notify({
                title: this.$t("Error"),
                text: "O contato optou por não receber comunicações.",
                iconPack: "feather",
                icon: "icon-user-x",
                color: "danger",
                position: "top-right",
                time: 4000,
              });
            } else {
              this.$vs.notify({
                title: this.$t("Error"),
                text: "Erro",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
                position: "top-right",
                time: 4000,
              });
            }
            this.addConversationLoading = false;
          });
      } else {
        this.addConversationLoading = false;
        this.$vs.notify({
          time: 5000,
          title: this.$t("Error"),
          text: this.$t("VerifyDataAndTryAgain"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
    async searchAndFilter(options = { initial: true }) {
      if (!this.isReady) {
        this.isFiltered = false;
        this.$vs.loading.close();
        return;
      }
      await this.resetActiveChatUser();
      await this.fetchFiltersCounter();

      if (
        !this.searchQuery &&
        !this.intentionFilter &&
        !this.operatorFilter &&
        !this.filterUnreadOnly
      ) {
        if (this.isFiltered) {
          this.isFiltered = false;
          await this.fetchConversations();
        }
        this.isFiltered = false;
        this.campaignLength = this.tabsCounterStage.campaignLength;
        this.waitingAttendanceLength =
          this.tabsCounterStage.waitingAttendanceLength;
        this.inAttendancePendingLength =
          this.tabsCounterStage.inAttendancePendingLength;
        this.inAttendanceLength = this.tabsCounterStage.inAttendanceLength;
        this.$vs.loading.close();
        return;
      }

      this.campaignLength = 0;
      this.waitingAttendanceLength = 0;
      this.inAttendanceLength = 0;
      this.inAttendancePendingLength = 0;

      this.isFiltered = true;

      this.showLoadMoreConversations = true;
      if (this.$refs.contactsList && this.$refs.contactsList.$el)
        this.$refs.contactsList.$el.scrollTop = 0;

      if (options.initial) {
        await this.fetchConversations();
      }

      this.$vs.loading.close();
    },
    searchAndFilterOnEvent(conversation) {
      let visible = false;

      const searchQueryLower = this.searchQuery
        ? normalizeString(this.searchQuery)
        : null;
      const operatorFilterLower = this.operatorFilter
        ? normalizeString(this.operatorFilter)
        : null;

      const intentionFilter = this.intentionFilter;

      if (searchQueryLower) {
        const fieldsToCheckContact = ["name", "email", "phone"];
        if (
          fieldsToCheckContact.some(
            (field) =>
              conversation[field] &&
              conversation[field]
                .toString()
                .toLowerCase()
                .includes(searchQueryLower)
          )
        )
          visible = true;

        if (
          conversation.tagsContact &&
          conversation.tagsContact.some(
            (tag) =>
              tag.label.toLowerCase().includes(searchQueryLower) ||
              tag.value.toLowerCase().includes(searchQueryLower)
          )
        )
          visible = true;
      }

      if (operatorFilterLower) {
        visible =
          conversation.operator &&
          conversation.operator
            .toString()
            .toLowerCase()
            .includes(operatorFilterLower);
      }

      if (intentionFilter) {
        if (!conversation.intentions.length && intentionFilter === "outros")
          visible = true;
        else
          visible = !!(
            conversation.intentions &&
            conversation.intentions.some((item) =>
              item.includes(intentionFilter)
            )
          );
      }

      if (
        this.filterUnreadOnly &&
        !searchQueryLower &&
        !operatorFilterLower &&
        !intentionFilter
      )
        visible = conversation.pendingResponse === true;

      if (visible) {
        this.filterIDs.push(
          conversation.id,
          this.searchQuery,
          this.filterUnreadOnly,
          this.intentionFilter
        );
        this.fetchFiltersCounter();
      }
    },
    changeWindowTitle(notificationCounter) {
      if (this.windowTitleInterval) {
        clearInterval(this.windowTitleInterval);
        this.windowTitleInterval = null;
      }

      if (notificationCounter) {
        const favicon = document.getElementById("favicon");
        favicon.href = "/favicon_alert.ico";
        let showNotification = false;
        this.windowTitleInterval = setInterval(() => {
          if (showNotification) {
            this.notificationTitle = "";
            showNotification = false;
          } else {
            this.notificationTitle = `(${notificationCounter} 💬) - `;
            showNotification = true;
          }
        }, 1200);
      } else {
        const favicon = document.getElementById("favicon");
        favicon.href = "/favicon.ico";
        this.notificationTitle = "";
      }
    },
    channelAcceptMedia(channel) {
      return this.channelsConfig.find(
        (el) => el.name === channel && el.mediaAvailable
      );
    },
    channelMediaData(channel) {
      return this.channelsConfig.find((el) => el.name === channel);
    },
    channelAddFileOnClick() {
      this.$refs.addFile.click();
      window.analytics.track(
        "Enviar anexo",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
    },
    channelAddEmoteOnClick() {
      window.analytics.track(
        "Enviar emoji",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
    },
    channelMediaDataSizeLimitMB(channel, mediaType) {
      const config = this.channelsConfig.find((el) => el.name === channel);
      if (config && config.mediaAcceptedTypesSizeLimitMB)
        if (config.mediaAcceptedTypesSizeLimitMB[mediaType])
          return config.mediaAcceptedTypesSizeLimitMB[mediaType];
        else if (config.mediaAcceptedTypesSizeLimitMB["file"])
          return config.mediaAcceptedTypesSizeLimitMB["file"];
      return 5;
    },
    cleanMessageInput() {
      this.$store.dispatch("chat/setTypedMessage", {
        id: this.activeContact.id,
        message: "",
      });
      this.$store.dispatch("chat/setAudioSource", null);
      this.$store.dispatch("chat/setBlob", null);
      this.filesToUpload = [];
      clearInterval(this.mediaAudioInterval);
      this.mediaAudioRecordingMin = "00";
      this.mediaAudioRecordingSec = "00";
      this.filesToUploadPreview = [];
      this.fileToUploadPreviewIndex = 0;
      this.messageFileUrl = null;
      this.messageFileMimetype = "";
    },
    async closeAttendance(closeSocket) {
      this.showLoadMoreConversations = true;
      await this.$store.dispatch("chat/setSearchQuery", "");
      this.searchQuery = "";
      this.intentionFilter = null;
      this.conversations = [[], [], [], []];
      this.currentTabConversations = [];
      this.tabsCounterStage = {
        campaignLength: 0,
        waitingAttendanceLength: 0,
        inAttendancePendingLength: 0,
        inAttendanceLength: 0,
      };

      this.conversationsNumbers = 0;
      this.campaignLength = 0;
      this.waitingAttendanceLength = 0;
      this.inAttendancePendingLength = 0;
      this.inAttendanceLength = 0;
      this.closedLength = 0;
      this.isReady = false;

      this.resetActiveChatUser();

      await this.$store.dispatch(
        "chat/setInboxOperatorsArray",
        this.inboxOperatorsArrayStored.map((operator) => operator.value)
      );
      if (closeSocket) {
        socket.removeAllListeners();
        if (socket.connected) socket.disconnect();
        socket.close();
      }
    },
    closeConversation(reason) {
      if (this.activeContact.closed) return;

      let showingLoading = true;
      const thisIns = this;
      this.totalConversations = this.totalConversations - 1;
      this.$vs.loading();

      socket.emit(
        "close conversation",
        {
          sessionID: this.activeContact.sessionID,
          chatID: this.activeContact.chatID,
          conversationID: this.activeContact.contactSessionID,
          userID: this.currentOperator._id,
          reason: reason.name,
          reasonType: reason.id === 1 ? 1 : reason.id === 3 ? 0 : 2,
          saleValue: reason.closingReasonSaleValue
            ? reason.closingReasonSaleValue
            : null,
          reasonLoss: reason.id === 2 ? reason.subReasons.name : null,
          customValue: reason.saleCustomValue || reason.subReasons.name,
          survey: reason.typeSearch,
        },
        async (response) => {
          showingLoading = false;
          this.$vs.loading.close();

          if (response.status === "ok") {
            const id = this.activeContact.id;
            this.totalConversations = this.totalConversations - 1;
            await this.conversationDelete(id);
            this.resetActiveChatUser();
            this.$vs.notify({
              title: this.$t("Success"),
              text: this.$t("CloseConversationSuccess"),
              iconPack: "feather",
              icon: "icon-check",
              color: "success",
              position: "top-right",
              time: 4000,
            });
          } else
            thisIns.$vs.notify({
              title: thisIns.$t("Error"),
              text: thisIns.$t("ErrorOnCloseConversation"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
              time: 4000,
            });
        }
      );
      setTimeout(function () {
        if (showingLoading) {
          thisIns.$vs.loading.close();
          thisIns.$vs.notify({
            title: thisIns.$t("Error"),
            text: thisIns.$t("ErrorOnCloseConversation"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        }
      }, 6000);
    },
    async createNewConversation(conversation) {
      let element = this.treatConversation(conversation);
      if (this.isSearch) this.searchAndFilterOnEvent(element);
      if (!(await this.conversationGet(element.id))) {
        await this.conversationCreateOrUpdate(element, {
          newConversation: true,
          updateCurrentTab: true,
        });
        this.playAudio(newConversationNotification);
        return element;
      }
    },
    dataURLToBlob(dataURL) {
      let BASE64_MARKER = ";base64,";
      if (dataURL.indexOf(BASE64_MARKER) === -1) {
        const parts = dataURL.split(",");
        const contentType = parts[0].split(":")[1];
        const raw = parts[1];

        return new Blob([raw], { type: contentType });
      }

      const parts = dataURL.split(BASE64_MARKER);
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;

      let uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    },
    downloadMessageFile(status, message) {
      this.$store.dispatch("chat/updateMessageOnMessageList", {
        _id: message._id ? message._id : -1,
        messageID: message.id,
        payload: { data: { ...message.data, fileStatus: status } },
      });

      axios({
        method: "post",
        url: `/p/chat/downloadMessageFile`,
        data: {
          getURL: true,
          message_id: message._id,
          messageID: message.id,
          inboxConversationId:
            this.$store.state.chat.activeContact.contactSessionID,
          sessionID: this.$store.state.chat.activeContact.sessionID,
          chatID: this.$store.state.chat.activeContact.chatID,
          userID: this.$store.state.chat.userProfileId,
        },
      }).then((res) => {
        const response = JSON.parse(res.request.response);
        const { data } = response;
        this.$store.dispatch("chat/updateMessageOnMessageList", {
          _id: message._id ? message._id : -1,
          messageID: message.id,
          payload: {
            data: { ...message.data, fileStatus: 1, fileURL: data.url },
          },
        });
        return data.url;
      });
    },
    async filterConversations(options = {}) {
      const { forceSearch } = options;
      if (!this.searchQueryChanged && !forceSearch) return;
      this.inboxSearchLastUpdate = "";
      this.inboxSearchPagination = 0;
      this.filterIDs = [];
      await this.searchAndFilter();
      await this.$store.dispatch("chat/setSearchQuery", this.searchQuery);
      this.searchQueryChanged = false;
    },
    findLastChannelInMessagesList(messageList, activeContact) {
      let channel = activeContact.channel;
      for (let i = messageList.length - 1; i >= 0; i--) {
        if (
          messageList[i] &&
          messageList[i].author !== "system" &&
          messageList[i].author !== "log" &&
          messageList[i].channel
        ) {
          channel = messageList[i].channel;
          continue;
        }
      }
      return channel;
    },
    filterQuickMessages() {
      if (this.searchQueryQuickMessages !== "") {
        this.inboxQuickMessagesFiltered = this.inboxQuickMessages.filter(
          (i) =>
            i.message
              .toLowerCase()
              .includes(this.searchQueryQuickMessages.toLowerCase()) ||
            i.title
              .toLowerCase()
              .includes(this.searchQueryQuickMessages.toLowerCase())
        );
      } else {
        this.inboxQuickMessagesFiltered = this.inboxQuickMessages;
      }

      if (this.inboxQuickMessagesFiltered.length > 2)
        this.inboxQuickMessagesFiltered = this.inboxQuickMessagesFiltered.slice(
          0,
          3
        );
    },
    async finishSync() {
      await this.updateCurrentTabConversations({ setDBConversations: true });
      this.conversationsSyncAlert = false;
      this.conversationsLoadingProgress = false;
    },
    async getChats() {
      await this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then((response) => {
          if (response.data.data.length === 1)
            this.addConversationChat = {
              label: response.data.data[0].name,
              value: response.data.data[0]._id,
              data: response.data.data[0],
            };
          for (let i = 0; i < response.data.data.length; i++) {
            this.chatOptions.push({
              label: response.data.data[i].name,
              value: response.data.data[i]._id,
              data: response.data.data[i],
            });
          }
          for (let i = 0; i < response.data.data.length; i++) {
            try {
              axios({
                method: "get",
                url: `/p/chat/inbox/provider/whatsapp-api/templates/${response.data.data[i]._id}`,
                params: { acc: this.$store.state.acc.current_acc.id },
              }).catch(() => {});
            } catch (e) {
              //ignore
            }
          }
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    async createQuickMessage() {
      const fd = new FormData();

      if (this.quickMessageMedia) {
        fd.append("content", this.quickMessageMedia);
      }

      fd.append("title", this.createQuickMessages.title);
      fd.append("message", this.createQuickMessages.message);
      if (this.createQuickMessages.intentions.length > 0) {
        this.createQuickMessages.intentions.forEach((item) => {
          if (item.value) fd.append("intentions[]", item.value);
        });
      }
      if (this.createQuickMessages.entities.length > 0) {
        this.createQuickMessages.entities.forEach((item) => {
          if (item.value) fd.append("entities[]", item.value);
        });
      }

      fd.append("acc", this.$store.state.acc.current_acc.id);
      fd.append("createdBy", this.$store.state.user._id);

      try {
        await axios.post("/p/chat/inbox/quickMessages", fd);
        this.$vs.notify({
          time: 2500,
          title: this.$t("Ok"),
          text: this.$t("QuickMessageCreated"),
          iconPack: "feather",
          icon: "icon-check",
          color: "success",
          position: "top-right",
        });

        await this.getQuickMessages();
        this.createQuickMessagesPopup = false;
        this.createQuickMessages = {
          title: "",
          message: "",
          intentions: [],
          entities: [],
        };
        this.quickMessageMedia = null;
        this.quickMessageMediaMimetype = "";
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: e,
          iconPack: "feather",
          icon: "icon-check",
          color: "danger",
          position: "top-right",
        });
      }
    },
    async getQuickMessagesWppApi() {
      this.inboxQuickMessagesWppAPIApproved = [];
      try {
        const responseA = await axios.get("/p/chat/inbox/quickMessagesWppApi", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            chat: this.activeContact.chatID,
            intentions: this.activeContact.intentions,
            entities: this.activeContact.entities,
            channel: this.channel,
          },
        });

        const quickMessagesApiResult = responseA.data.data;

        quickMessagesApiResult.forEach((el) => {
          const approvedTemplates = [];
          if (el.components) {
            if (el.status.toLowerCase() === "approved") {
              approvedTemplates.push(el);
            }
          } else if (el.whatsappApiTemplates)
            el.whatsappApiTemplates.forEach((el2) => {
              if (el2.templateStatus.toLowerCase() === "approved") {
                approvedTemplates.push(el2);
              }
            });
          const currentElement = el;
          currentElement.whatsappApiTemplates = approvedTemplates;
          this.inboxQuickMessagesWppAPIApproved.push(currentElement);
        });

        this.inboxQuickMessagesWppAPIApprovedOnChatFiltered =
          this.inboxQuickMessagesWppAPIApprovedOnChat;
      } catch (e) {
        //error on getQuickMessagesWppApi
        this.inboxQuickMessagesWppAPIApprovedOnChatFiltered = [];
      }
    },
    async getQuickMessages() {
      const responseB = await axios.get("/p/chat/inbox/quickMessages", {
        params: {
          acc: this.$store.state.acc.current_acc.id,
          chat: this.activeContact.chatID,
          intentions: this.activeContact.intentions,
          entities: this.activeContact.entities,
        },
      });

      const quickMessagesResult = responseB.data.data;
      this.inboxQuickMessagesFiltered = quickMessagesResult.slice(0, 3);
      await this.$store.dispatch(
        "chat/setInboxQuickMessages",
        quickMessagesResult
      );
    },
    async getConversationMessages(id) {
      try {
        let query = "";
        if (this.activeContact.inAttendance)
          query += `operator=${this.currentOperator._id}&`;
        if (this.activeContact.inAttendance)
          query += `inAttendance=${this.activeContact.inAttendance}&`;
        if (this.activeContact.sessionID)
          query += `sessionid=${this.activeContact.sessionID}&`;
        query += "end=1";
        if (!id) return;
        const response = await axios({
          method: "get",
          url: `/p/chat/conversation/messages/${this.currentAccId}/${id}?${query}`,
        });

        this.$vs.loading.close("#chatlog > .con-vs-loading");
        const data = response.data.data;
        if (typeof data === "string" && data === "conversation was seized") {
          return -1;
        }

        return data;
      } catch (err) {
        this.$vs.loading.close();
      }
    },
    handleConversationsScroll() {
      if (
        this.isLoadingConversations ||
        !this.$refs.conversationsListContainer ||
        !this.showLoadMoreConversations
      )
        return;
      const virtualList = this.$refs.conversationsListContainer;
      const offset = virtualList.getOffset();
      const clientSize = virtualList.getClientSize();
      const scrollSize = virtualList.getScrollSize();
      if (offset + clientSize >= scrollSize) this.loadMoreConversations();
    },
    handleFilesBeforeUpload(e, receivedFiles = false) {
      const thisIns = this;
      const channelMediaData = this.channelMediaData(this.channel);
      if (!channelMediaData) return;
      if (!channelMediaData.mediaAvailable) {
        this.$vs.notify({
          time: 5000,
          title: this.$t("Error"),
          text: this.$t("ChannelDontAcceptMedia"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        return;
      }
      let files = e && e.target ? e.target.files : null;
      if (receivedFiles) files = receivedFiles;
      if (!files) {
        this.$vs.notify({
          time: 5000,
          title: this.$t("Error"),
          text: this.$t("ErrorOnSendFileMessage"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        return null;
      }

      files.forEach((item, i) => {
        if (item) {
          const itemType = item.type.split("/")
            ? item.type.split("/")[0]
            : "file";

          const itemTypeSizeLimit = this.channelMediaDataSizeLimitMB(
            this.channel,
            itemType
          );

          if (i > 29) {
            e.preventDefault();
            this.$vs.notify({
              time: 5000,
              title: this.$t("Error"),
              text: "Limite de 30 arquivos atingido",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
            });
          } else if (item.size > itemTypeSizeLimit * 1000000) {
            e.preventDefault();
            this.$vs.notify({
              time: 5000,
              title: this.$t("Error"),
              text: this.$t("FileIsTooLarge", [itemTypeSizeLimit]),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
            });
          } else {
            if (item && typeof item.type === "string") {
              if (item.type.includes("image")) {
                item = this.resizePicture(item);
              } else if (item.type.includes("pdf")) {
                const reader = new FileReader();
                reader.onload = (e) => {
                  thisIns.filesToUploadPreview.push({
                    type: item.type,
                    content: e.target.result,
                    name: item.name,
                  });
                };
                reader.readAsDataURL(item);
                this.filesToUpload.push({ rawItem: item });
              } else {
                this.filesToUploadPreview.push({
                  type: item.type,
                  content: item.type.includes("image")
                    ? URL.createObjectURL(item)
                    : null,
                  name: item.name,
                });
                this.filesToUpload.push({ rawItem: item });
              }
            }
          }
        }
      });
      if (e && e.target) e.target.value = null;
    },
    async initializeInbox() {
      try {
        const response = await this.$http.get(
          `/p/chat/inbox/${this.$store.state.acc.current_acc.id}`,
          {
            params: { acc: this.$store.state.acc.current_acc.id },
          }
        );
        if (
          !response ||
          !response.data ||
          !response.data.data ||
          !response.data.data.operator
        ) {
          this.$vs.dialog({
            type: "confirm",
            color: "warning",
            title: "Oops",
            text: this.$t("YouDontHaveAccess"),
            accept: () => {
              this.$router.push({ name: "dashboard" });
            },
            cancel: () => {
              this.$router.push({ name: "dashboard" });
            },
            close: () => {
              this.$router.push({ name: "dashboard" });
            },
            acceptText: "Ok",
          });
          this.$vs.loading.close();
          return;
        }

        const data = response.data.data;

        await this.$store.dispatch(
          "chat/setRestrictOperator",
          data.restrictOperator === true
        );

        const thisIns = this;
        this.totalConversationsPerLoad = data.inbox.loadSize || 20;
        this.inboxSignOperator = data.inbox.signOperator;
        this.blockCreateConversationOperator =
          !!data.inbox.blockCreateConversationOperator;
        this.blockWaitingServiceTab =
          data.inbox.blockWaitingServiceTab === true;
        this.inboxSettingsData = data.inbox;
        this.inboxWarningFlags = data.inbox.inboxWarningFlags;
        this.closingConversationReasons =
          this.inboxSettingsData.closingConversationReasons.filter(
            (item) =>
              item.name !== "Atendimento IA" &&
              item.name !== "Encerrado Chatbot"
          );
        this.salesSuccessReasons = this.inboxSettingsData.salesSuccessReasons;
        this.salesFailureReasons = this.inboxSettingsData.salesFailureReasons;
        this.intentionConversationsCount =
          data.conversationsCounter.conversationByIntention;
        this.operatorConversationsCount =
          data.conversationsCounter.conversationByAttendant;

        if (data.inbox.ia) this.ia = data.inbox.ia;
        const intentions = [];
        const keyEntities = [];
        data.inbox.keyEntities.forEach((value) => {
          keyEntities.push({ label: value, value });
        });
        keyEntities.unshift({
          label: this.$t("All"),
          value: "__all__",
        });
        data.inbox.intentions.forEach((value) => {
          intentions.push({ label: value, value });
        });
        intentions.unshift({
          label: this.$t("All"),
          value: "__all__",
        });
        intentions.push({
          label: this.$t("Others"),
          value: "__other__",
        });

        this.createQuickMessagesOptions = {
          intentions: intentions,
          entities: keyEntities,
        };

        const inboxIntentions = [...data.inbox.intentions, "outros"].sort();

        const operatorIntentions = data.operator.intentions;

        this.channelsConfig = data.channelsConfig;

        this.inboxIntentions = data.inbox.intentions;
        if (data.inboxChatsConfig) {
          data.inboxChatsConfig.forEach((el) => {
            if (el.wppApi360DialogCloudApi) {
              this.cloudChats.push(el.chat);
            }
            thisIns.inboxChannelsAvailable.push({
              chat: el.chat,
              webChatStatus: el.webChatStatus === 1,
              webChatAIStatus: el.webChatStatus === 1,
              wppApiStatus: el.wppApiStatus === 1,
              wppApi360DialogStatus: el.wppApi360DialogStatus === 1,
              smsStatus: el.smsStatus === 1,
              wppStatus: el.wppStatus === 1,
              facebookStatus: el.facebookStatus === 1,
              instagramStatus: el.instagramStatus === 1,
            });

            thisIns.proactiveChannels.push({
              chat: el.chat,
              wppApi: el.wppApiStatus === 1,
              wppApi360: el.wppApi360DialogStatus === 1,
              sms: el.smsStatus === 1,
              wpp: el.wppStatus === 1,
            });
          });
        }
        this.inboxOperatorsList = data.operators.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        if (
          data.operator &&
          data.operator.intentions &&
          data.operator.intentions.indexOf("__all__") === -1
        ) {
          await this.$store.dispatch(
            "chat/setIntentionsArrayStored",
            operatorIntentions
          );
        } else {
          this.oprAllIntentions = true;
          await this.$store.dispatch(
            "chat/setIntentionsArrayStored",
            inboxIntentions
          );
        }
        await this.$store.dispatch(
          "chat/setAllEntities",
          data.inbox.keyEntities
        );
        if (
          data.operator.entities &&
          data.operator.entities.indexOf("__all__") === -1 &&
          data.operator.entities.length > 0
        ) {
          let entities = data.operator.entities;
          const index = entities.indexOf("__na__");
          if (index > -1) {
            entities.splice(index, 1);
          }
          this.$store.dispatch("chat/setEntitiesArray", entities);
        } else {
          this.$store.dispatch("chat/setEntitiesArray", data.inbox.keyEntities);
        }
        //SET_ENTITIES_ARRAY
        await this.$store.dispatch(
          "chat/setInboxOperatorsArrayStored",
          data.operators
        );
        await this.$store.dispatch(
          "chat/setInboxOperatorsArray",
          this.inboxOperatorsArrayStored.map((operator) => operator.value)
        );
        await this.$store.dispatch(
          "chat/setIntentionsArray",
          this.intentionsArrayStored
        );
        await this.$store.dispatch("chat/setCurrentOperator", data.operator);
        let allIntentions;
        allIntentions = [
          ...this.inboxOperatorsArrayStored.flatMap(
            (operator) => operator.intentions
          ),
          ...data.inbox.intentions,
        ];
        allIntentions = allIntentions.reduce((acc, intention) => {
          if (
            intention &&
            !acc.find(
              (accIntention) =>
                accIntention.toLowerCase() === intention.toLowerCase()
            )
          )
            acc.push(intention);
          return acc;
        }, []);
        await this.$store.dispatch("chat/setAllIntentions", allIntentions);
        await this.initSocket();
        this.inboxLoaded = true;

        //await this.mountConversationsList();
        await this.serveLocalConversationsDocs();

        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();

        if (e && e.message && e.message.search("Cannot read properties of")) {
          this.$vs.dialog({
            type: "confirm",
            color: "warning",
            title: "Oops",
            text: this.$t("YouDontHaveAccess"),
            accept: () => {
              this.$router.push({ name: "dashboard" });
            },
            cancel: () => {
              this.$router.push({ name: "dashboard" });
            },
            acceptText: "Ok",
          });
          return;
        }
        if (this.requestRetryDriver["initializeInbox"] === undefined)
          this.requestRetryDriver["initializeInbox"] = 0;

        if (e && (!e.response || (e.response && e.response.status === 502))) {
          if (this.requestRetryDriver["initializeInbox"] >= 10) {
            this.toggleReloadPageModal();
            return;
          }
          this.requestRetryDriver["initializeInbox"] += 1;
          await this.initializeInbox();
          return;
        }
        this.$vs.notify({
          title: this.$t("YoureOffline"),
          text: this.$t("NetworkIssues"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
      }
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    initAddConversationPopup({ name, phone }) {
      let foundedContact;
      if (phone)
        foundedContact = this.conversationSearchContacts.find(
          (e) => e.phone === phone
        );
      if (foundedContact) this.addConversationContact = foundedContact;
      else {
        this.addConversationName = name;
        this.addConversationPhone = phone;
      }
      this.addConversationPopup = true;
    },
    async initClosedTab() {
      if (!this.loadingClosed) {
        this.loadingClosed = true;
        this.conversationsLoadingProgress = true;

        const filters = { search: this.searchQuery };

        //if the conversation come from feedback page
        if (this.$route.query.conversation) {
          filters._id = this.$route.query.conversation;
          delete this.$route.query.conversation;
        }
      }
    },
    initSocket() {
      const v2Accs = ["608c60d25e9671ec25384820", "60ad40a8cf24431d122b2433"];
      if (v2Accs.includes(this.currentAccId)) {
        this.initSocketV2();
        return;
      }
      this.$vs.loading();
      const accID = this.currentAccId;
      const userID = this.activeUserInfo._id;
      /* socket */
      const token = this.$store.state.auth.accessToken;

      if (this.currentOperator.intentions) {
        this.isReconnection = true;
        socket.reconData = JSON.stringify({
          intentions: this.intentionsArrayStored,
        });
      }

      socket.auth = {
        accID,
        userID,
        token,
        platform: "web",
        reconData: socket.reconData,
      };

      socket.accID = accID;
      socket.userID = userID;

      const self = this;

      let inConflict = false;

      socket.connect();

      socket.onAny(() => {
        this.socketLastPing = Date.now();
      });

      socket.on("connection_conflict", () => {
        this.$vs.loading.close();
        this.$store.dispatch("chat/setSearchQuery", "");
        this.$store.dispatch("chat/reset");
        socket.removeAllListeners();
        socket.disconnect();
        socket.destroy();

        if (!inConflict)
          this.$vs.dialog({
            type: "confirm",
            color: "warning",
            title: this.$t("Warning"),
            text: this.$t("WebsocketConflict"),
            accept: () => {
              this.$vs.loading();
              location.reload();
            },
            cancel: () => {
              this.$router.push("/dashboard");
              inConflict = !inConflict;
            },
            close: () => {
              this.$router.push("/dashboard");
              inConflict = !inConflict;
            },
            acceptText: this.$t("UseHere"),
            cancelText: this.$t("Close"),
          });
      });
      /* socket */
      socket.onAny((event, message, id) => {
        if (
          message &&
          id &&
          typeof id === "string" &&
          !this.messageQueue.has(id)
        ) {
          socket.emit("ack_received", id);
          this.messageQueue.add(message.id);
        }
      });

      socket.on("connect", () => {
        this.$vs.loading.close();
        socket.emit("loadAttendance", 2);
        this.socketLastPing = Date.now();
      });

      socket.on("ready", async () => {
        this.$vs.loading.close();
        this.socketLastPing = Date.now();
        this.isReady = true;

        if (!this.isReconnection) {
          this.inboxCampaignPagination = 0;
          this.inboxCampaignLastUpdate = null;
          this.inboxWaitingAttendancePagination = 0;
          this.inboxWaitingAttendanceLastUpdate = null;
          this.inboxInAttendancePagination = 0;
          this.inboxInAttendanceLastUpdate = null;
          this.inboxInClosedPagination = 0;
          this.inboxInClosedLastUpdate = null;
          this.inboxSearchPagination = 0;
          this.inboxSearchLastUpdate = null;
        }

        if (this.activeTab === 3) await this.initClosedTab(true);
        await this.fetchConversations();

        if (this.activeContact && this.activeContact.id) {
          const id = this.activeContact.id;
          const currentItem = await this.conversationGet(id);
          if (currentItem && currentItem.inAttendance) {
            await this.updateActiveConversation(currentItem, id);
          }
        }
      });

      socket.on("no inbox", () => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: this.$t("Error"),
          text: this.$t("YouDontHaveAccess"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          position: "top-right",
          time: 4000,
        });

        this.$router.push("/dashboard");
      });

      socket.on("error", (data) => {
        self.$vs.loading.close();

        this.$vs.notify({
          title: this.$t("Error"),
          text: data,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
      });

      socket.on("new conversation", async (conversation) => {
        if (!this.operatorCanSeeConversation(conversation)) return;
        await this.createNewConversation(conversation);
        await this.updateCurrentTabConversations();
      });

      socket.on("contact phase changed", async (phase) => {
        await this.conversationCreateOrUpdate(
          { id: this.activeContact.id, phase },
          { updateCurrentTab: true }
        );
      });

      socket.on("conversation seized", async ({ contactSessionID, userID }) => {
        if (userID !== this.currentOperator._id) {
          this.seizeConversation(contactSessionID);
        }
      });

      socket.on(
        "conversation by another operator",
        async ({ conversationID }) => {
          const id = conversationID;
          if (await this.conversationGet(id)) {
            if (id === this.activeConversationId) this.resetActiveChatUser();
            this.totalConversations = this.totalConversations - 1;
            await this.conversationDelete(id);
            this.$vs.notify({
              title: this.$t("Error"),
              text: this.$t("ErrorAttendanceAnotherOperator"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
              time: 4000,
            });
          }
        }
      );

      socket.on("close conversation", () => {
        this.resetActiveChatUser();
      });

      socket.on(
        "update conversation",
        async (
          conversation,
          {
            operator,
            intentions,
            entities,
            name,
            email,
            phone,
            field,
            value,
            postponedTo,
          }
        ) => {
          await this.updateConversation(conversation, {
            operator,
            intentions,
            entities,
            name,
            email,
            phone,
            field,
            value,
            postponedTo,
          });
        }
      );

      /* socket conversation */
      socket.on("messages history", (data) => {
        this.messageList = data.messages.map((messageItem) => {
          if (
            messageItem.type === "file" &&
            messageItem.data.file.includes("s3.amazonaws")
          ) {
            messageItem.data.fileStatus = 0;
            messageItem.data.file = "";
          }
          return messageItem;
        });
        if (
          typeof data.participants === "object" &&
          data.participants.length > 0
        )
          this.$store.dispatch("chat/setParticipants", data.participants);
      });

      socket.on("private message", async (data) => {
        if (data.message.type === "file") {
          data.message.data.fileStatus = 0;
        }
        if (data.participants)
          this.$store.dispatch("chat/setParticipants", data.participants);
        this.$store.dispatch("chat/addMessageList", data.message);
        await this.conversationCreateOrUpdate(
          {
            id: this.activeContact.id,
            updated: new Date().toISOString(),
            timestamp: Date.now(),
          },
          { updateCurrentTab: true }
        );
      });

      socket.on("update pending response", async (inboxConversation) => {
        const id = inboxConversation._id;
        const currentitem = await this.conversationGet(id);
        if (
          currentitem &&
          !currentitem.pendingResponse &&
          inboxConversation.pendingResponse &&
          currentitem.inAttendance
        ) {
          this.playAudio(newMessageNotification);
        }
        if (currentitem) {
          await this.conversationCreateOrUpdate(
            {
              id,
              pendingResponse: inboxConversation.pendingResponse,
              updated: new Date().toISOString(),
              timestamp: Date.now(),
            },
            { updateCurrentTab: true }
          );
          if (this.isSearch)
            this.searchAndFilterOnEvent({
              ...currentitem,
              ...{
                pendingResponse: inboxConversation.pendingResponse,
                updated: new Date().toISOString(),
                timestamp: Date.now(),
              },
            });
        }
      });

      socket.on("conversation update", async (id, { prop, value }) => {
        await this.conversationCreateOrUpdate(
          { id, [prop]: value },
          { updateCurrentTab: true }
        );
      });

      socket.on("authentication ongoing", () => {
        this.$vs.notify({
          title: "WhatsApp Reconectando",
          text: "Aguarde que o WhatsApp está reconectando.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          position: "top-right",
          time: 10000,
        });
      });

      socket.on("conversation was seized", async (data) => {
        if (
          data &&
          data.conversationID &&
          data.conversationID === this.activeContact.id
        )
          this.resetActiveChatUser();

        if (data.conversationID) this.seizeConversation(data.conversationID);
        this.showToastSeizedConversation();
      });

      socket.on("disconnect", () => {});

      socket.on("send message error number not found", () => {
        this.$vs.notify({
          title: this.$t("Error"),
          text: this.$t("NumberDoesNotExist"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
      });

      socket.on("update message", (messageID, message, sessionID) => {
        if (message.type === "file") message.data.fileStatus = 0;
        if (this.activeContact.sessionID === sessionID) {
          if (message && Object.keys(message).length > 0) {
            let insertIfNull = true;
            if (message.reactions && Object.keys(message).length === 1)
              insertIfNull = false;
            this.$store.dispatch("chat/updateMessageOnMessageList", {
              _id: message._id || -1,
              messageID,
              payload: { ...message },
              insertIfNull,
            });
          }
        }
      });

      socket.on(
        "update message id",
        (oldMessageID, newMessageID, sessionID) => {
          if (this.activeContact.sessionID === sessionID) {
            this.$store.dispatch("chat/updateMessageIDOnMessageList", {
              oldMessageID,
              newMessageID,
            });
          }
        }
      );
    },
    initSocketV2() {
      this.$vs.loading();
      const accID = this.currentAccId;
      const userID = this.activeUserInfo._id;
      /* socket */
      const token = this.$store.state.auth.accessToken;

      if (this.currentOperator.intentions) {
        this.isReconnection = true;
        socket.reconData = JSON.stringify({
          intentions: this.intentionsArrayStored,
        });
      }

      socket.auth = {
        accID,
        userID,
        token,
        platform: "web",
        version: 2,
        reconData: socket.reconData,
      };

      socket.accID = accID;
      socket.userID = userID;

      const self = this;

      socket.connect();

      /* socket */
      socket.onAny((event, data) => {
        this.socketLastPing = Date.now();
        const { ackID } = data;
        if (
          ackID &&
          typeof ackID === "string" &&
          !this.messageQueue.has(ackID)
        ) {
          socket.emit("ack_received", ackID);
          this.messageQueue.add(ackID);
        }
      });

      socket.on("connect", () => {
        this.$vs.loading.close();
        this.socketLastPing = Date.now();
      });

      socket.on("ready", async () => {
        this.$vs.loading.close();
        this.socketLastPing = Date.now();
        this.isReady = true;

        if (!this.isReconnection) {
          this.inboxCampaignPagination = 0;
          this.inboxCampaignLastUpdate = null;
          this.inboxWaitingAttendancePagination = 0;
          this.inboxWaitingAttendanceLastUpdate = null;
          this.inboxInAttendancePagination = 0;
          this.inboxInAttendanceLastUpdate = null;
          this.inboxInClosedPagination = 0;
          this.inboxInClosedLastUpdate = null;
          this.inboxSearchPagination = 0;
          this.inboxSearchLastUpdate = null;
        }
        if (this.activeTab === 3) await this.initClosedTab(true);
        await this.fetchConversations();

        if (this.activeContact && this.activeContact.id) {
          const id = this.activeContact.id;
          const currentItem = await this.conversationGet(id);
          if (currentItem && currentItem.inAttendance) {
            await this.updateActiveConversation(currentItem, id);
          }
        }
      });

      socket.on("no inbox", () => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: this.$t("Error"),
          text: this.$t("YouDontHaveAccess"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          position: "top-right",
          time: 4000,
        });

        this.$router.push("/dashboard");
      });

      socket.on("error", (data) => {
        self.$vs.loading.close();

        this.$vs.notify({
          title: this.$t("Error"),
          text: data,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
      });

      socket.on("conversation_create", async ({ message }) => {
        const { conversation } = message;
        if (!this.operatorCanSeeConversation(conversation)) return;

        await this.createNewConversation(conversation);
        await this.updateCurrentTabConversations();
      });

      socket.on("conversation_update", async ({ message }) => {
        const { conversation } = message;
        await this.updateConversation(conversation, message.payload);
      });

      // legacy methods:
      socket.on("new conversation", async (conversation) => {
        if (!this.operatorCanSeeConversation(conversation)) return;
        await this.createNewConversation(conversation);
        await this.updateCurrentTabConversations();
      });

      socket.on("contact phase changed", async (phase) => {
        await this.conversationCreateOrUpdate(
          { id: this.activeContact.id, phase },
          { updateCurrentTab: true }
        );
      });

      socket.on("conversation seized", async ({ contactSessionID, userID }) => {
        if (userID !== this.currentOperator._id) {
          this.seizeConversation(contactSessionID);
        }
      });

      socket.on(
        "conversation by another operator",
        async ({ conversationID }) => {
          const id = conversationID;
          if (await this.conversationGet(id)) {
            if (id === this.activeConversationId) this.resetActiveChatUser();
            this.totalConversations = this.totalConversations - 1;
            await this.conversationDelete(id);
            this.$vs.notify({
              title: this.$t("Error"),
              text: this.$t("ErrorAttendanceAnotherOperator"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
              time: 4000,
            });
          }
        }
      );

      socket.on("close conversation", () => {
        this.resetActiveChatUser();
      });

      socket.on(
        "update conversation",
        async (
          conversation,
          {
            operator,
            intentions,
            entities,
            name,
            email,
            phone,
            field,
            value,
            postponedTo,
          }
        ) => {
          await this.updateConversation(conversation, {
            operator,
            intentions,
            entities,
            name,
            email,
            phone,
            field,
            value,
            postponedTo,
          });
        }
      );

      /* socket conversation */
      socket.on("messages history", (data) => {
        this.messageList = data.messages.map((messageItem) => {
          if (
            messageItem.type === "file" &&
            messageItem.data.file.includes("s3.amazonaws")
          ) {
            messageItem.data.fileStatus = 0;
            messageItem.data.file = "";
          }
          return messageItem;
        });
        if (
          typeof data.participants === "object" &&
          data.participants.length > 0
        )
          this.$store.dispatch("chat/setParticipants", data.participants);
      });

      socket.on("private message", async (data) => {
        if (data.message.type === "file") {
          data.message.data.fileStatus = 0;
        }
        if (data.participants)
          this.$store.dispatch("chat/setParticipants", data.participants);
        this.$store.dispatch("chat/addMessageList", data.message);
        await this.conversationCreateOrUpdate(
          {
            id: this.activeContact.id,
            updated: new Date().toISOString(),
            timestamp: Date.now(),
          },
          { updateCurrentTab: true }
        );
      });

      socket.on("update pending response", async (inboxConversation) => {
        const id = inboxConversation._id;
        const currentitem = await this.conversationGet(id);
        if (
          currentitem &&
          !currentitem.pendingResponse &&
          inboxConversation.pendingResponse &&
          currentitem.inAttendance
        ) {
          this.playAudio(newMessageNotification);
        }
        if (currentitem) {
          await this.conversationCreateOrUpdate(
            {
              id,
              pendingResponse: inboxConversation.pendingResponse,
              updated: new Date().toISOString(),
              timestamp: Date.now(),
            },
            { updateCurrentTab: true }
          );
          if (this.isSearch)
            this.searchAndFilterOnEvent({
              ...currentitem,
              ...{
                pendingResponse: inboxConversation.pendingResponse,
                updated: new Date().toISOString(),
                timestamp: Date.now(),
              },
            });
        }
      });

      socket.on("conversation update", async (id, { prop, value }) => {
        await this.conversationCreateOrUpdate(
          { id, [prop]: value },
          { updateCurrentTab: true }
        );
      });

      socket.on("authentication ongoing", () => {
        this.$vs.notify({
          title: "WhatsApp Reconectando",
          text: "Aguarde que o WhatsApp está reconectando.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          position: "top-right",
          time: 10000,
        });
      });

      socket.on("conversation was seized", async (data) => {
        if (
          data &&
          data.conversationID &&
          data.conversationID === this.activeContact.id
        )
          this.resetActiveChatUser();

        if (data.conversationID) this.seizeConversation(data.conversationID);
        this.showToastSeizedConversation();
      });

      socket.on("disconnect", () => {});

      socket.on("send message error number not found", () => {
        this.$vs.notify({
          title: this.$t("Error"),
          text: this.$t("NumberDoesNotExist"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
      });

      socket.on("update message", (messageID, message, sessionID) => {
        if (message.type === "file") message.data.fileStatus = 0;
        if (this.activeContact.sessionID === sessionID) {
          if (message && Object.keys(message).length > 0) {
            let insertIfNull = true;
            if (message.reactions && Object.keys(message).length === 1)
              insertIfNull = false;
            this.$store.dispatch("chat/updateMessageOnMessageList", {
              _id: message._id || -1,
              messageID,
              payload: { ...message },
              insertIfNull,
            });
          }
        }
      });

      socket.on(
        "update message id",
        (oldMessageID, newMessageID, sessionID) => {
          if (this.activeContact.sessionID === sessionID) {
            this.$store.dispatch("chat/updateMessageIDOnMessageList", {
              oldMessageID,
              newMessageID,
            });
          }
        }
      );
    },
    insertEmoji(emoji) {
      const textarea = this.$refs.inputTextareaMessage;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;

      const beforeCursor = this.typedMessage.substring(0, startPos);
      const afterCursor = this.typedMessage.substring(endPos);

      this.typedMessage = `${beforeCursor}${emoji}${afterCursor}`;

      this.emojiKeyForceRerender += 1;

      this.$nextTick(() => {
        const newPos = startPos + emoji.length;
        textarea.setSelectionRange(newPos, newPos);
        textarea.focus();
      });
    },
    async loadMoreConversations() {
      this.isLoadingConversations = true;
      await this.fetchConversations({
        loadMore: true,
        closedConversations: this.activeTab === 3,
      });
    },
    async isObjectId(id) {
      const objectIdRegExp = /^[0-9a-fA-F]{24}$/;
      return objectIdRegExp.test(id);
    },
    onDrop(event) {
      event.preventDefault();
      event.stopPropagation();

      this.showDropzone = false;

      const files = event.dataTransfer.files;
      if (!this.teamChatOpen) {
        if (files) this.handleFilesBeforeUpload(null, files);
      }
    },
    playAudio(file) {
      if (this.audioPlaying) return;
      this.audioPlaying = true;
      const audio = new Audio(file);
      audio.play().catch(() => {});
      const thisIns = this;
      setTimeout(() => {
        thisIns.audioPlaying = false;
      }, 6000);
    },
    pasteOnTextBox(event, mediaAvailable) {
      let paste = event.clipboardData || window.clipboardData;

      let file = [];
      for (var i = 0; i < paste.items.length; i++) {
        var item = paste.items[i];
        if (mediaAvailable && item.kind === "file") {
          file = [item.getAsFile()];
        }
      }
      if (file.length > 0) this.handleFilesBeforeUpload(null, file);
    },
    async pushRouteQueryParams(params, removeAll = false) {
      const query = !removeAll ? { ...this.$route.query, ...params } : params;
      if (this.$route.query) {
        if (
          String(params.activeTab) === this.$route.query.activeTab &&
          ((!params.conversation && !this.$route.query.conversation) ||
            params.conversation === this.$route.query.conversation)
        )
          return;
        if (
          params.conversation === this.$route.query.conversation &&
          (!params.activeTab ||
            String(params.activeTab) === this.$route.query.activeTab)
        )
          return;
      }
      this.$router.push({
        query,
      });
    },
    async resetActiveChatUser() {
      this.messageFileMimetype = "";
      this.messageFileUrl = "";
      this.messagesListPage = 1;
      this.$store.dispatch("chat/setActiveConversationId", null);
      this.$store.dispatch("chat/setActiveProfileSidebar", false);
      this.$store.dispatch("chat/setUserProfileId", -1);
      this.$store.dispatch("chat/setActiveConversation", {});
      this.toogleProfileSidebar(false);
      this.$vs.loading.close();
      this.$vs.loading.close("#chatlog > .con-vs-loading");
      await this.pushRouteQueryParams(
        {
          acc: this.$store.state.acc.current_acc.id,
          activeTab: this.activeTab,
        },
        true
      );
    },
    removeImageToUpload(index) {
      if (this.filesToUploadPreview.length === 1) this.cleanMessageInput();
      else if (this.fileToUploadPreviewIndex > 0)
        this.fileToUploadPreviewIndex = this.fileToUploadPreviewIndex - 1;

      this.filesToUploadPreview.splice(index, 1);
      this.filesToUpload.splice(index, 1);
      if (this.filesToUpload.length === 0) this.cleanMessageInput();
    },
    removeConversationContact() {
      this.addConversationContact = "";
    },
    async resendMsg(messageItem) {
      if (!this.socketConnOnline) {
        this.$vs.notify({
          title: this.$t("YoureOffline"),
          text: this.$t("NetworkIssues"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
        return;
      }

      const ack = 0;
      socket.emit(
        "private message 2",
        {
          message: {
            ...messageItem,
            ack,
            timestamp: Date.now(),
          },
          sessionID: this.activeContact.sessionID,
          conversationID: this.activeContact.contactSessionID,
          conversationMessageID: messageItem._id,
          chatID: this.activeContact.chatID,
          operator: this.currentOperator._id,
          resend: true,
          version: 2,
        },
        async (response) => {
          if (response.status === "ok") {
            await this.removeUnsendedMessage(
              this.activeContact.id,
              response.uid
            );
            await this.setMessageSent(response.message);
            this.$store.dispatch("chat/sortMessageList");
          } else if (response.reason === "seized") {
            this.showToastSeizedConversation();
          }
          return true;
        }
      );
    },
    async resizePicture(imageFile) {
      const thisIns = this;

      const reader = new FileReader();
      reader.onload = function (e) {
        var img = document.createElement("img");
        img.onload = function () {
          // Dynamically create a canvas element
          var MAX_WIDTH = 1600;
          var MAX_HEIGHT = 1200;

          var width = img.width;
          var height = img.height;

          // Change the resizing logic
          if (width > height) {
            if (width > MAX_WIDTH) {
              height = height * (MAX_WIDTH / width);
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width = width * (MAX_HEIGHT / height);
              height = MAX_HEIGHT;
            }
          }

          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          const content = canvas.toDataURL(imageFile.type);
          thisIns.filesToUploadPreview.push({
            type: imageFile.type,
            content,
            name: imageFile.name,
          });
          const resizedImage = thisIns.dataURLToBlob(content);
          thisIns.filesToUpload.push({
            rawItem: imageFile,
            image: resizedImage,
          });
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(imageFile);
    },
    async removeUnsendedMessage(id, uid) {
      const unsendedMessage = await this.$db
        .collection(this.localDBTable)
        .doc(id)
        .get();
      if (unsendedMessage) {
        const messages = unsendedMessage.messages;
        const index = messages.findIndex((el) => el.uid === uid);
        if (index > -1) {
          messages.splice(index, 1);
          if (messages.length === 0)
            await this.$db.collection(this.localDBTable).doc(id).delete();
          else
            await this.$db.collection(this.localDBTable).doc(id).update({
              messages,
            });
        }
      }
    },
    async setMessageSent(data) {
      await this.$store.dispatch("chat/updateMessageOnMessageList", {
        _id: data._id || -1,
        messageID: data.id,
        uid: data.uid,
        payload: data,
        insertIfNull: false,
      });
    },
    async seizeConversation(id) {
      if (await this.conversationGet(id)) {
        if (id === this.activeConversationId) this.resetActiveChatUser();
        this.totalConversations = this.totalConversations - 1;
        await this.conversationDelete(id);
      }
    },
    showToastSeizedConversation() {
      this.$vs.notify({
        title: this.$t("Error"),
        text: this.$t("ErrorAttendanceAnotherOperatorOrNotFounded"),
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "danger",
        position: "top-right",
        time: 10000,
      });
    },
    startRecording() {
      const workerOptions = {
        OggOpusEncoderWasmPath: `${window.location.protocol}//${window.location.host}/codec/OggOpusEncoder.wasm`,
        WebMOpusEncoderWasmPath: `${window.location.protocol}//${window.location.host}/codec/WebMOpusEncoder.wasm`,
      };
      window.MediaRecorder = OpusMediaRecorder;
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        let options = { mimeType: "audio/ogg;codecs=opus" };
        // Start recording
        this.$store.dispatch(
          "chat/setRecorder",
          new MediaRecorder(stream, options, workerOptions)
        );
        let startTime = Date.now();
        this.mediaAudioInterval = setInterval(() => {
          let elapsedTime = Date.now() - startTime;
          let minutes = Math.floor(elapsedTime / 60000);
          let seconds = Math.floor((elapsedTime % 60000) / 1000);
          this.mediaAudioRecordingMin = minutes < 10 ? "0" + minutes : minutes;
          this.mediaAudioRecordingSec = seconds < 10 ? "0" + seconds : seconds;
        }, 1000);
        // Set record to <audio> when recording will be finished
        this.$store.state.chat.recorder.addEventListener(
          "dataavailable",
          (e) => {
            this.$store.dispatch(
              "chat/setAudioSource",
              URL.createObjectURL(e.data)
            );
            this.$store.dispatch("chat/setBlob", e.data);
          }
        );
        this.$store.state.chat.recorder.start();
      });
    },
    stopRecording() {
      this.$store.state.chat.recorder.stop();
      this.$store.state.chat.recorder.stream
        .getTracks()
        .forEach((i) => i.stop());
      this.$store.dispatch("chat/setRecorder", null);
      clearInterval(this.mediaAudioInterval);
    },
    async sendMsg(e, type, msg) {
      if (!this.socketConnOnline) {
        this.$vs.notify({
          title: this.$t("YoureOffline"),
          text: this.$t("NetworkIssues"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
        return;
      }
      if (
        !(
          (/whatsapp/i.test(this.channel) &&
            (this.currentChannelsAvailable.wppApi360DialogStatus ||
              this.currentChannelsAvailable.wppApiStatus)) ||
          (/instagram/i.test(this.channel) &&
            this.currentChannelsAvailable.instagramStatus) ||
          (/facebook/i.test(this.channel) &&
            this.currentChannelsAvailable.facebookStatus) ||
          (/^(webchat|website)$/i.test(this.channel) &&
            (this.currentChannelsAvailable.webChatStatus ||
              this.currentChannelsAvailable.webChatAIStatus))
        )
      ) {
        this.$vs.notify({
          title: this.$t("Error"),
          text: this.$t("UnavailableChannel"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
        return;
      }
      const conversationID = this.activeContact.contactSessionID;
      const id = this.activeContact.id;

      if (
        type !== "chatSummary" &&
        type !== "sentimentAnalysis" &&
        !this.noteMessage
      )
        await this.conversationCreateOrUpdate(
          {
            id,
            updated: new Date().toISOString(),
            timestamp: Date.now(),
          },
          { updateCurrentTab: true }
        );
      const operator = `*${this.activeUserName}*:\n`;

      const sessionID = this.activeContact.sessionID;
      const chatID = this.activeContact.chatID;
      const typedMessage = msg
        ? msg
        : this.inboxSignOperator && !this.noteMessage
        ? operator + this.typedMessage
        : this.typedMessage;
      const channel = this.channel;

      const blob = this.blob;
      const audioSource = this.audioSource;
      const files = this.filesToUpload;

      if (
        operator &&
        !audioSource &&
        files.length === 0 &&
        ((typedMessage.trim() === operator.trim() && this.inboxSignOperator) ||
          typedMessage.trim() === "")
      ) {
        return;
      }

      if (!e || !e.shiftKey) {
        if (e) e.preventDefault();
        if (!typedMessage && !audioSource && files.length === 0) return;
        const store = this.$store;
        let whatsapp = false;
        let facebook = false;
        let instagram = false;
        let whatsappApi = false;
        let whatsappApi360Dialog = false;
        let sms = false;
        if (channel === "WhatsApp") whatsapp = true;
        if (channel === "Facebook") facebook = true;
        if (channel === "Instagram") instagram = true;
        if (channel === "WhatsApp API") whatsappApi = true;
        if (channel === "WhatsApp 360") whatsappApi360Dialog = true;
        if (channel === "SMS") sms = true;
        const addMessageListAndEmitPrivateMessage = async (msg) => {
          let currentMessage = msg;
          const unsendedMessage = await this.$db
            .collection(this.localDBTable)
            .doc(id)
            .get();

          if (currentMessage.type !== "file")
            await this.$db.collection(this.localDBTable).add(
              {
                messages: unsendedMessage
                  ? unsendedMessage.messages.concat(currentMessage)
                  : [currentMessage],
              },
              id
            );

          if (this.replyMessage) {
            currentMessage.context = {
              id: this.replyMessage.id,
            };
          }
          currentMessage.sendOnGoing = true;
          store.dispatch("chat/addMessageList", currentMessage);

          store.dispatch("chat/setChatReplyMessage", null);

          this.playAudio(sendMessageNotification);

          if (currentMessage.type === "file" && !currentMessage.data.fileUrl) {
            this.$store.dispatch("chat/updateMessageOnMessageList", {
              messageID: currentMessage.id,
              payload: {
                fileUploadPercent: 0,
              },
            });
            let fd = new FormData();
            if (currentMessage.data.file.image)
              fd.append("content", currentMessage.data.file.image);
            else
              fd.append(
                "content",
                currentMessage.data.file.rawItem || currentMessage.data.file
              );
            fd.append("chat", chatID);

            let filename;
            if (currentMessage.data.filename)
              filename = currentMessage.data.filename;
            else if (currentMessage.data.file.rawItem)
              filename = currentMessage.data.file.rawItem.name;
            if (filename) fd.append("filename", filename);

            fd.append("sessionid", sessionID);

            axios
              .post(
                `${process.env.VUE_APP_API_URL}p/chat/saveMessageFile`,
                fd,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization:
                      "Bearer " + localStorage.getItem("accessToken"),
                  },
                  onUploadProgress: (progressEvent) => {
                    const percentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    this.$store.dispatch("chat/updateMessageOnMessageList", {
                      messageID: currentMessage.id,
                      payload: {
                        fileUploadPercent: percentage,
                      },
                    });
                  },
                  timeout: 300000,
                }
              )
              .then((res) => {
                currentMessage.data.file = res.data.data;
                currentMessage.data.fileStatus = 0;
                this.$store.dispatch("chat/updateMessageOnMessageList", {
                  _id: -1,
                  messageID: message.id,
                  payload: {
                    data: currentMessage.data,
                    fileUploadPercent: -1,
                  },
                });

                socket.emit(
                  "private message 2",
                  {
                    eventID: id,
                    message: currentMessage,
                    sessionID,
                    conversationID,
                    chatID,
                    channel,
                    version: 2,
                    operator: this.currentOperator._id,
                  },
                  async (response) => {
                    if (response.status === "ok") {
                      await this.removeUnsendedMessage(id, currentMessage.uid);
                      await this.setMessageSent({
                        sendOnGoing: undefined,
                        ...response.message,
                      });
                    } else if (response.reason === "seized") {
                      this.showToastSeizedConversation();
                    }
                    return true;
                  }
                );
              })
              .catch(() => {
                this.$store.dispatch("chat/rmMessageList", message.id);

                this.$vs.notify({
                  title: this.$t("Error"),
                  text: this.$t("ErrorOnSendFileMessage"),
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger",
                  position: "top-right",
                  time: 4000,
                });
              });
          } else {
            socket.emit(
              "private message 2",
              {
                message: currentMessage,
                sessionID,
                conversationID,
                chatID,
                channel,
                operator: this.currentOperator._id,
                version: 2,
              },
              async (response) => {
                if (response.status === "ok") {
                  await this.removeUnsendedMessage(id, currentMessage.uid);
                  await this.setMessageSent({
                    sendOnGoing: undefined,
                    ...response.message,
                  });
                } else if (response.reason === "seized") {
                  this.showToastSeizedConversation();
                }
                return true;
              }
            );
          }
        };

        const messageType = type
          ? type
          : this.noteMessage
          ? "notes"
          : store.state.user._id;

        let message = {
          id: uuidV4(),
          uid: `${this.currentAccId}_${uuidV4()}`,
          author: messageType,
          timestamp: Date.now(),
          authorName: store.state.user.name,
          ...msg,
          ack: 0,
          channel,
        };

        if (this.messageFileUrl) {
          let treatedText = typedMessage;
          if (typedMessage.endsWith("\n"))
            treatedText = typedMessage.slice(0, typedMessage.length - 1);
          message.type = "file";
          message.data = {
            text: treatedText,
            file: this.messageFileUrl,
            fileUrl: this.messageFileUrl,
            mimetype: this.messageFileMimetype,
            whatsapp,
            facebook,
            instagram,
            whatsappApi,
            whatsappApi360Dialog,
            sms,
          };
          await addMessageListAndEmitPrivateMessage(message);
          this.cleanMessageInput();
        } else if (store.state.chat.audioSource) {
          const audioFileName = audioSource.split("/");
          const filename = `${audioFileName[audioFileName.length - 1]}.oga`;

          let file = blob;
          let fileUrl;

          if (!file) {
            file = store.state.chat.audioSource;
            fileUrl = store.state.chat.audioSource;
          }

          message.type = "file";
          message.data = {
            mimetype: "audio/ogg; codecs=opus",
            file,
            fileUrl,
            filename,
            fileStatus: 2,
            whatsapp,
          };

          await addMessageListAndEmitPrivateMessage(message);
          this.cleanMessageInput();
        } else if (files.length > 0) {
          files.forEach((file) => {
            message = {
              id: uuidV4(),
              author: store.state.user._id,
              timestamp: Date.now(),
              ack: 0,
              channel,
            };
            if (file.image)
              store.dispatch("chat/addMessageListLocalPics", {
                id: message.id,
                url: URL.createObjectURL(file.image),
              });
            message.type = "file";
            message.data = {
              mimetype: file.rawItem.type,
              file,
              filename: file.rawItem.name,
              fileStatus: 2,
              whatsapp,
              facebook,
              instagram,
              whatsappApi,
              whatsappApi360Dialog,
              sms,
            };
            addMessageListAndEmitPrivateMessage(message);
          });
          this.cleanMessageInput();
        } else {
          let treatedText = typedMessage;
          if (typedMessage.endsWith("\n"))
            treatedText = typedMessage.slice(0, typedMessage.length - 1);
          message.type = "text";
          message.data = {
            text: treatedText,
            whatsapp,
            facebook,
            instagram,
            whatsappApi,
            whatsappApi360Dialog,
            sms,
          };

          await addMessageListAndEmitPrivateMessage(message);
          if (!type) this.cleanMessageInput();
        }
      }
    },
    startLivechat() {
      if (!this.activeContact.id) return;

      const isActive = !this.activeContact.closed;

      this.$vs.loading({ container: "#chatlog" });
      if (this.startLivechatTimer) clearTimeout(this.startLivechatTimer);
      this.startLivechatTimer = setTimeout(async () => {
        this.messageList = [];

        const treatGetConversation = async (response) => {
          const sessionid = response.sessionid;
          const messages = response.messages || response.history.messages;
          const participants =
            response.participants || response.history.participants || undefined;
          if (sessionid === this.activeContact.sessionID) {
            if (messages !== undefined) {
              this.messageList = messages;

              // Remove logs and system messages
              const validMessages = messages.filter(
                (msg) => msg.author !== "log" && msg.author !== "System"
              );
              if (validMessages.length) {
                const lastMessage = validMessages[validMessages.length - 1];
                this.channel = this.treatChannel(
                  lastMessage.channel.toLowerCase() === "website"
                    ? "Website"
                    : lastMessage.channel.charAt(0).toUpperCase() +
                        lastMessage.channel.slice(1)
                );
              }
            }
            if (typeof participants === "object" && participants.length > 0)
              await this.$store.dispatch("chat/setParticipants", participants);
          }
        };

        if (isActive) {
          let request = true;
          let tryN = 0;
          do {
            if (!this.activeContact.id) {
              request = false;
              return;
            }
            const response = await this.getConversationMessages(
              this.activeContact.contactSessionID,
              tryN
            );
            if (response) {
              if (response === -1) {
                this.seizeConversation(this.activeContact.contactSessionID);
                this.showToastSeizedConversation();
              } else await treatGetConversation(response);
              request = false;
            } else tryN += 1;
            if (tryN >= 3) {
              this.resetActiveChatUser();
              request = false;
              this.$vs.notify({
                title: this.$t("UnexpectedError"),
                text: this.$t("UnexpectedErrorLoadConversationMessages"),
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
                position: "top-right",
                time: 4000,
              });
            }
          } while (request);

          if (!this.activeContact.id) return;

          this.joinConversationSocket();
        } else {
          socket.emit(
            "get closed conversation",
            {
              sessionID: this.activeContact.sessionID,
              chatID: this.activeContact.chatID,
              conversationID: this.activeContact.contactSessionID,
            },
            async (response) => {
              await treatGetConversation(response);
              this.$vs.loading.close("#chatlog > .con-vs-loading");
              return true;
            }
          );
        }
      }, 650);
    },
    joinConversationSocket() {
      if (this.activeContact.closed) return;
      socket.emit(
        "get conversation 2",
        {
          sessionID: this.activeContact.sessionID,
          conversationID: this.activeContact.contactSessionID,
          chatID: this.activeContact.chatID,
          inAttendance: this.activeContact.inAttendance,
        },
        async (response) => {
          if (response.status === "ok") {
            this.$vs.loading.close("#chatlog > .con-vs-loading");
          } else if (response.reason === "closed_conversation") {
            this.seizeConversation(this.activeContact.contactSessionID);
            this.showToastSeizedConversation();
          }
          return true;
        }
      );
    },
    setOperator(operatorID, intention, entity) {
      const thisIns = this;
      const activeContact = JSON.parse(JSON.stringify(this.activeContact));
      this.$vs.loading();
      const channel = this.findLastChannelInMessagesList(
        this.messageList,
        activeContact
      );

      this.resetActiveChatUser();

      socket.emit(
        "set conversation operator",
        {
          contactSessionID: activeContact.contactSessionID,
          sessionID: activeContact.sessionID,
          chat: activeContact.chatID,
          inAttendance: true,
          operatorID: operatorID,
          intention: intention !== "outros" ? intention : "",
          entity: entity && entity.length > 0 ? entity : null,
          timestamp: Date.now(),
          channel,
        },
        async (response) => {
          this.$vs.loading.close();
          if (response.status === "ok") {
            this.toogleProfileSidebar(false);
            this.$vs.notify({
              time: 2500,
              title: this.$t("Success"),
              text: "Encaminhado com sucesso",
              iconPack: "feather",
              icon: "icon-check",
              color: "success",
              position: "top-right",
            });
          } else {
            thisIns.$vs.notify({
              title: thisIns.$t("Error"),
              text: thisIns.$t("ErrorOnForwardConversation"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "warning",
              position: "top-right",
              time: 4000,
            });
          }
        }
      );
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        this.isChatSidebarActive = this.clickNotClose = false;
      } else {
        this.isChatSidebarActive = this.clickNotClose = true;
      }
    },
    startTemplateConversationWPPApi(
      templateName,
      templateText,
      templateWithImageHeader
    ) {
      if (this.startTemplateConversationWPPApiAwait === true) {
        return;
      }
      this.startTemplateConversationWPPApiAwait = true;
      setTimeout(() => {
        this.startTemplateConversationWPPApiAwait = false;
      }, 2000);
      const conversationID = this.activeContact.contactSessionID;
      const sessionID = this.activeContact.sessionID;
      const chatID = this.activeContact.chatID;
      const channel = this.channel;

      const operatorName = this.inboxOperatorsArrayStored.find(
        (i) => i.value === this.currentOperator._id
      ).label;

      let parametersArr = [];

      if (channel === "WhatsApp API") {
        let resultContato = templateText.indexOf("{NOME_CONTATO}");
        let resultAtendente = templateText.indexOf("{NOME_ATENDENTE}");
        let idConversa = templateText.indexOf("{ID_CONVERSA}");

        if (resultAtendente > -1 || resultContato > -1 || idConversa > -1) {
          let atentendeFirst =
            resultAtendente > -1 && resultAtendente < resultContato;

          if (atentendeFirst) parametersArr.push(operatorName);
          else {
            if (resultContato > -1) parametersArr.push(this.activeContact.name);
            if (resultAtendente > -1) parametersArr.push(operatorName);
          }

          if (atentendeFirst && resultContato > -1)
            parametersArr.push(this.activeContact.name);
          if (idConversa > -1) parametersArr.push(conversationID);
        }
      } else {
        if (templateText.search("{NOME_ATENDENTE}") > -1)
          parametersArr.push(operatorName);
        if (templateText.search("{NOME_CONTATO}") > -1)
          parametersArr.push(this.activeContact.name);
        if (templateText.search("{ID_CONVERSA}") > -1)
          parametersArr.push(conversationID);

        if (templateText.includes("{{1}}")) parametersArr.push(operatorName);
        if (templateText.includes("{{2}}"))
          parametersArr.push(this.activeContact.name);
        if (templateText.includes("{{3}}")) parametersArr.push(conversationID);
      }
      let finalMessage = templateText.replaceAll(
        "{NOME_ATENDENTE}",
        operatorName
      );
      finalMessage = finalMessage.replaceAll(
        "{NOME_CONTATO}",
        this.activeContact.name
      );
      finalMessage = finalMessage.replaceAll("{ID_CONVERSA}", conversationID);

      finalMessage = finalMessage.replaceAll("{{1}}", operatorName);
      finalMessage = finalMessage.replaceAll("{{2}}", this.activeContact.name);
      finalMessage = finalMessage.replaceAll("{{3}}", conversationID);

      let customVariablesIndex = 4;

      this.previewTemplateMessage.forEach((previewTemplateMessage) => {
        finalMessage = finalMessage.replaceAll(
          `{{${customVariablesIndex}}}`,
          previewTemplateMessage.value
        );
        finalMessage = finalMessage.replace(
          previewTemplateMessage.label,
          previewTemplateMessage.value
        );
        customVariablesIndex++;
        parametersArr.push(previewTemplateMessage.value);
      });
      let header360Media;
      let header360Buttons;
      let footer360;

      if (channel === "WhatsApp 360" && templateWithImageHeader !== undefined) {
        header360Buttons = templateWithImageHeader.find(
          (comp) => comp.type === "BUTTONS"
        );

        // verifica se existe botoes no template e adiciona a mensagem
        if (header360Buttons && header360Buttons.buttons.length > 0) {
          header360Buttons.buttons.forEach((btn) => {
            finalMessage += `\n - ${btn.text}`;
          });
        }

        header360Media = templateWithImageHeader.find(
          (comp) => comp.type === "HEADER"
        );

        footer360 = templateWithImageHeader.find(
          (comp) => comp.type === "FOOTER"
        );
      }

      let messageType = "text";

      if (
        channel === "WhatsApp API" &&
        templateWithImageHeader &&
        templateWithImageHeader.templateHeaderMedia
      ) {
        messageType = "file";
      } else if (
        header360Media &&
        header360Media.length !== 0 &&
        header360Media.type === "HEADER"
      ) {
        messageType = "file";
      }

      let message = {
        id: uuidV4(),
        author: this.$store.state.user._id,
        type: messageType,
        data: {
          text: finalMessage,
          whatsappApi: channel === "WhatsApp API",
          whatsappApi360Dialog: channel === "WhatsApp 360",
          sms: channel === "SMS",
        },
        timestamp: Date.now(),
        ack: 0,
        channel,
      };

      if (
        channel === "WhatsApp API" &&
        templateWithImageHeader &&
        templateWithImageHeader.templateHeaderMedia
      ) {
        message.data.fileUrl = templateWithImageHeader.templateHeaderMedia;
        message.data.file = templateWithImageHeader.templateHeaderMedia;
        message.data.mimetype =
          templateWithImageHeader.templateHeaderType === "image"
            ? "image/png"
            : "video/mp4";
      } else if (
        header360Media &&
        header360Media.length !== 0 &&
        header360Media.type === "HEADER"
      ) {
        message.data.fileUrl = header360Media.example.header_handle[0];
        message.data.file = header360Media.example.header_handle[0];
        message.data.mimetype =
          header360Media.format === "VIDEO" ? "video/mp4" : "image/png";
      }
      if (footer360 && footer360.text)
        message.data.text += `\n\n${footer360.text}`;

      this.$store.dispatch("chat/addMessageList", message);

      socket.emit("wpp api template notification message", {
        message,
        parameters: parametersArr,
        templateName,
        sessionID,
        conversationID,
        chatID,
        templateWithImageHeader,
        templateId:
          (this.templateData &&
            this.templateData._id &&
            this.templateData._id) ||
          null,
      });
    },
    selectQuickMessage(val) {
      if (!val) val = this.selectedQuickMessage;

      const message = val.message;

      const operatorName = this.inboxOperatorsArrayStored.find(
        (i) => i.value === this.currentOperator._id
      ).label;

      let finalMessage = message.replaceAll(
        "{NOME_CONTATO}",
        this.activeContact.name
      );

      finalMessage = finalMessage.replaceAll("{NOME_ATENDENTE}", operatorName);

      finalMessage = finalMessage.replaceAll(
        "{ID_CONVERSA}",
        this.activeContact.contactSessionID
      );

      finalMessage = finalMessage.replaceAll("{{1}}", operatorName);
      finalMessage = finalMessage.replaceAll("{{2}}", this.activeContact.name);
      finalMessage = finalMessage.replaceAll(
        "{{3}}",
        this.activeContact.contactSessionID
      );

      // Verificando se o template possui componentes do tipo header e caso exista (IMG/MP4) preencher o preview da conversa e a mensagem com a URL
      const templateHeader = val.components
        ? val.components.find((temp) => temp.type === "HEADER")
        : undefined;
      if (
        templateHeader &&
        (templateHeader.format === "IMAGE" ||
          templateHeader.format === "VIDEO") &&
        val.headerMediaUrl
      ) {
        this.messageFileUrl = val.headerMediaUrl;
        this.messageFileMimetype =
          templateHeader.format === "IMAGE" ? "image/png" : "video/mp4";
      } else if (val.header) {
        this.messageFileUrl = val.header.url || null;
        this.messageFileMimetype = val.header.mimetype;
      } else if (val.type === "audio") {
        if (val.audioUrl)
          this.$store.dispatch("chat/setAudioSource", val.audioUrl);
        finalMessage = "";
      }

      this.typedMessage = finalMessage;
      this.quickMessagesPopup = false;
    },
    urlFormatter(fileUrl) {
      return `${process.env.VUE_APP_API_URL}p/chat/media/tempFile/${fileUrl}`;
    },
    async startContactAttendance() {
      this.$vs.loading();

      this.$store.dispatch("chat/setCurrentAttendance", []);

      const channel = this.findLastChannelInMessagesList(
        this.messageList,
        this.activeContact
      );
      socket.emit(
        "set conversation operator",
        {
          contactSessionID: this.activeContact.contactSessionID,
          sessionID: this.activeContact.sessionID,
          inAttendance: false,
          conversationID: this.activeContact.id,
          chat: this.activeContact.chatID,
          channel,
        },
        async (response) => {
          if (response.status === "ok") {
            if (this.activeTab !== 2) this.activeTab = 2;
            const id = this.activeContact.id;

            const currentItem = await this.conversationCreateOrUpdate(
              {
                id,
                operator: this.currentOperator._id,
                updated: new Date().toISOString(),
                timestamp: Date.now(),
                inAttendance: true,
              },
              { setActiveConversationId: true }
            );

            if (currentItem) {
              await this.updateActiveConversation(currentItem, id);
            }
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              time: 4000,
              title: this.$t("Error"),
              text: this.$t("NotPossibleToStartConversation"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
            });
            this.$vs.loading.close();
          }
        }
      );
    },
    openCreateQuickMessagePopup() {
      this.newQuickMessageVisible = false;
      this.createQuickMessagesPopup = true;
    },
    sendWhatsappTemplate() {
      this.startTemplateConversationWPPApi(
        this.templateData.name,
        this.templateData.message,
        this.templateData.components
      );
      this.modalPreviewTemplateMessage = false;
      this.modalReplaceTemplateMessage = false;
    },
    getCustomVariablesFromWhatsAppTemplate(message) {
      const regex = /\{[^}]+\}/g;
      const exceptions = [
        "{NOME_CONTATO}",
        "{NOME_ATENDENTE}",
        "{LINK_NPS}",
        "{LINK_CSAT}",
        "{ID_CONVERSA}",
      ];
      const matches = message.match(regex) || [];
      const exceptionPlaceholders = matches.filter(
        (placeholder) => !exceptions.includes(placeholder)
      );

      return exceptionPlaceholders || [];
    },
    handleWhatsappTemplate(templateData) {
      let name,
        message,
        item,
        customVariables = [],
        objAux = {};
      if (this.channel === "WhatsApp API") {
        name = templateData.template.templateName;
        message = templateData.message;
        item = templateData.template;
        customVariables = this.getCustomVariablesFromWhatsAppTemplate(message);

        customVariables.forEach((customVar) => {
          const key = customVar.replace(/[{}]/g, "");
          objAux[key] = null;
        });
      } else {
        name = templateData.name;
        message = templateData.message;
        item = templateData.components;

        if (item.length) {
          const itemBody = item.find((el) => el.type === "BODY");
          if (customVariables) {
            customVariables = this.getCustomVariablesFromWhatsAppTemplate(
              itemBody.text
            );
            customVariables.forEach((customVar) => {
              const key = customVar.replace(/[{}]/g, "");
              objAux[key] = null;
            });
          }
        }
      }
      this.templateData = {
        name,
        message,
        components: item,
        customVariables,
        _id: templateData._id || null,
      };
      if (Object.keys(objAux).length) {
        this.editVariablesTemplate = objAux;

        this.templateData.customVariables.forEach((key) => {
          this.previewTemplateMessage.push({ label: key, value: "" });
        });
        this.openModalReplaceTemplateMessage();
      } else {
        this.sendWhatsappTemplate();
      }
    },
    toogleProfileSidebar(value) {
      this.$store.dispatch("chat/setActiveProfileSidebar", value);
    },
    toggleIsChatPinned(value) {
      this.isChatPinned = value;
    },
    toggleChatSidebar(value = false) {
      if (this.clickNotClose) return;
      this.isChatSidebarActive = value;
    },
    treatConversation(conversation) {
      if (conversation.dbParsed) return conversation;
      if (conversation.contactSessionID) return conversation;
      const contactData = conversation.contactData || {};
      let timestamp = conversation.lastMessageDate
        ? new Date(conversation.lastMessageDate).getTime()
        : new Date(conversation.createdAt).getTime();
      if (conversation.status === 4)
        timestamp = new Date(conversation.closingDate).getTime();

      return {
        _id: conversation._id,
        stage: conversation.stage,
        acc: conversation.acc,
        contactSessionID: conversation._id,
        chatID: conversation.chat,
        sessionID: conversation.sessionid,
        id: conversation._id,
        created: conversation.createdAt,
        updated:
          conversation.closingDate ||
          conversation.lastMessageDate ||
          conversation.updatedAt,
        contactID: contactData._id,
        type: contactData.type,
        phase: contactData.phase,
        name: contactData.name,
        phone: contactData.phone,
        optIn: contactData.optIn,
        email: contactData.email,
        contactStatus: contactData.status,
        facebookId: contactData.facebookId,
        instagramId: contactData.instagramId,
        whatsappWAID: contactData.whatsappWAID,
        pendingResponse: conversation.pendingResponse || false,
        profilePic: contactData.profilePic || "",
        operator: conversation.operator || "",
        url: conversation.url,
        status: conversation.status,
        online: conversation.online,
        intentions: conversation.intentions,
        entities: conversation.entities,
        tags: conversation.tags,
        tagsContact: contactData.tags || null,
        channel: conversation.channel,
        origin: conversation.origin,
        inAttendance: !conversation.operator
          ? false
          : conversation.operator.length > 0,
        closed: conversation.status === 4,
        visible: true,
        closingReason: conversation.closingReason,
        closingDate: conversation.closingDate,
        wppApiOn24hWindow: conversation.wppApiOn24hWindow === true,
        timestamp,
        postponedTo: conversation.postponedTo,
      };
    },
    treatChannel(channel) {
      let channelTreated = channel;
      if (
        (channel && channel === "Website") ||
        channel === "website" ||
        channel === "chat" ||
        channel === "Chat"
      )
        channelTreated = "Webchat";
      return channelTreated;
    },
    async updateActiveConversation(conversation, id) {
      if (this.activeConversationId === id) {
        this.joinConversationSocket();
        return;
      }
      this.messageFileMimetype = "";
      this.messageFileUrl = "";
      this.activeTab = this.getConversationTab(conversation);
      this.showScrollMessagesListToBottom = false;
      await this.$store.dispatch("chat/setInboxChannels", []);
      await this.$store.dispatch("chat/setChatReplyMessage", null);
      this.messageList = [];
      await this.pushRouteQueryParams({
        conversation: id,
      });
      this.messagesListPage = 1;

      await this.$store.dispatch("chat/setActiveConversation", conversation);
      await this.$store.dispatch("chat/setActiveConversationId", id);
      await this.toggleChatSidebar();
      await this.$store.dispatch("chat/setUserProfileId", id);
      //verificar tamanho tela
      if (screen.width > 1280)
        this.$store.dispatch("chat/setActiveProfileSidebar", true);
      await this.startLivechat();

      // Available channels setter
      const availableChannels = [];
      this.channel = conversation.channel;
      const channel = this.treatChannel(conversation.channel);
      if (
        channel === "Webchat" &&
        this.inboxChannelsAvailable.find(
          (el) => el.chat === conversation.chatID && el.webChatStatus
        )
      )
        availableChannels.push("Webchat");

      if (
        channel === "Webchat AI" &&
        this.inboxChannelsAvailable.find(
          (el) => el.chat === conversation.chatID && el.webChatAIStatus
        )
      )
        availableChannels.push("Webchat AI");

      if (
        conversation.facebookId &&
        this.inboxChannelsAvailable.find(
          (el) => el.chat === conversation.chatID && el.facebookStatus
        )
      )
        availableChannels.push("Facebook");
      if (
        conversation.instagramId &&
        this.inboxChannelsAvailable.find(
          (el) => el.chat === conversation.chatID && el.instagramStatus
        )
      )
        availableChannels.push("Instagram");

      if (
        this.proactiveChannels.length > 0 &&
        String(conversation.phone).length > 0
      ) {
        const proactiveChannels = this.proactiveChannels.find(
          (el) => el.chat === conversation.chatID
        );
        if (proactiveChannels) {
          if (proactiveChannels.wpp) {
            availableChannels.push("WhatsApp");
          }
          if (proactiveChannels.wppApi) {
            try {
              await this.getQuickMessagesWppApi();
              this.getQuickMessages();
            } catch (e) {
              //error on get quickmessages and wpp tempaltes
            }
            availableChannels.push("WhatsApp API");
          }
          if (proactiveChannels.wppApi360) {
            try {
              await this.getQuickMessagesWppApi();
              this.getQuickMessages();
            } catch (e) {
              //error on get quickmessages and wpp tempaltes
            }
            availableChannels.push("WhatsApp 360");
          }
          if (proactiveChannels.sms) {
            availableChannels.push("SMS");
          }
        }
      }
      await this.$store.dispatch("chat/setInboxChannels", availableChannels);
    },
    updateContactProp(update) {
      this.$store.dispatch("chat/updateActiveContactField", {
        field: update.prop,
        data: update.value,
      });
      this.$nextTick(async () => {
        await this.conversationCreateOrUpdate(
          {
            id: this.activeContact.id,
            [update.prop]: update.value,
          },
          { updateCurrentTab: true }
        );
      });
    },
    async updateConversation(
      conversation,
      {
        operator,
        intentions,
        entities,
        name,
        email,
        phone,
        field,
        value,
        postponedTo,
      }
    ) {
      const contactSessionID = conversation._id;
      let id = contactSessionID;

      const currentItem = await this.conversationGet(id);
      if (!currentItem) {
        if (conversation && conversation.status !== 1) return;
        if (
          this.currentOperator.acl === "admin" ||
          (operator && operator === this.currentOperator._id) ||
          !conversation.operator ||
          (conversation.operator && conversation.operator === "")
        ) {
          const currentEntities =
            entities && entities.length > 0 ? entities : conversation.entities;
          const currentIntentions =
            intentions && intentions.length > 0
              ? intentions
              : conversation.intentions;
          if (
            this.entitiesArray.length > 0 &&
            (!currentEntities || currentEntities.length === 0)
          ) {
            return;
          }
          if (
            this.entitiesArray.length > 0 &&
            currentEntities &&
            currentEntities.length > 0 &&
            !currentEntities.some((entity) =>
              this.entitiesArray.includes(entity)
            )
          ) {
            return;
          }
          if (
            (!this.oprAllIntentions &&
              currentIntentions &&
              currentIntentions.length > 0 &&
              !currentIntentions.some((intent) =>
                this.intentionsArrayStored.includes(intent)
              )) ||
            (!this.oprAllIntentions &&
              currentIntentions &&
              currentIntentions.length === 0 &&
              !this.intentionsArrayStored.includes(""))
          ) {
            return;
          }

          await this.createNewConversation(conversation);
        }
      } else {
        const updateQuery = { id };
        if (operator) updateQuery.operator = operator;
        else if (operator === "") {
          updateQuery.operator = null;
          updateQuery.inAttendance = false;
        }
        if (intentions) updateQuery.intentions = intentions;
        if (entities) updateQuery.entities = entities;
        if (name) updateQuery.name = name;
        if (email) updateQuery.email = email;
        if (phone) updateQuery.phone = phone;
        if (field) updateQuery[field] = value;
        if (postponedTo || postponedTo === null)
          updateQuery.postponedTo = postponedTo
            ? new Date(postponedTo).toISOString()
            : postponedTo;

        if (Object.values(updateQuery).length > 0)
          await this.conversationCreateOrUpdate(updateQuery, {
            updateCurrentTab: true,
          });

        if (this.activeContact && this.activeContact.id === conversation._id)
          this.activeContact = this.treatConversation(conversation);

        if (conversation && conversation.status !== 1) {
          if (conversation._id === this.activeConversationId)
            this.resetActiveChatUser();
          await this.$store.dispatch(
            "chat/removeElementOnChatContacts",
            conversation._id
          );
          return;
        }
        if (!field) {
          if (
            !currentItem.inAttendance &&
            operator &&
            operator !== this.currentOperator._id &&
            this.currentOperator.acl !== "operator"
          )
            await this.conversationCreateOrUpdate(
              { id, inAttendance: true },
              { updateCurrentTab: true }
            );

          let seize = false;
          if (intentions) {
            if (
              intentions.length === 0 &&
              !this.oprAllIntentions &&
              !this.intentionsArrayStored.includes("")
            )
              seize = true;
            else if (
              !this.oprAllIntentions &&
              intentions.length > 0 &&
              !intentions.some((intent) =>
                this.intentionsArrayStored.includes(intent)
              )
            )
              seize = true;
          }

          if (entities) {
            if (
              this.currentOperator.entities > 0 &&
              entities.length > 0 &&
              !entities.some((entity) =>
                this.currentOperator.entities.includes(entity)
              )
            )
              seize = true;
          }
          if (seize) {
            if (id === this.activeConversationId) this.resetActiveChatUser();
            await this.conversationDelete(id);
          }

          if (
            operator &&
            operator !== this.currentOperator._id &&
            this.currentOperator.acl === "operator"
          ) {
            await this.conversationDelete(id);
            if (id === this.activeConversationId) this.resetActiveChatUser();
          }
        }
        if (this.isSearch)
          this.searchAndFilterOnEvent({ ...currentItem, ...updateQuery });
      }
    },
    async conversationDeepLink(id) {
      if (this.deepLinkCalled) return;
      this.deepLinkCalled = true;
      const response = await axios.get("/p/chat/inbox/conversations-v2", {
        params: {
          acc: this.$store.state.acc.current_acc.id,
          byID: id,
          loadType: null,
          loadSize: 1,
        },
      });

      const conversations = response.data.data.conversations;
      if (conversations.length) {
        if (id) {
          const conversation = this.treatConversation(conversations[0]);
          if (!this.conversationsLoadedStatus[conversation.id])
            this.currentTabConversations =
              this.currentTabConversations.push(conversation);
          await this.updateActiveConversation(conversation, conversation.id);
        } else if (
          this.$route.query.notification ||
          this.$route.query.activeTab === "1" ||
          this.$route.query.activeTab === "2" ||
          this.$route.query.activeTab === 1 ||
          this.$route.query.activeTab === 2
        ) {
          const conversation = this.treatConversation(conversations[0]);
          await this.conversationCreateOrUpdate(conversation);
          const _conv = await this.conversationGet(conversation.id);
          if (_conv) {
            await this.updateActiveConversation(_conv, _conv.id);
          } else {
            this.$vs.notify({
              time: 4000,
              title: this.$t("Error"),
              text: this.$t("ErrorAttendanceAnotherOperatorOrNotFounded"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
            });
          }
        } else {
          this.activeTab = 3;
        }
      }
    },
    async startExternalLinkConversation(startConversation) {
      try {
        let conv;

        const response = await axios.get("/p/chat/inbox/conversations-v2", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            loadSize: 1,
            searchQuery: startConversation.phone,
          },
        });

        const conversationsSearch = response.data.data.conversations;

        const { contacts } = response.data.data;

        if (conversationsSearch && conversationsSearch.length > 0) {
          conv = conversationsSearch[0];
          this.$route.query.conversation = conv._id;
          if (!conv.contactData && contacts) {
            conv.contactData = contacts.find(
              (el) => el._id === conv.inboxContact
            );
          }
          conv = this.treatConversation(conv);
          await this.conversationCreateOrUpdate(conv, {
            updateCurrentTab: true,
          });
        }

        if (conv) {
          await this.updateActiveConversation(conv, conv.id);
          this.addConversationPopup = false;
        } else {
          this.addConversationPopup = true;
          this.validPhone = true;
          this.addConversationLoading = false;
          this.addConversationNameDisabled = false;
          this.addConversationPhoneDisabled = false;

          this.addConversationIntention = "";
          this.addConversationEntity = "";
          this.addConversationContact = "";
          this.addConversationName = startConversation.name;
          this.addConversationPhone = startConversation.phone;
        }
        localStorage.removeItem("startConversation");
      } catch (e) {
        return e;
      }
    },
    async fetchConversations(payload = {}) {
      try {
        const {
          isTabChange,
          loadMore,
          loadSize,
          page,
          isDeltaLoad,
          //closedConversations,
        } = payload;
        let byID = !isDeltaLoad && payload.byID ? payload.byID : null;
        if (
          (this.isSearch && isDeltaLoad) ||
          (!isTabChange && isDeltaLoad && this.activeTab === 3)
        )
          return;

        this.isLoadingConversations = true;

        if (this.isSearch) {
          this.searchQueryChanged = false;
          this.showConversationsSkeleton = true;
          this.isFiltered = true;
        }

        this.currentTabConversationsLength =
          this.conversations[this.activeTab].length;

        let loadType = "all";
        let currentTabPagination = 0;
        let currentTabLastUpdate;

        if (this.activeTab === 0) {
          currentTabPagination = this.inboxCampaignPagination;
          currentTabLastUpdate = this.inboxCampaignLastUpdate;
          loadType = "campaign";
        } else if (this.activeTab === 1) {
          currentTabPagination = this.inboxWaitingAttendancePagination;
          currentTabLastUpdate = this.inboxWaitingAttendanceLastUpdate;
          loadType = "waitingAttendance";
        } else if (this.activeTab === 2) {
          currentTabPagination = this.inboxInAttendancePagination;
          currentTabLastUpdate = this.inboxInAttendanceLastUpdate;
          loadType = "inAttendance";
        } else if (this.activeTab === 3) {
          currentTabPagination = this.inboxInClosedPagination;
          currentTabLastUpdate = this.inboxInClosedLastUpdate;
          loadType = "closed";
        }
        if (this.isSearch) {
          currentTabPagination = this.inboxSearchPagination;
          currentTabLastUpdate = this.inboxSearchLastUpdate;
        } else {
          this.inboxSearchLastUpdate = "";
          this.inboxSearchPagination = 0;
        }

        // Nao é a melhor solucao, isso é algo temporario.
        if (await this.isObjectId(this.searchQuery)) {
          byID = this.searchQuery;
        }

        this.conversationsLoadingProgress = true;
        const response = await axios.get("/p/chat/inbox/conversations-v2", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            isFirstLoad: this.isFirstLoad,
            page: !isDeltaLoad ? currentTabPagination : page,
            byID: byID || undefined,
            loadType,
            loadSize:
              isDeltaLoad && loadSize && currentTabLastUpdate
                ? loadSize
                : this.totalConversationsPerLoad,
            searchQuery: normalizeString(this.searchQuery),
            intentionFilter: this.intentionFilter,
            operatorFilter: this.operatorFilter,
            filterUnreadOnly: this.filterUnreadOnly,
            lastUpdate:
              isDeltaLoad && currentTabLastUpdate
                ? currentTabLastUpdate
                : undefined,
          },
        });

        this.requestRetryDriver["getConversations"] = 0;
        if (response && response.data && response.data.data) {
          const { conversations, conversationsNumbers } = response.data.data;

          if (
            conversationsNumbers &&
            !byID &&
            !this.isSearch &&
            (conversationsNumbers.total || conversationsNumbers.partial)
          ) {
            if (conversationsNumbers.partial) {
              if (conversationsNumbers.stage === 1) {
                this.tabsCounterStage.campaignLength =
                  conversationsNumbers.campaign;
                this.campaignLength = conversationsNumbers.campaign;
              } else if (conversationsNumbers.stage === 2) {
                this.waitingAttendanceLength =
                  conversationsNumbers.waitingAttendance;
                this.tabsCounterStage.waitingAttendanceLength =
                  conversationsNumbers.waitingAttendance;
              } else if (conversationsNumbers.stage === 3) {
                this.inAttendancePendingLength =
                  conversationsNumbers.inAttendancePending;
                this.inAttendanceLength = conversationsNumbers.inAttendance;
                this.tabsCounterStage.inAttendancePendingLength =
                  conversationsNumbers.inAttendancePending;
                this.tabsCounterStage.inAttendanceLength =
                  conversationsNumbers.inAttendance;
              }
            } else {
              this.tabsCounterStage = {
                campaignLength: conversationsNumbers.campaign,
                waitingAttendanceLength: conversationsNumbers.waitingAttendance,
                inAttendancePendingLength:
                  conversationsNumbers.inAttendancePending,
                inAttendanceLength: conversationsNumbers.inAttendance,
              };

              this.conversationsNumbers = conversationsNumbers;
              this.campaignLength = conversationsNumbers.campaign;
              this.waitingAttendanceLength =
                conversationsNumbers.waitingAttendance;
              this.inAttendancePendingLength =
                conversationsNumbers.inAttendancePending;
              this.inAttendanceLength = conversationsNumbers.inAttendance;
            }
            //this.closedLength = 0;
          }

          if (conversations && conversations.length > 0) {
            if (!byID) {
              if (this.isSearch) {
                this.inboxSearchLastUpdate = new Date().getTime();
                if (!page) this.inboxSearchPagination++;
              } else if (this.activeTab === 0) {
                this.inboxCampaignLastUpdate = new Date().getTime();
                if (!page) this.inboxCampaignPagination++;
              } else if (this.activeTab === 1) {
                this.inboxWaitingAttendanceLastUpdate = new Date().getTime();
                if (!page) this.inboxWaitingAttendancePagination++;
              } else if (this.activeTab === 2) {
                this.inboxInAttendanceLastUpdate = new Date().getTime();
                if (!page) this.inboxInAttendancePagination++;
              } else if (this.activeTab === 3) {
                this.inboxInClosedLastUpdate = new Date().getTime();
                if (!page) this.inboxInClosedPagination++;
              }
            }

            for (let i = 0; i < conversations.length; i += 1) {
              if (conversations[i].status === 4 && this.activeTab !== 3)
                await this.conversationDelete(conversations[i]._id);
              else if (!conversations[i].dbParsed)
                conversations[i] = this.treatConversation(conversations[i]);
            }

            await this.conversationsBulk(conversations);

            if (this.isSearch) {
              const idsArray = conversations.map((el) => el.id);
              this.filterIDs = this.filterIDs.concat(idsArray);
            }
          } else if (!this.isSearch && !this.isFirstLoad && !isDeltaLoad) {
            this.showLoadMoreConversations = false;
          } else if (this.isSearch && !loadMore) this.filterIDs = [];
          else if (this.isSearch && loadMore)
            this.showLoadMoreConversations = false;

          if (this.isSearch && isTabChange)
            await this.searchAndFilter({ initial: false });

          if (byID) {
            const id = byID;
            const currentItem = await this.conversationGet(id);
            if (currentItem && currentItem.inAttendance) {
              await this.updateActiveConversation(currentItem, id);
              this.addConversationPopup = false;
            }
          }
          this.$vs.loading.close("#chatlog > .con-vs-loading");
          this.$vs.loading.close();
          this.isLoadingConversations = false;
          this.socketReconnect = false;
          this.socketLastPing = Date.now();
          this.isFirstLoad = false;

          if (!byID && conversations && conversations.length > 0) {
            this.showLoadMoreConversations = true;
          }
          let startConversation = localStorage.getItem("startConversation");
          if (startConversation) {
            startConversation = JSON.parse(startConversation);
            await this.startExternalLinkConversation(startConversation);
          }
          if (this.$route.query.conversation && this.activeTab !== 0) {
            await this.conversationDeepLink(this.$route.query.conversation);
          }
        }
        await this.finishSync();

        this.$vs.loading.close();
        this.showConversationsSkeleton = false;
        this.isLoadingConversations = false;
      } catch (e) {
        if (this.requestRetryDriver["getConversations"] === undefined)
          this.requestRetryDriver["getConversations"] = 0;
        if (e && e.response && e.response.status === 502) {
          if (this.requestRetryDriver["getConversations"] >= 10) {
            this.toggleReloadPageModal();
            return;
          }
          this.fetchConversations(payload);
          this.requestRetryDriver["getConversations"] += 1;
          return;
        }
        this.isFirstLoad = false;
        this.isLoadingConversations = false;
        this.showConversationsSkeleton = false;
        this.$vs.notify({
          time: 4000,
          title: this.$t("Error"),
          text: this.$t("ErrorOnLoadingConversations"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
    validatePhone(e) {
      this.validPhone = e.valid;
      if (e && e.countryCallingCode)
        this.addConversationPhoneCountryCode = String(e.countryCallingCode);
    },
    countryChange(e) {
      this.addConversationPhoneCountryCode = String(e.dialCode);
    },
    isIaMessage(author) {
      const iaAuthors = ["chatSummary", "sentimentAnalysis"];
      return iaAuthors.includes(author);
    },
    isSpecialAuthor(author) {
      const iaAuthors = ["chatSummary", "sentimentAnalysis", "notes"];
      return iaAuthors.includes(author);
    },
    async getMessageHistoricForGpt() {
      const messages = [...this.messageList].reverse(),
        historic = [];
      let texts = 0;

      messages.forEach((message) => {
        if (texts >= 8000) {
          return;
        }

        if (this.isSpecialAuthor(message.author)) {
          return;
        }

        if (message.type === "text" && !this.isIaMessage(message.author)) {
          texts += message.data.text.length;
          if (message.author === "me") {
            historic.push({
              role: "user",
              content: message.data.text,
            });
          } else {
            historic.push({
              role: "assistant",
              content: message.data.text,
            });
          }
        }
      });

      return historic;
    },
    async getMessagesForSummary() {
      const messages = [...this.messageList].reverse();
      let historic = "";
      let texts = 0;

      messages.forEach((message) => {
        if (texts >= 8000) {
          return;
        }

        if (this.isSpecialAuthor(message.author)) {
          return;
        }

        if (message.type === "text" && !this.isIaMessage(message.author)) {
          texts += message.data.text.length;
          historic +=
            (message.author === "me" ? "Usuário: " : "Assistente: ") +
            message.data.text +
            "\n";
        }
      });

      return [
        {
          role: "user",
          content: historic,
        },
      ];
    },
    async generateChatGptSuggestion() {
      this.generateChatGptSuggestionOngoing = true;
      window.analytics.track(
        "Sugerir resposta",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.chatGptSuggestionGenerated = true;
      const payload = await this.getMessageHistoricForGpt();

      try {
        const generatedMessage = await axios.post(
          "/p/chat/ai/generate/message",
          {
            messages: payload.reverse(),
            acc: this.$store.state.acc.current_acc.id,
            chat: this.activeContact.chatID,
            conversationId: this.activeContact.id,
            type: "answer",
          }
        );

        if (
          generatedMessage &&
          generatedMessage.data &&
          generatedMessage.data.data
        ) {
          this.typedMessage = generatedMessage.data.data;
        }
        this.generateChatGptSuggestionOngoing = false;
        this.$vs.loading.close();
      } catch (err) {
        this.generateChatGptSuggestionOngoing = false;
        this.$vs.notify({
          time: 4000,
          title: this.$t("Error"),
          text: this.$t("ErrorOnGeneratingChatGptSuggestion"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        this.chatGptSuggestionGenerated = false;
        this.$vs.loading.close();
      }
    },
    async resumeConversation() {
      window.analytics.track(
        "Resumir conversa",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );

      this.$vs.loading();
      const payload = await this.getMessagesForSummary();

      try {
        const generatedMessage = await axios.post(
          "/p/chat/ai/generate/message",
          {
            messages: payload.reverse(),
            acc: this.$store.state.acc.current_acc.id,
            chat: this.activeContact.chatID,
            conversationId: this.activeContact.id,
            type: "chatSummary",
          }
        );

        if (
          generatedMessage &&
          generatedMessage.data &&
          generatedMessage.data.data
        ) {
          this.sendMsg(null, "chatSummary", generatedMessage.data.data);
        }
        this.$vs.loading.close();
      } catch (err) {
        this.$vs.notify({
          time: 4000,
          title: this.$t("Error"),
          text: this.$t("ErrorOnGeneratingChatGptSuggestion"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        this.$vs.loading.close();
      }
    },
    async sentimentAnalysis() {
      window.analytics.track(
        "Análise de sentimento",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );

      this.$vs.loading();
      const payload = await this.getMessagesForSummary();

      try {
        const generatedMessage = await axios.post(
          "/p/chat/ai/generate/message",
          {
            messages: payload.reverse(),
            acc: this.$store.state.acc.current_acc.id,
            chat: this.activeContact.chatID,
            conversationId: this.activeContact.id,
            type: "sentimentAnalysis",
          }
        );

        if (
          generatedMessage &&
          generatedMessage.data &&
          generatedMessage.data.data
        ) {
          this.sendMsg(null, "sentimentAnalysis", generatedMessage.data.data);
        }
        this.$vs.loading.close();
      } catch (err) {
        this.$vs.notify({
          time: 4000,
          title: this.$t("Error"),
          text: this.$t("ErrorOnGeneratingChatGptSuggestion"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        this.$vs.loading.close();
      }
    },
    checkOtherTabs() {
      const otherTabId = localStorage.getItem("otherTabId");
      if (!this.otherTabOpen && otherTabId && otherTabId !== this.tabId) {
        this.closeAttendance(true);
        this.otherTabOpen = true;
        this.$vs.dialog({
          type: "confirm",
          color: "warning",
          title: this.$t("Warning"),
          text: this.$t("WebsocketConflict"),
          accept: () => {
            this.$vs.loading();
            location.reload();
          },
          cancel: () => {
            this.$router.push("/dashboard");
          },
          close: () => {
            this.$router.push("/dashboard");
          },
          acceptText: this.$t("UseHere"),
          cancelText: this.$t("Close"),
        });
      }
    },
    onStorageChange(event) {
      if (event.key === "tabId" || event.key === "otherTabId") {
        this.checkOtherTabs();
      }
    },
    onBeforeUnload() {
      if (localStorage.getItem("otherTabId") === this.tabId) {
        localStorage.removeItem("otherTabId");
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    document.body.style.removeProperty("overflow");
    this.changeWindowTitle(0);
    this.$store.dispatch("chat/setSearchQuery", "");
    socket.removeAllListeners();
    if (socket.connected) socket.disconnect();
    next();
  },
};
</script>

<style lang="scss">
.duo-custom-radio {
  width: 100%;
  padding: 1.25rem;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  cursor: pointer;

  &:has(input:checked) {
    border-color: rgb(115, 103, 240);

    &::v-deep .vs-radio--circle {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 44%;
        height: 44%;
        background-color: #fff;
        border-radius: 100vw;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
}

.newChatCheckbox .vs-checkbox {
  width: 50px !important;
}
.newChatCheckbox .vs-checkbox--input {
  z-index: 10 !important;
}
.dropdownActionsBtn,
.suggestionBtn.vs-button-warning.vs-button-border {
  background: rgba(255, 159, 67, 0.08) !important;
}
.dropdownActionsBtn:hover .vs-button-warning.vs-button-border,
.suggestionBtn:hover.vs-button-warning.vs-button-border {
  background: rgba(255, 159, 67, 0.2) !important;
}
.dropdownActionsListBtn .vs-dropdown--item .vs-dropdown--item-link {
  padding: 0 !important;

  button {
    width: 100%;
    justify-content: left !important;
    border: 1px solid transparent !important;

    &:hover {
      border-color: rgb(255, 159, 67) !important;
    }
  }
}
.chat__log {
  height: calc(100vh - 330px);
}
.chat__log_closed {
  height: calc(100vh - 68px);
}
.width-textarea {
  width: calc(100% - 200px);
}
.msg-reply {
  padding: 5px;
  background-color: #7267f02d;
  border-radius: 5px;
  margin-bottom: 5px;
  border-left: 8px solid #7367f0;

  .reply-participant {
    color: #7367f0;
  }
}
.clusterize-scroll {
  overflow: auto;
  height: calc(100vh - 215px);
}
.EndMessage {
  background: #333;
  position: absolute;
  top: 100%;
}

.vselectNoWrap {
  .vs__search {
    margin-top: -30px;
  }
  input.vs__search:focus {
    padding-top: 30px !important;
  }
  span.vs__selected {
    width: 80px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    display: inline-block !important;
    text-overflow: ellipsis !important;
  }
}
/* .justify-items-center, .vs-tabs--li .vs-tabs--btn {
  justify-content: center !important;
} */

.backdrop-filter {
  background-color: #cccccc;
  filter: blur(500px);
  z-index: 999;
}

.p-5-mutaded {
  padding: 1.25rem !important;
}
#chat-app {
  top: 0;
  bottom: 0;
  right: 0;
  @media screen and (max-width: 450px) {
    right: 0;
  }
  left: -0.5rem;
}
#chat-log .vs-con-items {
  flex-wrap: wrap;
}
.chat-content-scroll-area {
  height: calc(100vh - 285px) !important;
  @media screen and (max-width: 450px) {
    height: calc(100vh - 315px) !important;
  }
}
#chat-input {
  max-height: 217px;

  &.bg-FAFAFA {
    background: #fafafaff;
  }

  &.bg-E4EEF7 {
    background: #e4eef7;
  }
}
.con-vs-dropdown--menu {
  z-index: 92000 !important;
}
#popup-inbox-conversation .vs-popup {
  max-width: 450px !important;
}
#chat-list-sidebar > div {
  @media screen and (max-width: 450px) {
    max-width: 100% !important;
  }
  @media screen and (min-width: 451px) {
    max-width: 350px !important;
  }
}
#chat-list-sidebar > .vs-sidebar,
#chat-profile-info-sidebar > .vs-sidebar {
  margin-top: 0px !important;
}
.label-onboarding {
  font-size: 0.85rem;
  background-color: white;
  margin-left: 1rem;
  padding: 0 0.5rem 0 0.5rem;
  width: fit-content;
  position: relative;
  bottom: -10px;
  order: -1;
  color: #adadad;
}
.input-onboarding {
  padding-left: 8px;
}
.input-onboarding:focus {
  border: solid 1px rgba(var(--vs-primary), 1);
}
.vue-tel-input {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.vue-tel-input:focus-within {
  border-color: rgba(var(--vs-primary), 1);
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.input-onboarding:focus + span + .label-onboarding,
.input-onboarding:focus + .label-onboarding,
.vue-tel-input:focus-within + span + .label-onboarding {
  color: rgba(var(--vs-primary), 1);
}

#tabs-inbox > div > ul > li > button {
  padding-bottom: 15px;
}
#inbox-search > div {
  border: 0;
}
#inbox-search > ul {
  border-top: 1px solid rgba(60, 60, 60, 0.26);
}
#inbox-search > div > .vs__actions > span {
  color: #b8c2cc;
}
.search-active > div .vs__actions > span {
  color: #7367f0 !important;
}
#inbox-search > div .vs__selected {
  position: relative;
  left: 10px;
}

#collapse-inbox-1 > header {
  display: flex;
  justify-content: center;
  margin-right: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}

#collapse-inbox-1 > header .icon-header {
  right: 14px;
  color: #b8c2cc;
}
#collapse-inbox {
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}
#collapse-users-1 > header {
  display: flex;
  justify-content: center;
  margin-right: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}
#collapse-users {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}
#collapse-users-1 > header .icon-header {
  color: #b8c2cc;
}
#collapse-search {
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}
#collapse-search > div > header {
  padding: 0;
}
#collapse-search-1 > header .icon-header {
  color: #b8c2cc;
}
.media-close {
  margin-left: -20px;
  width: 20px !important;
  height: 20px !important;
  background-color: #c1c1c1 !important;
}
.media-close:hover {
  background-color: #979797 !important;
}
.quick-message:hover {
  opacity: 0.5 !important;
}

.flip-list-move {
  transition: transform 1s;
  transition-duration: 0.4s;
  transition-delay: 0s;
  transition-timing-function: linear;
}
.messageMediaPopup {
  padding-left: 2px;
  position: absolute;
  bottom: 218px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.quickMessageMediaPopup * {
  max-height: 300px;
  max-width: 300px;
}
.newQuickMessage {
  position: absolute;
  bottom: 220px;
  max-width: 1000px;
  z-index: 9999999;
  height: 250px;
  overflow: scroll;
  background-color: white;
  width: 100%;

  .contact__container {
    padding: 5px;
    color: black;
    &:hover,
    &:focus {
      background-color: #7367f0;
      color: white;
      cursor: pointer;
    }
  }
}

// Add conversation popup - telephone country select
.vti__dropdown-list {
  z-index: 12;
}

.chatGptSuggestionButton {
  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #ffd200;
  background: rgba(255, 210, 0, 0.07);
}
</style>
